import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { addIndividualValidation } from "../../utils/validationSchema";
import { useCreateIndividual } from "../../data/useCreateIndividual";
import { displayError, showError } from "../../utils/error-handling";
import { NewApplicationFrame } from "./NewApplicationFrame";
import { MIN_AGE_APPLICATION } from "../../utils/constants";
import { useCommonTitles } from "../../data/useCommonTitles";
import { useState, useEffect } from "react";
import { Loader } from "../../components/Loader";
import { useIndividuals } from "../../data/useIndividuals";
import { useUpdateIndividual } from "../../data/useUpdateIndividual";
import { useMandate } from "../../data/useMandate";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

const inputProps = {
  minWidth: 200,
  flex: 1,
};

const genderOptions = [
  { value: "female", label: "Female" },
  { value: "male", label: "Male" },
  { value: "other", label: "Other" },
];

const Header = () => {
  return (
    <>
      <H2>Add Individuals</H2>
      <Paragraph>
        Please add the individuals you would like to complete verifications.
      </Paragraph>
    </>
  );
};

export const AddApplicationIndividual = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const params = useParams();
  const mandateId = parseInt(params.id);
  const location = useLocation();

  // const { data: mandateData } = useMandate(mandateId);
  const showMonitoringStep = location.state?.showMonitoringStep ?? false;

  const [initialValues, setInitialValues] = useState({
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    dateOfBirth: null as Date | null,
    email: "",
    gender: "",
  });

  const shouldFetchIndividuals =
    location && location.state && location?.state?.checks
      ? location?.state?.checks
      : false;

  const { data: individuals } = useIndividuals(
    shouldFetchIndividuals ? mandateId : undefined
  );

  const { mutate: createIndividual, isLoading } = useCreateIndividual();

  const { mutate: updateIndividual } = useUpdateIndividual();

  const { data: titlesData } = useCommonTitles();

  const titleOptions = titlesData?.data.map(({ name }) => ({
    label: name,
    value: name,
  })) as Option[];

  useEffect(() => {
    if (shouldFetchIndividuals && individuals?.data?.results?.[0]) {
      const individual = individuals.data.results[0];
      setInitialValues({
        title: individual.title || "",
        firstName: individual.first_name || "",
        middleName: individual.middle_name || "",
        lastName: individual.last_name || "",
        dateOfBirth: individual.date_of_birth
          ? DateTime.fromISO(individual.date_of_birth).toJSDate()
          : null,
        email: individual.email || "",
        gender: individual.gender || "",
      });
    }
  }, [shouldFetchIndividuals, individuals]);

  const handleUpdateIndividual = (form: any) => {
    setDoubleClick(true);
    const dob = form.dateOfBirth
      ? DateTime.fromJSDate(form.dateOfBirth as Date).toFormat("yyyy-MM-dd")
      : null;

    if (mandateId) {
      updateIndividual(
        {
          params: {
            gender: form.gender,
            title: form.title,
            first_name: form.firstName,
            date_of_birth: dob,
            last_name: form.lastName,
            middle_name: form.middleName,
            mandate: mandateId,
            email: form.email,
          },
          id: individuals.data.results[0].id,
        },
        {
          onSuccess: ({ data }) => {
            navigate(`/applications/${mandateId}/individuals`, {
              state: { checks: true, showMonitoringStep: showMonitoringStep  },
            });
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(err, "A problem occurred while creating the individual.");
          },
        }
      );
    } else {
      displayError(
        "A problem occurred while creating individual. No application selected"
      );
    }
  };

  const handleCreateIndividual = (form: any) => {
    setDoubleClick(true);
    const dob = form.dateOfBirth
      ? DateTime.fromJSDate(form.dateOfBirth as Date).toFormat("yyyy-MM-dd")
      : null;

    if (mandateId) {
      createIndividual(
        {
          gender: form.gender,
          title: form.title,
          first_name: form.firstName,
          date_of_birth: dob,
          last_name: form.lastName,
          middle_name: form.middleName,
          mandate: mandateId,
          email: form.email,
        },
        {
          onSuccess: ({ data }) => {
            navigate(`/applications/${mandateId}/individuals`, {
              state: { checks: true, showMonitoringStep: showMonitoringStep  },
            });
          },
          onError: (err: any) => {
            setDoubleClick(false);
            showError(err, "A problem occurred while creating the individual.");
          },
        }
      );
    } else {
      displayError(
        "A problem occurred while creating individual. No application selected"
      );
    }
  };

  return (
    <NewApplicationFrame
      step="individuals"
      mandateId={mandateId}
      showMonitoringStep={showMonitoringStep}
    >
      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={addIndividualValidation}
          onSubmit={
            shouldFetchIndividuals && individuals?.data?.results.length !== 0
              ? handleUpdateIndividual
              : handleCreateIndividual
          }
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
              <Header />
              <Flex {...rowProps}>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("title", option.value)
                    }
                    value={titleOptions?.find(
                      ({ value }) => value === props.values.title
                    )}
                    options={titleOptions}
                    placeholder="Select title"
                    mt="2"
                    label="Title"
                    name="title"
                  />
                </div>
                <div style={{ width: "48%" }}>
                  <Select
                    flex={1}
                    onChange={(option: any) =>
                      props.setFieldValue("gender", option.value)
                    }
                    value={genderOptions?.find(
                      ({ value }) => value === props.values.gender
                    )}
                    options={genderOptions}
                    placeholder="Select gender"
                    mt="2"
                    label="Select gender"
                    name="gender"
                    isRequired
                  />
                </div>
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.firstName}
                  placeholder="Enter first name"
                  mt="2"
                  label="First name"
                  name="firstName"
                  hasError={Boolean(props.errors.firstName)}
                  errorMessage={props.errors.firstName as string}
                  isRequired
                />
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.middleName}
                  placeholder="Enter middle(s) name"
                  mt="2"
                  label="Middle name(s)"
                  name="middleName"
                  hasError={Boolean(props.errors.middleName)}
                  errorMessage={props.errors.middleName as string}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.lastName}
                  placeholder="Enter last name"
                  mt="2"
                  label="Last name"
                  name="lastName"
                  hasError={Boolean(props.errors.lastName)}
                  errorMessage={props.errors.lastName as string}
                  isRequired
                />
                <DatePicker
                  value={props.values.dateOfBirth as any}
                  onChange={(date: any) => {
                    props.setFieldValue("dateOfBirth", date);
                  }}
                  mt="2"
                  maxDate={DateTime.now()
                    .minus({ years: MIN_AGE_APPLICATION })
                    .toJSDate()}
                  label="Date of birth"
                  name="dateOfBirth"
                  hasError={Boolean(props.errors.dateOfBirth)}
                  errorMessage={props.errors.dateOfBirth as string}
                  {...inputProps}
                />
              </Flex>
              <Flex {...rowProps}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.email}
                  placeholder="Enter email address"
                  mt="2"
                  label="Email address"
                  name="email"
                  hasError={Boolean(props.errors.email)}
                  errorMessage={props.errors.email as string}
                  isRequired
                />
              </Flex>
              <Flex justifyContent="space-between">
                <Button
                  size="large"
                  mt="2"
                  mb="0"
                  variant="secondary"
                  onClick={() =>
                    navigate(`/applications/${mandateId}/individuals`)
                  }
                >
                  Back
                </Button>
                <Button
                  size="large"
                  mt="2"
                  mb="0"
                  type="submit"
                  isDisabled={
                    // !props.dirty ||
                    (props.dirty && !props.isValid) || isLoading || doubleClick
                  }
                >
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      )}
    </NewApplicationFrame>
  );
};
