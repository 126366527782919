import { FaPlus } from "react-icons/fa";
import { MdTune } from "react-icons/md";
import { Box } from "../components/Box";
import { Button } from "../components/Button";
import { Flex } from "../components/Flex";
import { Input } from "../components/Input";
import Topbar from "../components/Topbar";
import { UserFilter } from "../components/UserFilter";
import { ROUTE } from "../routes";
import { env } from "../utils/env";
import { useMemo, useState } from "react";
import { useUsers } from "../data/useUsers";
import { useNavigate } from "react-router-dom";
import { ChecksTable } from "../components/ChecksTable";
import { ChecksFilters } from "../components/ChecksFilters";
import CustomTabs from "../components/CustomTabs";
import { Paragraph } from "../components/Paragraph";
import { Image } from "../components/Image";
import all from "../assets/screening/all.svg";
import individual from "../assets/screening/individual.svg";
import company from "../assets/screening/company.svg";

const initialState = {
  dateStarted: [null, null],
  checkType: null,
};

const Checks = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [submittedSearchTerm, setSubmittedSearchTerm] = useState("");
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [filters, setFilters] = useState(initialState);
  const { data: users } = useUsers();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const [activeTab, setActiveTab] = useState(0);

  const handleSearchSubmit = () => {
    setSubmittedSearchTerm(searchTerm);
  };

  const handleResetSearch = () => {
    setSearchTerm("");
    setSubmittedSearchTerm("");
  };

  const userOptions = users?.data?.results.map(
    ({ first_name, last_name, id, colour }) => ({
      value: id,
      label: `${first_name} ${last_name}`,
      firstName: first_name,
      lastName: last_name,
      colour,
    })
  );

  const filterUserIds = useMemo(
    () => [selectedUser?.value],
    [selectedUser?.value]
  );

  const handleFilterSubmit = (values) => {
    setFilters(values);
    setIsFiltersOpen(false);
  };

  const handleSelection = (type) => {
    setIsDropdownOpen(false);
    if (type === "individual") {
      navigate(ROUTE.CHECKS_NEW);
    } else if (type === "company") {
      navigate(ROUTE.CHECKS_NEW_COMPANY);
    }
  };

  return (
    <>
      <div>
        <Topbar title="Screening">
          <Flex justifyContent="space-between">
            {env.isSearchChecksFeatureOn ? (
              <Flex ml={4}>
                <Input
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.currentTarget.value)}
                  type="search"
                  size="small"
                  placeholder="Search"
                  maxWidth={250}
                  mr={1}
                />
                <Button
                  isDisabled={submittedSearchTerm === searchTerm}
                  onClick={handleSearchSubmit}
                >
                  Search
                </Button>

                {submittedSearchTerm && (
                  <Button
                    variant="text"
                    pl="0px"
                    ml={1}
                    onClick={handleResetSearch}
                  >
                    Reset search
                  </Button>
                )}
              </Flex>
            ) : (
              <Box />
            )}
            <Flex>
              <UserFilter
                size="regular"
                options={userOptions}
                value={selectedUser}
                onChange={setSelectedUser as any}
                mr={1}
              />
              {env.isFilterApplicationsFeatureOn && (
                <Button
                  variant="secondary"
                  mr={1}
                  Icon={MdTune}
                  iconPosition="right"
                  onClick={() => setIsFiltersOpen((isOpen) => !isOpen)}
                >
                  Filter
                </Button>
              )}
              {
                <Flex position="relative">
                  <Button
                    Icon={FaPlus}
                    iconSize="16px"
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    New Screening
                  </Button>

                  {isDropdownOpen && (
                    <Box
                      style={{
                        position: "absolute",
                        top: "100%",
                        left: 0,
                        background: "white",
                        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                        borderRadius: "12px",
                        zIndex: 10,
                        width: "100%",
                      }}
                    >
                      <Paragraph
                        my={0}
                        style={{ padding: "10px", cursor: "pointer" }}
                        onClick={() => handleSelection("individual")}
                      >
                        Individual
                      </Paragraph>
                      <Paragraph
                        my={0}
                        style={{ padding: "10px", cursor: "pointer" }}
                        onClick={() => handleSelection("company")}
                      >
                        Company
                      </Paragraph>
                    </Box>
                  )} 
                </Flex>
              }
            </Flex>
          </Flex>
        </Topbar>
         <Flex justifyContent="space-between">
          <CustomTabs
            activeTab={activeTab}
            onTabChange={setActiveTab}
            tabs={[
              {
                label: (
                  <Flex alignItems={"center"}>
                    {" "}
                    <Image
                      src={all}
                      alt="all Icon"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    All
                  </Flex>
                ),
                content: activeTab === 0 && (
                  <div>
                    {" "}
                    <ChecksTable
                      searchTerm={submittedSearchTerm}
                      filterUserIds={filterUserIds}
                      filters={filters}
                      type=""
                    ></ChecksTable>
                    <ChecksFilters
                      isOpen={isFiltersOpen}
                      onRequestClose={() => setIsFiltersOpen(false)}
                      onSubmit={handleFilterSubmit}
                      filters={filters}
                    />{" "}
                  </div>
                ),
              },
              {
                label: (
                  <Flex alignItems={"center"}>
                    {" "}
                    <Image
                      src={individual}
                      alt="individual Icon"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    Individual
                  </Flex>
                ),
                content: activeTab === 1 && (
                  <div>
                    <ChecksTable
                      searchTerm={submittedSearchTerm}
                      filterUserIds={filterUserIds}
                      filters={filters}
                      type="individual"
                    ></ChecksTable>
                    <ChecksFilters
                      isOpen={isFiltersOpen}
                      onRequestClose={() => setIsFiltersOpen(false)}
                      onSubmit={handleFilterSubmit}
                      filters={filters}
                    />{" "}
                  </div>
                ),
              },
              {
                label: (
                  <Flex alignItems={"center"}>
                    {" "}
                    <Image
                      src={company}
                      alt="Company Icon"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    Company
                  </Flex>
                ),
                content: activeTab === 2 && (
                  <div>
                    <ChecksTable
                      searchTerm={submittedSearchTerm}
                      filterUserIds={filterUserIds}
                      filters={filters}
                      type="entity"
                    ></ChecksTable>
                    <ChecksFilters
                      isOpen={isFiltersOpen}
                      onRequestClose={() => setIsFiltersOpen(false)}
                      onSubmit={handleFilterSubmit}
                      filters={filters}
                    />{" "}
                  </div>
                ),
              },
            ]}
          />
        </Flex> 
      </div>
    </>
  );
};

export default Checks;
