import { Form, Formik } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { DatePicker } from "../../components/DatePicker";
import { Flex } from "../../components/Flex";
import { H2 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import Select, { Option } from "../../components/Select";
import { useState } from "react";
import { useCreateMandateForChecks } from "../../data/useCreateMandateForChecks";
import { useCreateMandateTypeFromCheckpointIds } from "../../data/useCreateMandateTypeFromCheckpointIds";
import { NewCompanyChecksFrame } from "./NewCompanyChecksFrame";
import { useCountries } from "../../data/useCountries";
import { Box } from "../../components/Box";
import { useEntityTypes } from "../../data/useEntityTypes";
import { useCreateEntity } from "../../data/useCreateEntity";
import { DateTime } from "luxon";
import { showError } from "../../utils/error-handling";
import { companyCheckValidationSchema } from "../../utils/validationSchema";

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

const inputProps = {
  minWidth: 200,
  flex: 1,
};

const Header = () => {
  return (
    <>
      <H2>Company Details</H2>
      <Paragraph>Please add the company to perform the checks on.</Paragraph>
    </>
  );
};

export const AddCompanyDetails = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const checkpointIds = location.state?.checkpointIds;

  const isSanctionsSelected = location.state?.isSanctionsSelected;

  const isAdverseMediaSelected = location.state?.isAdverseMediaSelected;

  const { data: entityTypes } = useEntityTypes();
  const { mutate: createEntity, isLoading } = useCreateEntity();

  const { mutate: createMandateType } = useCreateMandateTypeFromCheckpointIds();
  const { mutate: createMandate } = useCreateMandateForChecks();

  const { data: countries } = useCountries();

  const entityTypeOptions = entityTypes?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];

  const countryOptions = countries?.data.map(({ id, name }) => ({
    label: name,
    value: id,
  })) as Option[];

  const handleSubmit = (form) => {
    setDoubleClick(true);
    createMandateType(
      { checkpoint: checkpointIds },
      {
        onSuccess: (res) => {
          if (res?.status?.toString().startsWith("2")) {
            createMandate(
              { mandate_type: res?.data?.id, category: "Checks" },
              {
                onSuccess: ({ data: mandate }) => {
                  createEntity(
                    {
                      mandate: mandate.id,
                      company_name: form.companyName,
                      country: form.country,
                      entity_type: form.companyType,
                      registeration_number: form.registrationNumber,
                      date_of_incorporation: form.dateOfIncorporation
                        ? DateTime.fromJSDate(
                            new Date(form.dateOfIncorporation)
                          ).toFormat("yyyy-MM-dd")
                        : null,
                      // dissolution_date: form.dissolutionDate
                      //   ? DateTime.fromJSDate(
                      //       new Date(form.dissolutionDate)
                      //     ).toFormat("yyyy-MM-dd")
                      //   : null,
                    },
                    {
                      onSuccess: ({ data: company }) => {
                        navigate(
                          `/checks/${mandate.id}/company/address/${company.id}`,
                          {
                            state: {
                              checkpointIds,
                              isSanctionsSelected,
                              isAdverseMediaSelected,
                            },
                          }
                        );
                      },
                      onError: (err) => {
                        setDoubleClick(false);
                        showError(err, "Error creating company.");
                      },
                    }
                  );
                },
                onError: (err) => {
                  setDoubleClick(false);
                  showError(err, "Error creating mandate.");
                },
              }
            );
          }
        },
        onError: (err) => {
          setDoubleClick(false);
          showError(err, "Error creating mandate type.");
        },
      }
    );
  };

  return (
    <NewCompanyChecksFrame step="company">
      <Formik
        initialValues={{
          companyName: "",
          country: "",
          companyType: "",
          registrationNumber: "",
          dateOfIncorporation: "",
          // dissolutionDate: "",
        }}
        validationSchema={companyCheckValidationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
            <Header />
            <Flex {...rowProps}>
              <Input
                {...inputProps}
                onChange={props.handleChange}
                value={props.values.companyName}
                placeholder="Enter company name"
                mt="2"
                label="Company name"
                name="companyName"
                isRequired
                hasError={Boolean(props.errors.companyName)}
                errorMessage={props.errors.companyName as string}
              />
            </Flex>

            <Flex {...rowProps}>
              <Select
                value={countryOptions?.find(
                  ({ value }) => value === props.values.country
                )}
                options={countryOptions}
                onChange={(option: Option) => {
                  props.setFieldValue("country", option.value);
                }}
                minWidth={200}
                flex={1}
                label="Country of incorporation"
                name="country"
                isRequired
                hasError={Boolean(props.errors.country)}
                errorMessage={props.errors.country as string}
              />
            </Flex>
            <Flex {...rowProps}>
              <Select
                value={entityTypeOptions?.find(
                  ({ value }) => value === props.values.companyType
                )}
                options={entityTypeOptions}
                onChange={(option: Option) =>
                  props.setFieldValue("companyType", option.value)
                }
                minWidth={200}
                flex={1}
                label="Company type"
                name="companyType"
                hasError={Boolean(props.errors.companyType)}
                errorMessage={props.errors.companyType as string}
              />
            </Flex>
            <Flex {...rowProps}>
              <Box flex={1}>
                <Input
                  {...inputProps}
                  onChange={props.handleChange}
                  value={props.values.registrationNumber}
                  placeholder="Enter registration number"
                  label="Registration number"
                  name="registrationNumber"
                  hasError={Boolean(props.errors.registrationNumber)}
                  errorMessage={props.errors.registrationNumber as string}
                />
              </Box>
              <Box flex={1}>
                <DatePicker
                  value={props.values.dateOfIncorporation as any}
                  onChange={(date: any) => {
                    props.setFieldValue("dateOfIncorporation", date);
                  }}
                  maxDate={DateTime.now().toJSDate()}
                  label="Date of incorporation"
                  name="dateOfIncorporation"
                  hasError={Boolean(props.errors.dateOfIncorporation)}
                  errorMessage={props.errors.dateOfIncorporation as string}
                  {...inputProps}
                />
              </Box>
            </Flex>

            <Flex justifyContent="flex-end">
              {/* <Button
                size="large"
                mt="2"
                mb="0"
                variant="secondary"
                onClick={() =>
                  navigate(`/applications/${mandateId}/individuals`)
                }
              >
                Back
              </Button> */}
              <Button
                size="large"
                mt="2"
                mb="0"
                type="submit"
                isDisabled={
                  (props.dirty && !props.isValid) || isLoading || doubleClick
                }
              >
                Next
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </NewCompanyChecksFrame>
  );
};
