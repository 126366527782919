import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { IndividualManager } from "./IndividualManager";
import { SendInvites } from "./SendInvites";
import { ApplicationsMonitoring } from "./ApplicationsMonitoring";

export const ApplicationIndividuals = () => {
  const params = useParams();
  const mandateId = parseInt(params.id);
  let location = useLocation();

  const showMonitoringStep = location.state?.showMonitoringStep ?? false;

  const [step, setStep] = useState("individualManager");

  const handleClickNext = () => {
    setStep("sendInvites");
  };

  const handleClickBack = () => {
    setStep("individualManager");
  };

  const handleClickMonitoringNext = () => {
    setStep("monitoring");
  }
  const handleClickMonitoringBack = () => {
    setStep("monitoring");
  }

  return (
    <>
      {step === "individualManager" && !showMonitoringStep && (
        <IndividualManager
          mandateId={mandateId}
          onClickNext={handleClickNext}
        />
      )}
      {step === "individualManager" && showMonitoringStep && (
        <IndividualManager
          mandateId={mandateId}
          onClickNext={handleClickMonitoringNext}
        />
      )}

      {step === "monitoring" && (
        <ApplicationsMonitoring
        mandateId={mandateId}
        onClickNext={handleClickNext}
        onClickBack={handleClickBack}
        />
      )}

      {step === "sendInvites" && !showMonitoringStep && (
        <SendInvites mandateId={mandateId} onClickBack={handleClickBack} />
      )}

      {step === "sendInvites" && showMonitoringStep && (
        <SendInvites mandateId={mandateId} onClickBack={handleClickMonitoringBack} />
      )}

    </>
  );
};
