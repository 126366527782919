import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  mandate: number;
  company_name: string;
  country: number;
  entity_type: number;
  registeration_number: string;
  date_of_incorporation: string;
  // dissolution_date: string;
}

export const useCreateEntity = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params) => {
    return axios.post(`${env.verifyServiceUrl}individual/entity/`, params, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
