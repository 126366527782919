import styled from "styled-components";
import { space, color, typography, flexbox, layout, borders } from "styled-system";

export const H1 = styled.h1<any>`
  ${({ theme: { colors, fontSizes, space, breakpoints } }) => `
        color: ${colors.blue[100]};
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[6]};
        margin-bottom: ${space[1]};
        margin-top: ${space[0]};

        @media screen and (max-width: ${breakpoints.md}) {
          font-size: ${fontSizes[4]};
        }
    `};

  ${typography}
  ${color}
  ${space}
  ${flexbox}
  ${layout}
`;

export const H2 = styled.h2<any>`
  ${({ theme: { colors, fontSizes, space, breakpoints } }) => `
        color: ${colors.blue[100]};
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[4]};
        margin-bottom: ${space[1]};
        margin-top: ${space[0]};

        @media screen and (max-width: ${breakpoints.md}) {
          font-size: ${fontSizes[3]};
        }
    `};

  ${typography}
  ${color}
  ${space}
  ${flexbox}
  ${layout}
`;

export const H3 = styled.h3<any>`
  ${({ theme: { colors, fontSizes, space } }) => `
        color: ${colors.blue[100]};
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[3]};
        margin-bottom: ${space[1]};
        margin-top: ${space[0]};
    `};

  ${typography}
  ${color}
  ${space}
  ${flexbox}
  ${layout}
  ${borders}
`;

export const H4 = styled.h3<any>`
  ${({ theme: { colors, fontSizes, space } }) => `
        color: ${colors.blue[100]};
        font-family: "Inter", sans-serif;
        font-size: ${fontSizes[2]};
        margin-bottom: ${space[1]};
        margin-top: ${space[0]};
    `};

  ${typography}
  ${color}
  ${space}
  ${flexbox}
  ${layout}
  ${borders}
`;
