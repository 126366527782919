import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { EntityType } from "../types/CompanyType";
import { env } from "../utils/env";
import { entityTypesFallback } from "./fallbackPayloads/companyTypes";
import { asAxiosResponse } from "./fallbackPayloads/utilities";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetchEntityTypes = (authHeader: string) => () =>
  axios.get<EntityType[]>(`${env.verifyServiceUrl}common/entity_type_list/`, {
    headers: {
      Authorization: authHeader,
    },
  });
export const useEntityTypes = () => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["companyTypes"], fetchEntityTypes(authHeader), {
    initialData: () => asAxiosResponse(entityTypesFallback),
    retry: false,
    onError: (err: any) => {
      if (err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
      if (err.request.status === 401) {
        navigate(ROUTE.LOGOUT);
      }
    },
  });
};
