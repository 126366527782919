import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H1 } from "../../components/Heading";
import { Input } from "../../components/Input";
import { Paragraph } from "../../components/Paragraph";
import { ROUTE } from "../../routes";
import { Image } from "../../components/Image";
import tillerVerifySvg from "../../assets/verify-tiller.svg";
import { useState } from "react";
import loginSvg from "../../assets/login.svg";
import { useRecoveryCode } from "../../data/useRecoveryCode";
import { extractErrorMessage, showError } from "../../utils/error-handling";
import { end } from "@popperjs/core";
import { useAuth } from "../../context/AuthContext";

const RecoveryCode = () => {
  const [doubleClick, setDoubleClick] = useState(false);
  const navigate = useNavigate();
  const { mutate: recoveryCode } = useRecoveryCode();
  const { logout, isLoggedIn } = useAuth();

  const handleSubmit = (code, setFieldError) => {
    setDoubleClick(true);
    recoveryCode(code, {
      onSuccess: () => {
        setDoubleClick(false);
        navigate(ROUTE.APPLICATIONS);
      },
      onError: (err: any) => {
        const errorMessage = extractErrorMessage(
          err,
          "The code entered is invalid or already used."
        );
        setFieldError("recoveryCode", errorMessage);
        showError(err, "The code entered is invalid or already used.");
        setDoubleClick(false);
      },
    });
  };

  return (
    <Flex height="100vh">
      <Flex width="900px" flexDirection="column" p="60px" overflowY="auto">
        <Image
          src={tillerVerifySvg}
          alt="tiller verify logo"
          height="200px"
          width="160px"
        />

        <H1>Enter a Recovery Code</H1>
        <Paragraph my={4}>
          You can use a recovery code to access your account if you no longer
          have access to your authenticator app. Enter one of the codes from
          your recovery list below.
        </Paragraph>
        <Formik
          initialValues={{ recoveryCode: "" }}
          onSubmit={(values, { setFieldError }) => {
            handleSubmit(values.recoveryCode, setFieldError);
          }}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit}>
              <Flex
                gap="6"
                alignItems={"center"}
                justifyContent={"space-around"}
              >
                <Input
                  width={"100%"}
                  onChange={props.handleChange}
                  value={props.values.recoveryCode}
                  placeholder="Enter recovery code"
                  label="Recovery code"
                  name="recoveryCode"
                  isRequired
                  hasError={Boolean(props.errors.recoveryCode)}
                  errorMessage={props.errors.recoveryCode}
                />
              </Flex>
              <Flex my={2} justifyContent={"end"}>
                <Button
                  type="submit"
                  isDisabled={
                    !props.dirty ||
                    (props.dirty && !props.isValid) ||
                    doubleClick
                  }
                  mt="2"
                  size="large"
                  width="100%"
                >
                  Verify Recovery Code
                </Button>
              </Flex>

              <Paragraph my={4}>I don’t have recovery codes</Paragraph>

              <Flex justifyContent={end}>
                <Button
                  p={0}
                  variant="blueText"
                  onClick={() =>
                    navigate(ROUTE.USE_RECOVERY_EMAIL, { replace: true })
                  }
                >
                  Verify via Email
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      <Flex
        bg={"background"}
        display={{ _: "none", md: "flex" }}
        height="100vh"
        width="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Image
          src={loginSvg}
          alt="login image"
          display="block"
          width="55%"
          height="60%"
        />
      </Flex>
      {isLoggedIn && (
        <Button
          position="absolute"
          top={2}
          right={2}
          variant="secondary"
          onClick={() => logout()}
        >
          Sign Out
        </Button>
      )}
    </Flex>
  );
};

export default RecoveryCode;
