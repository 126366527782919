import React from "react";
import { DateTime } from "luxon";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Paragraph } from "../../components/Paragraph";
import { CardLine, Heading } from ".";
import { employmentDetail } from "../../types/Individual";
import { Image } from "../../components/Image";
import { EmploymentIcons } from "./helper";
import { H3 } from "../../components/Heading";

type Accumulator = {
  [currencyCode: string]: { symbol: string; total: number };
};

// Top-level Employment Card
const EmploymentCard: React.FC<{ employmentDetails: employmentDetail[] }> = ({
  employmentDetails,
}) => {
  const currentEmployments = employmentDetails.filter(
    (job) => job.is_current === true
  );
  const previousEmployments = employmentDetails.filter(
    (job) => job.is_current !== true
  );


  return (
    <>
      {(currentEmployments?.length > 0) && (
        <>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading>Current Income and Employment Details</Heading>
            <IncomeDetails employments={currentEmployments} />
          </Flex>
          <hr></hr>
          <EmploymentDetails employments={currentEmployments} />
        </>
      )}
      {(previousEmployments?.length > 0) && (
        <>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Heading>Previous Income and Employment History</Heading>
          </Flex>
          <hr></hr>
          <EmploymentDetails
            employments={previousEmployments}
          />
        </>
      )}
    </>
  );
};

// Income Details Component
const IncomeDetails: React.FC<{ employments: employmentDetail[] }> = ({
  employments,
}) => {
  const totalIncomeByCurrency = employments.reduce<Accumulator>((acc, job) => {
    const currency = job.currency.code;
    const income = job.income;

    if (!acc[currency]) {
      acc[currency] = { symbol: job.currency.symbol, total: 0 };
    }

    acc[currency].total += income;
    return acc;
  }, {} as { [currency: string]: { symbol: string; total: number } });

  return (
    <Box>
      {Object.entries(totalIncomeByCurrency).map(([currency, data]) => (
        <CardLine key={currency}>
          Total Annual Income &nbsp; &nbsp;
          <span style={{
            fontSize: 16,
            color:"black"
          }}>
            {" "}
            {currency} ({data.symbol}){data.total.toLocaleString()}
          </span>
        </CardLine>
      ))}
    </Box>
  );
};

// Employment Details Section
const EmploymentDetails: React.FC<{
  employments: employmentDetail[];
  title?: string;
}> = ({ employments, title }) => (
  <Box mb={3}>
    {title && <Heading my={3}>{title}</Heading>}
    {employments.map((employment) => (
      <EmploymentItem key={employment.id} employment={employment}
      />
    ))}
  </Box>
);

// Individual Employment Item
const EmploymentItem: React.FC<{ employment: employmentDetail }> = ({
  employment,
}) => (
  <Box mb={2}>
    <Flex gap="8" my={1} alignItems={"center"}>
      <Image
        src={EmploymentIcons[employment.employment_type.name]}
        alt="indicator icon"
        height="20px"
        width="24px"
      />
      <H3 fontSize={2} mb={0}>
        {employment.employment_type.name}
      </H3>
    </Flex>
    <Flex>
      <Box flex={1}>
        <Heading>Date</Heading>
        {employment?.start_date && (
          <>
            <Paragraph my={1} fontSize={1}>
              Start Date
            </Paragraph>
            <CardLine>
              {DateTime.fromISO(employment.start_date).toFormat("dd MMM yyyy")}
            </CardLine>
          </>
        )}
        {employment?.end_date && (
          <>
            <Paragraph my={1} fontSize={1}>
              End Date
            </Paragraph>
            <CardLine>
              {DateTime.fromISO(employment.end_date).toFormat("dd MMM yyyy")}
            </CardLine>
          </>
        )}
      </Box>
      <Box flex={1}>
        {(employment.role || employment.company_name) && (
          <>
            <Heading>Details</Heading>
            {employment.role && <CardLine>{employment.role}</CardLine>}
            {employment.company_name && (
              <CardLine>{employment.company_name}</CardLine>
            )}
          </>
        )}
      </Box>
      <Box flex={1}>
        {(employment.industry || employment.country) && (
          <>
            <Heading>Industry</Heading>
            {employment.industry && (
              <CardLine>{employment.industry?.option.name}</CardLine>
            )}
            {employment.country && (
              <CardLine>{employment.country.name}</CardLine>
            )}
          </>
        )}
      </Box>
      <Box flex={1}>
        <Heading>Annual Income</Heading>
        {employment.currency && employment.income && (
          <CardLine>
            {employment.currency.symbol}
            {employment.income.toLocaleString()}
          </CardLine>
        )}
      </Box>
    </Flex>
  </Box>
);

export default EmploymentCard;
