import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useState, useEffect, useMemo } from "react";
import { DateTime } from "luxon";
import theme from "../../theme";
import { FaSortUp } from "react-icons/fa";
import { FaSortDown } from "react-icons/fa";
import { Icon } from "../Icon";
import { StyledLink } from "../Breadcrumbs";
import { Flex } from "../Flex";
import { useMonitoringList } from "../../data/useMonitoringList";
import { Image } from "../Image";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import pending from "../../assets/pending-icon.svg";
import { Loader } from "../Loader";
import { Paragraph } from "../Paragraph";

export const MonitoringTable = (props) => {
  const { searchTerm, filterStatusIds, filters } = props;

  const [data, setData] = useState<any>({});
  const [filtersUpdate, setFiltersUpdate] = useState({ ...filters });
  const [searchTermUpdate, setSearchTermUpdate] = useState(props.searchTerm);
  const [filterStatusIdsUpdate, setFilterStatusIdsUpdate] = useState(
    props.filterStatusIds
  );
  const [reference, setreference]= useState(props.reference);

  const monitoringIcons = {
    active: success,
    "requires review": inReview,
    pending: pending,
  };


  const cols = [
    {
      header: () => "Individual Name",
      accessorKey: "first_name",
      cell: (info) => {
        const { first_name, last_name } = info.row.original; // Access the original row data
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "#09093E" }}
          >
            {`${first_name} ${last_name}`}
          </span>
        );
      },
    },
    {
      header: () => "Status",
      accessorKey: "status",
      cell: (info) => {
        const status = info.getValue();
        return (
          <Flex justifyContent="start" alignItems="center" >
              <span key={status.id} style={{ margin: "0 5px" }}>
              <Image 
                   src={monitoringIcons[status.status]}
                    alt="checks"
                    height="auto"
                    width="22px"
                    mr={1}
                  />

                 {status.label}
              </span>
          </Flex>
        );
      },
     },
    {
      header: () => "ID",
      accessorKey: "reference",
      cell: (info) => {
        return (
          <Flex
            justifyContent="start"
            alignItems="center"
            position="relative"
            color={theme.colors.gray[40]}
          >
            {info.getValue()}
          </Flex>
        );
      },
    },
    {
      header: () => "Date Started",
      accessorKey: "created_on",
      cell: (info) => {
        return (
          <Flex color={theme.colors.gray[40]}>
            {info.getValue()
                      ? DateTime.fromISO(info.getValue()).toFormat("dd/MM/yyyy") : "_"}
          </Flex>
        );
      },
    },
    {
      header: () => "Last Activity",
      accessorKey: "last_activity",
      cell: (info) => {
        return (
          <Flex color={theme.colors.gray[40]}>
             {info.getValue()
                      ? DateTime.fromISO(info.getValue()).toFormat(
                        "dd/MM/yyyy"
                      )
                      : "-"}
          </Flex>
        );
      },
    },
    {
      header: () => "",
      id: "view_id",
      accessorKey: "id",
      cell: (info) => {
        const { id} =
          info.row.original;
        let path = `${id}`;
        if(info.row.original.status.status !== "pending"){
          return (
            <StyledLink to={path}>
              <span
                style={{
                  color: theme.colors.blue.primary,
                }}
              >
                View
              </span>
            </StyledLink>
          );
        } else {
          return null;
        }
        
      },
    },
  ];

  const [columns] = useState([...cols]);
  const [{ pageIndex, pageSize }, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const [sorting, setSorting] = useState<SortingState>([
    { id: "last_activity", desc: true },
  ]);

  const getMonitoringData = useMonitoringList(
    reference,
    filterStatusIdsUpdate,
    searchTermUpdate,
    filtersUpdate,
    pageIndex + 1,
    pageSize,
    sorting[0]?.desc ? "dsc" : "asc",
    sorting[0]?.id
  );

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 10 });
    setFiltersUpdate(filters);
    setSearchTermUpdate(searchTerm);
    setFilterStatusIdsUpdate(filterStatusIds);
  }, [searchTerm, searchTermUpdate, filterStatusIds, filters]
  );

   useEffect(() => {
     setData(() => getMonitoringData?.data?.data);
   }, [getMonitoringData]);

  const pagination = useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  const table = useReactTable({
    data: data?.results?.filter(row => !row.deleted) ?? [],
    columns,
    state: {
      pagination,
      sorting,
    },
    pageCount: Math.ceil(data?.count / pageSize) ?? -1,
    debugTable: true,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    manualPagination: true,
    manualSorting: true,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      {getMonitoringData.isFetching && (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      )}

      {!getMonitoringData.isFetching && data?.results?.length === 0 && (
        <Paragraph m={3} color="gray.40" textAlign="center">
          No Monitoring Data
        </Paragraph>
      )}

{!getMonitoringData.isFetching && data?.results?.length > 0 && (
        <div
          className="tableContainer"
          style={{ height: "calc(100vh - 230px)" }}
        >
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup?.id}>
                  {headerGroup?.headers.map((header) => (
                    <th key={header?.id} colSpan={header?.colSpan}>
                      {header?.isPlaceholder ? null : (
                        <>
                          <div
                            {...{
                              className: header?.column.getCanSort()
                                ? "select-none cursor-pointer flex items-center gap-1"
                                : "",
                              onClick: header?.column.getToggleSortingHandler(),
                            }}
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortDown}
                                ></Icon>
                              ),
                              desc: (
                                <Icon
                                  display="inline-block"
                                  ml={1}
                                  size="20px"
                                  color="gray.40"
                                  Type={FaSortUp}
                                ></Icon>
                              ),
                            }[header?.column.getIsSorted() as string] ?? null}
                          </div>
                        </>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table?.getRowModel().rows.map((row, index) => (
                <tr key={row?.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell?.id}>
                      {flexRender(
                        cell?.column.columnDef.cell,
                        cell?.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Flex
        className="pagination"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex>
          <span>
            Rows per page &nbsp;&nbsp;
            <select
              className="pageSizeDropdown"
              value={table.getState().pagination.pageSize}
              onChange={(e) => table.setPageSize(Number(e.target.value))}
            >
              {[5, 10, 20, 50, 100].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </span>
        </Flex>

        <Flex>
          <span style={{ display: "flex", alignItems: "center" }}>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </span>
          &nbsp;&nbsp;&nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.setPageIndex(0)}
          >
            &lt;&lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanPreviousPage()}
            onClick={() => table.previousPage()}
          >
            &lt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.nextPage()}
          >
            &gt;
          </button>
          &nbsp;
          <button
            disabled={!table.getCanNextPage()}
            onClick={() => table.setPageIndex(table.getPageCount() - 1)}
          >
            &gt;&gt;
          </button>
        </Flex>
      </Flex>
    </div>
  );
};
