import { collapseErrors, extractErrors } from "./api";
import { notify } from "./notify";

/** Replace with an actual error logged in the future if needed, e.g. Sentry */
export const logError = (err: string) => {
  console.error(err);
};

/** A generic function to inform the user that there has been an error. */
export const displayError = (err: string) => {
  notify(err, {
    position: "bottom-left",
    type: "error",
  });
};

/** Wrapper function for the above functions. To be used with react-query. */
export const handleQueryError =
  (defaultError: string, includeErrorKeys = true) =>
  (err: any) => {
    displayError(
      collapseErrors(extractErrors(err), includeErrorKeys) || defaultError
    );
    logError(err);
  };

export const extractErrorMessage = (
  error: any,
  defaultError: string
): string => {
  const errorResponseData = error.response?.data;
  if (!errorResponseData) return defaultError;

  const exception = errorResponseData?.exception;

  const handleArrayException = (): string | undefined => {
    return typeof exception[0] === "string" ? exception[0] : undefined;
  };

  const handleObjectException = () => {
    let strss = [];
    for (let value of Object.values(errorResponseData?.exception)) {
      if (typeof value === "string" || value instanceof String) {
        strss.push(value);
      } else if (typeof value[0] === "string" || value[0] instanceof String) {
        strss.push(value[0]);
      } else {
        for (let obj of Object.values(value[0])) {
          strss.push(obj[0]);
        }
      }
    }
    return strss[0];
  };

  return exception && typeof exception === "string"
    ? exception
    : Array.isArray(exception)
    ? handleArrayException() || defaultError
    : typeof exception === "object"
    ? handleObjectException() || defaultError
    : defaultError;
};

export const showError = (error: any, defaultError: string) => {
  const errorMessage = extractErrorMessage(error, defaultError);
  displayError(errorMessage);
  logError(error);
};
