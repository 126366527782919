import { DateTime } from "luxon";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumb } from "../components/Breadcrumbs";
import { Comments } from "../components/Comments";
import { Flex } from "../components/Flex";
import { H3 } from "../components/Heading";
import { Loader } from "../components/Loader";
import { Paragraph } from "../components/Paragraph";
import Topbar from "../components/Topbar";
import { useIndividual } from "../data/useIndividual";
import { ROUTE } from "../routes";
import { buildName } from "../utils/string";
import { CheckSection } from "./IndividualView/CheckSection";
import { Image } from "../components/Image";
import { Box } from "../components/Box";
import { Button } from "../components/Button";
import commentsIcon from "../assets/comments-icon.svg";
import { Card } from "../components/Card";
import { AddressVerificationCheck } from "./IndividualView/AddressVerificationCheck";
import { AdverseMediaCheck } from "./IndividualView/AdverseMediaCheck";
import { BankDetailsCheck } from "./IndividualView/BankDetailsCheck";
import { GeoLocationCheck } from "./IndividualView/GeoLcationCheck";
import { IndividualChecksMap } from "./IndividualView/helper";
import { IdentityLivenessCheck } from "./IndividualView/IdentityLivenessCheck";
import { PepAndSanctionsCheck } from "./IndividualView/PepAndSanctionsCheck";
import { PersonalDetailsCheck } from "./IndividualView/PersonalDetailsCheck";
import { ProofOfAddressCheck } from "./IndividualView/ProofOfAddressCheck";
import { SourceOfFundsCheck } from "./IndividualView/SourceOfFundsCheck";
import axios from "axios";
import theme from "../theme";
import { showError } from "../utils/error-handling";
import { notify, clearAll } from "../utils/notify";
import { useDeleteIndividual } from "../data/useDeleteIndividual";
import { useAuth } from "../context/AuthContext";
import downloadIcon from "../assets/download-arrow.svg";
import { Modal } from "../components/Modal";
import { FaTrash } from "react-icons/fa";
import { IconButtonDropdown } from "../components/IconButtonDropdown";
import { IconButtonDropdownOption } from "../components/IconButtonDropdown/IconButtonDropdownOption";
import { env } from "../utils/env";
import resendInviteIcon from "../assets/resend-invite-icon.svg";
import {
  MandateStatus,
  useMandateStatuses,
} from "../context/MandateStatusContext";
import { useMandate } from "../data/useMandate";
import monitoringIcon from "../assets/monitoring-icon.svg";
import { capitalise } from "../utils/string";
import { useMonitoringList } from "../data/useMonitoringList";
import success from "../assets/card-success-icon.svg";
import inReview from "../assets/card-in-review-icon.svg";
import pending from "../assets/pending-icon.svg";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;

const Container = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]};
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-bottom: ${space[5]}
  `}
`;

const CardLine = ({ children, ...styleProps }: any) => (
  <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
    {children}
  </Paragraph>
);

export const IndividualChecksView = () => {
  const params = useParams();
  const { authHeader } = useAuth();
  const navigate = useNavigate();

  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isCommentsOpen, setIsCommentsOpen] = useState(false);
  const [isRequestingPdfDownload, setIsRequestingPdfDownload] = useState(false);
  const [checkContent, setCheckContent] = useState(null);

  const getCheckComponent = (name, id) => {
    if (name === IndividualChecksMap.address_verification_check.name) {
      return (
        <AddressVerificationCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.proof_of_address_check.name) {
      return (
        <ProofOfAddressCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap["pep_&_sanctions_check"].name) {
      return (
        <PepAndSanctionsCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.bank_account_details_check.name) {
      return (
        <BankDetailsCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.personal_details_match_check.name) {
      return (
        <PersonalDetailsCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap["id_&_liveliness_check"].name) {
      return (
        <IdentityLivenessCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.geo_location_check.name) {
      return (
        <GeoLocationCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.adverse_media_check.name) {
      return (
        <AdverseMediaCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    if (name === IndividualChecksMap.source_of_funds_check.name) {
      return (
        <SourceOfFundsCheck
          isOpen={true}
          onRequestClose={() => { }}
          id={id}
          useSlider={false}
        />
      );
    }
    return null;
  };

  const { data: individualData, isLoading } = useIndividual(params.id);

  const individual = individualData?.data;

  const individualName = isLoading
    ? "..."
    : buildName([
      individual?.first_name,
      individual?.middle_name,
      individual?.last_name,
    ]);

  const { getMandateStatusId } = useMandateStatuses();

  const { data: mandateData } = useMandate(individual?.mandate);
  const mandate = mandateData?.data;

  const { mutate: deleteIndividual } = useDeleteIndividual();

  const canDelete =
    mandate?.status?.id !== getMandateStatusId(MandateStatus.Rejected);

  const { data: monitoringList } = useMonitoringList(individual?.reference);
  const monitoringIcons = {
    active: success,
    "requires review": inReview,
    pending: pending,
  };

  useEffect(() => {
    if (individual?.all_checks && individual.all_checks.length > 0) {
      const firstCheck = individual.all_checks[0];
      const checkComponent = getCheckComponent(firstCheck.name, firstCheck.id);
      setCheckContent(checkComponent);
    }
  }, [individual]);

  const handleShowContent = (content) => {
    setCheckContent(content);
  };

  const handleDownloadPdfClick = () => {
    if (isRequestingPdfDownload) {
      return;
    }

    setIsRequestingPdfDownload(true);
    notify("Generating PDF, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: false,
    });

    axios
      .get(`${env.verifyServiceUrl}individual/download_pdf/${params.id}`, {
        headers: {
          Authorization: authHeader,
        },
        responseType: "arraybuffer",
      })
      .then((response) => {
        clearAll();
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const timestamp = DateTime.now().toFormat("yyyy-MM-dd_HH-mm-ss");

        link.download = `Customer Report - ${individual?.reference} - ${individual?.first_name
          } ${individual?.middle_name || ""} ${individual?.last_name
          } - ${timestamp}.pdf`;

        link.click();
      })
      .catch(() => {
        clearAll();
        setTimeout(() => {
          notify("There was a problem while generating the PDF report", {
            position: "bottom-left",
            type: "error",
          });
        }, 100);
      })
      .finally(() => {
        setIsRequestingPdfDownload(false);
      });
  };

  const handleConfirmDeleteIndividual = () => {
    setIsConfirmDeleteOpen(false);

    deleteIndividual(
      { id: params.id },
      {
        onSuccess: () => {
          navigate(`/checks`);
          notify("Individual removed", {
            position: "bottom-left",
            type: "success",
          });
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while deleting the individual.");
        },
      }
    );
  };

  return (
    <div>
      <Topbar
        header={
          <Flex justifyContent="space-between" width="100%">
            <>
              <Breadcrumb>
                <Breadcrumb.Section linkTo={ROUTE.CHECKS}>
                  Screening
                </Breadcrumb.Section>

                <Breadcrumb.Section>{individualName}</Breadcrumb.Section>
              </Breadcrumb>
              <Flex justifyContent="space-between">
                <Button
                  onClick={() => setIsCommentsOpen(!isCommentsOpen)}
                  mr={1}
                  variant="secondary"
                >
                  <Image
                    src={commentsIcon}
                    alt="comments"
                    height="auto"
                    width="22px"
                    mr={1}
                  />
                  Comments
                </Button>

                {individual?.can_download_pdf && (
                  <Button
                    onClick={handleDownloadPdfClick}
                    isDisabled={isRequestingPdfDownload}
                    variant="secondary"
                    color={"#2D374B"}
                    mr={1}
                  >
                    <Image
                      src={downloadIcon}
                      alt="download pdf"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    <Paragraph fontWeight="500" fontSize={theme.fontSizes[1]}>
                      {isRequestingPdfDownload
                        ? "Downloading..."
                        : "Download PDF"}
                    </Paragraph>
                  </Button>
                )}

                {canDelete && (
                  <IconButtonDropdown placement="bottom-end">
                    <IconButtonDropdownOption
                      onClick={() => setIsConfirmDeleteOpen(true)}
                      IconType={FaTrash}
                      isDestructive
                    >
                      Delete Individual
                    </IconButtonDropdownOption>
                  </IconButtonDropdown>
                )}
              </Flex>
            </>
          </Flex>
        }
      ></Topbar>

      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Container>
            <Flex gap="24">
              <Box width={"20%"}>
                <H3 mb={2}>Details </H3>

                <Card mb={0}>
                  <Heading>Reference</Heading>
                  <CardLine mb={3}>{individual?.reference}</CardLine>
                  <Button
                    mb={3}
                    onClick={() =>
                      navigate(`/applications/new`, {
                        state: { checks: true, id: individual?.mandate },
                      })
                    }
                    mr={1}
                    variant="gray"
                    paddingLeft={2}
                    paddingRight={0}
                    backgroundColor="gray.10"
                    border="2px solid background"
                    color="blue.primary"
                    fontWeight={600}
                    isDisabled={mandate?.checks_in_pending !== 0}
                  >
                    <Image
                      src={resendInviteIcon}
                      alt="check to application"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    Convert to application
                  </Button>

                  {/* <Heading>Monitoring</Heading>

                  {monitoringList?.data?.count > 0 && (
                    <div>
                      <Flex mt={2}>
                        <Image
                          src={monitoringIcons[monitoringList?.data?.results[0]?.status.status]}
                          alt="checks"
                          height="20px"
                          width="24px"
                        />
                        <Paragraph color="gray.70" my="0px" ml={1}>
                          {monitoringList?.data?.results[0]?.status.status}
                        </Paragraph>
                      </Flex>

                      <Button
                        mb={3}
                        mr={1}
                        mt={2}
                        variant="gray"
                        paddingLeft={2}
                        backgroundColor="gray.10"
                        border="2px solid background"
                        color="blue.primary"
                        fontWeight={600}
                        size="regular"
                        onClick={() =>
                          navigate(`/monitoring/${monitoringList.data.results[0].id}`, {
                          })
                        }
                      >
                        View Monitoring
                      </Button>
                    </div>
                  )}
                  {monitoringList?.data.count == 0 && (
                    <Button
                      mb={3}
                      onClick={() =>
                        navigate(`/checks/activate-monitoring-individual/${individual?.id}`, {
                          state: { id: individual?.id, mandateId: individual?.mandate },
                        })
                      }
                      mr={1}
                      variant="gray"
                      paddingLeft={2}
                      backgroundColor="gray.10"
                      border="2px solid background"
                      color="blue.primary"
                      fontWeight={600}
                      isDisabled={individual.checks.pending.length > 0}
                    >
                      <Image
                        src={monitoringIcon}
                        alt="Activate monitoring"
                        height="auto"
                        width="22px"
                        mr={1}
                      />
                      Activate Monitoring
                    </Button>
                  )} */}

                  <Heading>Checks completed</Heading>
                  <CardLine mb={3}>
                    {individual?.checks_completed_date
                      ? DateTime.fromISO(
                        individual?.checks_completed_date
                      ).toFormat("dd/MM/yyyy")
                      : "-"}
                  </CardLine>
                  <Heading>Name</Heading>
                  <CardLine mb={3}>
                    {individual?.first_name} {individual?.middle_name}{" "}
                    {individual?.last_name}
                  </CardLine>
                  <Heading>Born</Heading>
                  <CardLine mb={3}>
                    {individual?.date_of_birth
                      ? DateTime.fromISO(individual?.date_of_birth).toFormat(
                        "dd/MM/yyyy"
                      )
                      : "-"}
                  </CardLine>
                  <Heading>Gender</Heading>
                  {/* <CardLine mb={3}>{capitalise(individual?.gender)}</CardLine> */}
                  <Heading>Address</Heading>
                  {individual?.address ? (
                    <>
                      <CardLine>
                        {individual?.address?.flat_appartment_subbuilding && (
                          <span>
                            {individual?.address?.flat_appartment_subbuilding}
                            &nbsp;
                          </span>
                        )}
                        {individual?.address?.building_number && (
                          <span>
                            {individual?.address?.building_number}
                            &nbsp;
                          </span>
                        )}
                        {individual?.address?.building_name && (
                          <span>
                            {individual?.address?.building_name}
                            &nbsp;
                          </span>
                        )}
                        {individual?.address?.road_street}
                      </CardLine>
                      <CardLine>
                        {individual?.address?.town_city && (
                          <span>
                            {individual?.address?.town_city}
                            &nbsp;
                          </span>
                        )}
                        {individual?.address?.district}
                      </CardLine>
                      <CardLine>{individual?.address?.post_zip_code}</CardLine>

                      <Flex
                        gap="8"
                        alignItems={"center"}
                        mb={3}
                        fontSize={1}
                        color="gray.60"
                        fontFamily={"'Inter', sans-serif"}
                      >
                        {individual?.address?.country_flag && (
                          <Box
                            backgroundColor={"gray.10"}
                            borderRadius={"50%"}
                            p={"1"}
                          >
                            <img
                              src={`data:image/png;base64,${individual?.address?.country_flag}`}
                              alt="iamge"
                            />
                          </Box>
                        )}
                        {individual?.address?.country_flag && (
                          <span>
                            &nbsp;
                            {individual?.address?.country_name}
                          </span>
                        )}
                      </Flex>
                    </>
                  ) : (
                    "-"
                  )}
                  <Heading>Delete date</Heading>
                  <CardLine mb={3}>
                    {individual?.delete_date
                      ? DateTime.fromISO(individual?.delete_date).toFormat(
                        "dd/MM/yyyy"
                      )
                      : "-"}
                  </CardLine>

                  <Paragraph color={"gray.60"} fontSize={1}>
                    These checks will be deleted automatically on this date as
                    part of our data retention policy.
                  </Paragraph>
                </Card>
              </Box>

              <Box width={"20%"}>
                <H3 mb={2}>Checks</H3>

                <Flex gap="8" flexWrap={"wrap"}>
                  {individual?.all_checks?.map(
                    ({ id, name, status, title, updated_at }, i) => (
                      <Box width={"100%"} key={i}>
                        <CheckSection
                          title={title}
                          status={status}
                          id={id}
                          name={name}
                          date={updated_at}
                          onShowContent={handleShowContent}
                          useSlider={false}
                        />
                      </Box>
                    )
                  )}
                </Flex>
              </Box>

              <Box width={"60%"}>
                {checkContent && (
                  <>
                    <H3 mb={2}>Check details</H3>
                    <Card p={"0px !important"}>{checkContent}</Card>
                  </>
                )}
              </Box>
            </Flex>
          </Container>
          <Comments
            isOpen={isCommentsOpen}
            onRequestClose={() => setIsCommentsOpen(false)}
            referenceId={individual?.id}
            commentType="Individual"
          />
          <Modal
            isOpen={isConfirmDeleteOpen}
            onClose={() => setIsConfirmDeleteOpen(false)}
            onModalConfirmClick={handleConfirmDeleteIndividual}
            title="Do you want to delete individual?"
            content="This is an irreversible action, if you delete it you will not be able to undo the change. "
            hasSecondaryButton
            secondaryButtonText="Cancel"
            width="450px"
            primaryButtonText="Delete"
            primaryButtonVariant="danger"
          />
        </>
      )}
    </div>
  );
};
