import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { DateTime } from "luxon";
import { PagedResponse } from "../types/Common";
import { Monitoring } from "../types/Monitoring";
import { ROUTE } from "../routes";
import { useNavigate } from "react-router-dom";

const fetch = async (
  authHeader: string,
  individual_reference?:any,
  status?: string,
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const url = new URL(
    `${env.verifyServiceUrl}individual/monitoring/`
  );

  // Manually encode the string, ensuring no double encoding
  if(individual_reference != null || individual_reference != undefined)  {
    url.searchParams.set("individual_reference", individual_reference);
  }

  if( filters?.status != null || filters?.status != undefined)  {
    url.searchParams.set("status", filters.status);
  }
  if (searchTerm?.length > 0) {
    url.searchParams.set("search", searchTerm);
  }
  if (filters?.created_on_date?.[0]) {
    url.searchParams.set(
      "created_on_after",
      DateTime.fromJSDate(filters?.created_on_date[0]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters?.created_on_date?.[1]) {
    url.searchParams.set(
      "created_on_before",
      DateTime.fromJSDate(filters.created_on_date[1]).toFormat("yyyy-MM-dd")
    );
  }

  if (filters?.last_activity_date?.[0]) {
    url.searchParams.set(
      "last_activity_after",
      DateTime.fromJSDate(filters?.last_activity_date[0]).toFormat("yyyy-MM-dd")
    );
  }
  if (filters?.last_activity_date?.[1]) {
    url.searchParams.set(
      "last_activity_before",
      DateTime.fromJSDate(filters?.last_activity_date[1]).toFormat("yyyy-MM-dd")
    );
  }

  if (page) {
    url.searchParams.set("page", page.toString());
  }

  if (Number.isInteger(page_size)) {
    url.searchParams.set("page_size", page_size.toString());
  }

  if (sort_by && order_by) {
    const prefix = sort_by === "dsc" ? "-" : "";
    url.searchParams.set("ordering", `${prefix}${order_by}`);
  }

  return axios.get<PagedResponse<Monitoring[]>>(url.href, {
    headers: {
      Authorization: authHeader,
    },
  });
};

export const useMonitoringList = (
  individual_reference?:any,
  status?: string,
  searchTerm?: string,
  filters?: any,
  page?: number,
  page_size?: number,
  sort_by?: string,
  order_by?: string
) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(
    [
      "monitoringList",
      individual_reference,
      status,
      searchTerm,
      filters,
      page,
      page_size,
      sort_by,
      order_by,
    ],
    () =>
      fetch(
        authHeader,
        individual_reference,
        status,
        searchTerm,
        filters,
        page,
        page_size,
        sort_by,
        order_by
      ),
    {
      keepPreviousData: true,
      retry: true,
      onError: (err: any) => {
        if(err.request.status === 500 || err.request.status === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }
      }
    }
  );
};
