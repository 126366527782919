import { Formik, Form } from "formik";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2, H4 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { showError } from "../../utils/error-handling";
import { notify } from "../../utils/notify";
import { useState } from "react";
import { useDirtyForm } from "./DirtyFormContext";
import { queryClient } from "../../queryClient";
import { Paragraph } from "../../components/Paragraph";
import RangeSlider from "../../components/RangeSlider";
import { Toggle } from "../../components/Toggle";
import { useClientDetails } from "../../data/useClientDetails";
import { useUpdateClient } from "../../data/useUpdateClient";
import { useAuth } from "../../context/AuthContext";
import { Client } from "../../types/Client";

const SettingsIncomeAndEmployment = () => {
  const [doubleClick, setDoubleClick] = useState(false);

  const { setFormDirty, formRef } = useDirtyForm();

  const { userDetails } = useAuth();

  const { data: client, isLoading } = useClientDetails(userDetails?.tenant);

  const { mutate: updateClient, isLoading: isUpdatingClient } =
    useUpdateClient();

  const initialState = {
    employment_history_min: 5,
    capture_employment_history: false,
  };

  const handleIncomeEmploymentSubmit = (data: Client) => {
    setDoubleClick(true);
    setFormDirty(false); // Reset the form dirty state before submitting
    updateClient(
      {
        id: userDetails?.tenant,
        capture_employment_history: data.capture_employment_history,
        employment_history_min: data.capture_employment_history? data.employment_history_min: 5,
      },
      {
        onSuccess: (res) => {
          setDoubleClick(false);
          if (res?.status?.toString().startsWith("2")) {
            notify("Income & Employment details updated", {
              type: "success",
            });
            queryClient.invalidateQueries([
              "clientDetails",
              userDetails?.tenant,
            ]);
          }
        },
        onError: (err: any) => {
          setDoubleClick(false);
          showError(
            err,
            "A problem occurred while updating the company details."
          );
        },
      }
    );
  }

  return (
    <>
      <Box bg="white" borderRadius={0} p={3} mb={3}>
        {isLoading && (
          <Flex justifyContent="center" alignItems="center" minHeight="35vh">
            <Loader />
          </Flex>
        )}

        {!isLoading && (
          <>
            <Formik
              initialValues={{
                employment_history_min: client?.data?.employment_history_min || 5,
                capture_employment_history: client?.data?.capture_employment_history || false,
              }}
              enableReinitialize
              validate={() => null}
              onSubmit={(values, actions) => {
                handleIncomeEmploymentSubmit(values);
                actions.setSubmitting(false); // Make sure to set submitting to false
              }}
              innerRef={formRef}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit}>
                  <Box>
                    <Flex justifyContent="space-between">
                      <H2>Income & Employment</H2>
                      <Button
                        onClick={() => {
                          props.resetForm({ values: initialState });
                          props.submitForm();
                        }}
                        variant="text"
                        type="button"
                        fontWeight={"bold"}
                      >
                        Reset all
                      </Button>
                    </Flex>
                    <div style={{ marginTop: "2%"}}>
                    <H4>Income & employment history required</H4>
                    <Flex justifyContent="space-between">
                      <Paragraph width={"50%"} fontWeight="500" fontSize={1} color={"gray.60"} mt={"0"} mb={3}>Clients are required to provide details of their income & employment
                        history. </Paragraph>
                      <Toggle
                        isChecked={props.values.capture_employment_history}
                        onChange={(e: any) => {
                          setFormDirty(true);
                          props.setFieldValue("capture_employment_history", e.target.checked);
                        }}
                        mt={2}
                      />

                    </Flex>
                    </div>
                    {props.values.capture_employment_history && (
                      <>
                        <H4>Years of income & employment to capture</H4>
                        <Flex justifyContent="space-between">
                          <Paragraph width={"50%"} fontWeight="500" fontSize={1} color={"gray.60"} mt={"0"} mb={3}>Select the years of income & employment history.
                          </Paragraph>
                          <div style={{ width: "45%" }} >
                            <RangeSlider
                              min={1}
                              max={10}
                              toleranceValue={props.values ? props.values.employment_history_min : 5}
                              onChange={(e) => {
                                setFormDirty(true);
                                props.setFieldValue("employment_history_min", e);
                              }}
                            />
                          </div>
                        </Flex>
                      </>
                    )}
                  </Box>
                  <Box>
                    <Flex>
                      <Button
                        size="large"
                        mb="0"
                        type="submit"
                        isDisabled={
                          !props.dirty ||
                          (props.dirty && !props.isValid) ||
                          isUpdatingClient ||
                          doubleClick
                        }
                      >
                        Save
                      </Button>
                    </Flex>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Box>

    </>
  );
};

export default SettingsIncomeAndEmployment;
