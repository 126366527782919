import { CompanyType, EntityType } from "../../types/CompanyType";

export const companyTypesFallback = [
  {
    id: 1,
    name: "Sole Trader",
  },
  {
    id: 2,
    name: "{Partnership}",
  },
  {
    id: 3,
    name: "Limited Liability Partnership",
  },
  {
    id: 4,
    name: "Limited Company",
  },
  {
    id: 5,
    name: "Other",
  },
] as CompanyType[];

export const entityTypesFallback = [
  {
    id: 1,
    name: "Private Limited Company",
  },
  {
    id: 2,
    name: "Public Limited Company",
  },
  {
    id: 3,
    name: "Sole Proprietorship",
  },
  {
    id: 4,
    name: "Unincorporated",
  },
  {
    id: 5,
    name: "Company Limited by Guarantee",
  },
] as EntityType[];
