import { Modal } from "../../../components/Modal";
import { Input } from "../../../components/Input";
import { TextArea } from "../../../components/TextArea";
import { Button } from "../../../components/Button";
import { Box } from "../../../components/Box";
import { Flex } from "../../../components/Flex";
import Select from "../../../components/Select";
import { Formik, Form } from "formik";
import { H2 } from "../../../components/Heading";
import { Toggle } from "../../../components/Toggle";
import { Label } from "../../../components/Label";
import { FormRow } from "../../../components/Form";
import { useQuestionsFiledTypeList } from "../../../data/useQuestionsFiledTypeList";
import { validateQuestionForm } from "../../../utils/validationSchema";
import { Paragraph } from "../../../components/Paragraph";

export const FormQuestion = ({ isOpen, onRequestClose, onSave, question }) => {
  const { data: questionsFieldTypeList } = useQuestionsFiledTypeList();

  const handleAddOption = (setFieldValue, options) => {
    setFieldValue("options", [...options, ""]);
  };

  const handleOptionChange = (setFieldValue, options, index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setFieldValue("options", updatedOptions);
  };

  const handleRemoveOption = (setFieldValue, options, index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setFieldValue("options", updatedOptions);
  };

  const dataTypeOptions = questionsFieldTypeList?.data?.map(
    ({ id, name, slug_name }) => ({
      value: id,
      label: name,
      slug_name: slug_name,
    })
  );

  const handleSubmitQuestion = (values, resetForm) => {
    const transformedOptions = values.options.map((option) => {
      return option.value || option;
    });

    const fieldTypeId =
      typeof values.field_type === "object"
        ? values.field_type.id
        : values.field_type;

    const updatedValues = {
      ...question,
      ...values,
      field_type: fieldTypeId,
      meta_data:
        transformedOptions.length > 0
          ? {
              options: transformedOptions,
            }
          : null,
    };

    delete updatedValues.options;

    onSave(updatedValues);
    resetForm();

    // onRequestClose();
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={onRequestClose}>
      <Formik
        initialValues={{
          title: question?.title || "",
          description: question?.description || "",
          field_type: question?.field_type?.id || question?.field_type || "",
          required: question?.required || false,
          options: question?.meta_data?.options || [],
        }}
        validationSchema={validateQuestionForm}
        onSubmit={(values, { resetForm }) => {
          handleSubmitQuestion(values, resetForm);
        }}
      >
        {({
          values,
          handleChange,
          setFieldValue,
          handleSubmit,
          setFieldTouched,
          validateForm,
          validateField,
          errors,
          touched,
          dirty,
          isValid,
        }) => (
          <Box>
            <H2>Add Question</H2>
            <Form onSubmit={handleSubmit}>
              <Flex
                my={3}
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Label mb={"0px"}>Required</Label>
                <Toggle
                  isChecked={values.required}
                  onChange={(e: any) => {
                    setFieldValue("required", e.target.checked);
                  }}
                />
              </Flex>

              <FormRow my={3}>
                <Input
                  width={"100%"}
                  label="Title"
                  placeholder="Enter question title"
                  value={values.title}
                  onChange={handleChange}
                  name="title"
                  isRequired
                  hasError={!!errors.title}
                  errorMessage={
                    typeof errors.title === "string" ? errors.title : undefined
                  }
                />
              </FormRow>

              <FormRow my={3}>
                <TextArea
                  width={"100%"}
                  label="Description (optional)"
                  placeholder="Enter question description"
                  value={values.description}
                  onChange={handleChange}
                  name="description"
                  rows={3}
                  hasError={!!errors.description}
                  errorMessage={
                    typeof errors.description === "string"
                      ? errors.description
                      : undefined
                  }
                />
              </FormRow>

              <FormRow my={3}>
                <Select
                  width={"100%"}
                  options={dataTypeOptions}
                  onChange={(option: any) => {
                    setFieldValue("field_type", option.value); // Store only the ID

                    setFieldValue(
                      "options",
                      option.slug_name === "choice" ||
                        option.slug_name === "multi_select"
                        ? [""]
                        : []
                    );

                    setFieldTouched("field_type", true);
                    setTimeout(() => validateField("field_type"), 0);
                  }}
                  value={
                    dataTypeOptions.find(
                      (option) => option.value === values.field_type
                    ) || null
                  }
                  label="Field type"
                  isInModal
                  isRequired
                  name="dataType"
                  hasError={!!errors.field_type}
                  errorMessage={
                    typeof errors.field_type === "string"
                      ? errors.field_type
                      : undefined
                  }
                />
              </FormRow>

              {(() => {
                const selectedOption = dataTypeOptions.find(
                  (option) => option.value === values.field_type
                );

                return (
                  selectedOption?.slug_name === "choice" ||
                  selectedOption?.slug_name === "multi_select"
                );
              })() && (
                <Box mt={4}>
                  <Label>Options</Label>
                  {values.options.map((option, index) => (
                    <>
                      <Flex
                        key={index}
                        alignItems="center"
                        mt={2}
                        width={"100%"}
                      >
                        <Input
                          width={"100%"}
                          value={
                            typeof option === "string"
                              ? option || ""
                              : option.label || option.value || ""
                          }
                          placeholder={`Option ${index + 1}`}
                          onChange={(e) => {
                            const value = e.target.value;

                            // Validation for commas
                            if (value.includes(",")) {
                              setFieldValue(
                                `options[${index}].error`,
                                "Commas are not allowed"
                              );
                              return;
                            }

                            handleOptionChange(
                              setFieldValue,
                              values.options,
                              index,
                              {
                                ...option,
                                value: value,
                                label: value,
                              }
                            );
                            setFieldValue(`options[${index}].error`, ""); // Clear error if valid
                          }}
                        />

                        {option && (
                          <Button
                            onClick={() =>
                              handleRemoveOption(
                                setFieldValue,
                                values.options,
                                index
                              )
                            }
                            ml={2}
                          >
                            Remove
                          </Button>
                        )}
                      </Flex>
                      {option?.error && (
                        <Paragraph color="red.primary">
                          {option.error}
                        </Paragraph>
                      )}
                    </>
                  ))}

                  <Button
                    variant="blueText"
                    onClick={() =>
                      handleAddOption(setFieldValue, values.options)
                    }
                    mt={2}
                    p={"0px"}
                    isDisabled={
                      values.options[values.options.length - 1] === ""
                    }
                  >
                    + Add Option
                  </Button>
                </Box>
              )}

              <Flex justifyContent="end" mt={4}>
                <Button onClick={onRequestClose} variant="secondary" mr={2}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  isDisabled={(() => {
                    const selectedOption = dataTypeOptions.find(
                      (option) => option.value === values.field_type
                    );
                    const isChoiceOrMultiSelect =
                      selectedOption?.slug_name === "choice" ||
                      selectedOption?.slug_name === "multi_select";

                    const validOptions =
                      values.options?.filter(
                        (option) =>
                          typeof option?.value === "string" &&
                          option.value.trim() !== ""
                      ) || [];

                    const areOptionsInvalid =
                      isChoiceOrMultiSelect &&
                      (values.options.some((option) => option?.error) || // Check for errors
                        validOptions.length < 2);

                    return !dirty || (dirty && !isValid) || areOptionsInvalid;
                  })()}
                >
                  {question ? "Update" : "Create"}
                </Button>
              </Flex>
            </Form>
          </Box>
        )}
      </Formik>
    </Modal>
  );
};
