import styled from "styled-components";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { Card } from "../../components/Card";
import { H3, H4 } from "../../components/Heading";
import { Box } from "../../components/Box";
import resendInviteIcon from "../../assets/resend-invite-icon.svg";
import { Image } from "../../components/Image";
import { Paragraph } from "../../components/Paragraph";
import { useIndividual } from "../../data/useIndividual";
import { useMandate } from "../../data/useMandate";
import { capitalise } from "../../utils/string";
import { DateTime } from "luxon";
import { Loader } from "../../components/Loader";
import { Geolocation } from "../../components/Geolocation";
import Employment from "./Employment";
import { Slider } from "../../components/Slider";
import { useParams } from "react-router-dom";
import person from "../../assets/settings/person.svg";
import invite from "../../assets/invite.svg";
import address from "../../assets/address-icon.svg";
import financial from "../../assets/financials-icon.svg";
import formsIcon from "../../assets/forms-icon.svg";
import downloadIcon from "../../assets/download-arrow.svg";
import theme from "../../theme";
import { useState } from "react";
import { clearAll, notify } from "../../utils/notify";
import axios from "axios";
import { env } from "../../utils/env";
import { useAuth } from "../../context/AuthContext";
import { ExpansionPanel } from "../../components/ExpansionPanel";

export const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;

export const TitleHeading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[3]};
    word-break: break-all;
  `}
`;


const Container = styled.div`
  ${({ theme: { space } }) => `
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-bottom: ${space[5]}
  `}
`;
export const CardLine = ({ children, ...styleProps }: any) => (
    <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
        {children}
    </Paragraph>
);
export const CardLineDetail = ({ children, ...styleProps }: any) => (
    <Paragraph fontSize={2} color="gray.60" my={0} {...styleProps}>
        {children}
    </Paragraph>
);

export const IndividualDetailsView = ({
    isOpen,
    onRequestClose,
}) => {
    const params = useParams();
    const { authHeader } = useAuth();
    const { data: individualData, isLoading } = useIndividual(params.id);

    const individual = individualData?.data;
    const [isRequestingExcelDownload, setIsRequestingExcelDownload] = useState(false);

    const { data: mandateData, isLoading: isLoadingMandate } = useMandate(
        individual?.mandate
    );

    function scrollToElement(id) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }
    const handleDownloadExcelClick = () => {
        // if (isRequestingExcelDownload) {
        //     return;
        // }
        // setIsRequestingExcelDownload(true);
        // notify("Generating Excel, this could take up to 30 seconds", {
        //     position: "bottom-left",
        //     type: "info",
        //     pauseOnFocusLoss: false,
        //     pauseOnHover: false,
        //     autoClose: false,
        // });

        // axios
        //     .get(`${env.verifyServiceUrl}individual/download_pdf/${params.id}`, {
        //         headers: {
        //             Authorization: authHeader,
        //         },
        //         responseType: "arraybuffer",
        //     })
        //     .then((response) => {
        //         clearAll();
        //         const blob = new Blob([response.data], { type: "application/pdf" });
        //         const link = document.createElement("a");
        //         link.href = window.URL.createObjectURL(blob);

        //         const timestamp = DateTime.now().toFormat("yyyy-MM-dd_HH-mm-ss");

        //         link.download = `Customer Report - ${individual?.reference} - ${individual?.first_name
        //             } ${individual?.middle_name || ""} ${individual?.last_name
        //             } - ${timestamp}.pdf`;

        //         link.click();
        //     })
        //     .catch(() => {
        //         clearAll();
        //         setTimeout(() => {
        //             notify("There was a problem while generating the Excel report", {
        //                 position: "bottom-left",
        //                 type: "error",
        //             });
        //         }, 100);
        //     })
        //     .finally(() => {
        //         setIsRequestingExcelDownload(false);
        //     });
    }

    return (
        <Slider isOpen={isOpen} onRequestClose={onRequestClose} width="70%">
            <div>
                <Flex justifyContent="space-between" width="90%" m={2}>
                    <H3 mb={2}>About {individual?.first_name
                        ? individual?.first_name
                        : "-"} {individual?.middle_name
                            ? individual?.middle_name
                            : ""} {individual?.last_name
                                ? individual?.last_name
                                : ""}   </H3>

                    {/* <Button
                        onClick={handleDownloadExcelClick}
                        variant="secondary"
                        color={"#2D374B"}
                        mr={1}
                    >
                        <Image
                            src={downloadIcon}
                            alt="download excel"
                            height="auto"
                            width="22px"
                            mr={1}
                        />
                        <Paragraph fontWeight="500" fontSize={theme.fontSizes[1]}>
                            {isRequestingExcelDownload
                                ? "Downloading..."
                                : "Download Excel"}
                        </Paragraph>
                    </Button> */}
                </Flex>
                <hr></hr>
            </div>

            {isLoading && (
                <Flex justifyContent="center" alignItems="center" minHeight="60vh">
                    <Loader />
                </Flex>
            )}

            {!isLoading && (
                <>
                    <Container>
                        <Flex gap="24">
                            <Box width={"80%"}>
                                <Box>
                                    <Card>
                                        <Flex gap="24" id="invite">
                                            <Box
                                                flex={1}
                                                borderBottom="1px solid"
                                                borderColor="gray.20"
                                            >
                                                <TitleHeading mb={2}>Invite Details</TitleHeading>

                                                <Flex gap="24">
                                                    <Box flex={1}>
                                                        <CardLine>First name</CardLine>
                                                        <Heading>
                                                            {individual?.first_name
                                                                ? individual?.first_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Middle name</CardLine>
                                                        <Heading>
                                                            {individual?.middle_name
                                                                ? individual?.middle_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Last name</CardLine>
                                                        <Heading>
                                                            {individual?.last_name
                                                                ? individual?.last_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Title</CardLine>
                                                        <Heading>
                                                            {individual?.title ? individual?.title : "-"}
                                                        </Heading>
                                                    </Box>
                                                </Flex>
                                                <Heading mb={2} style={{ marginTop: "2%" }}>Birth Details</Heading>

                                                <Flex gap="24">
                                                    <Box flex={1}>
                                                        <CardLine>Date of birth</CardLine>
                                                        <Heading>
                                                            {individual?.date_of_birth
                                                                ? DateTime.fromISO(
                                                                    individual?.date_of_birth
                                                                ).toFormat("dd/MM/yyyy")
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Gender</CardLine>
                                                        <Heading>
                                                            {individual?.gender
                                                                ? capitalise(individual?.gender)
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}></Box>
                                                    <Box flex={1}></Box>

                                                </Flex>
                                            </Box>
                                        </Flex>
                                        <Flex gap="24" id="personal">
                                            <Box flex={1} paddingTop={2}>
                                                <TitleHeading mb={2}>Personal Details</TitleHeading>

                                                <Flex gap="24">
                                                    <Box flex={1}>
                                                        <CardLine>First name</CardLine>
                                                        <Heading>
                                                            {individual?.personal_detail?.first_name
                                                                ? individual?.personal_detail?.first_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Middle name</CardLine>
                                                        <Heading>
                                                            {individual?.personal_detail?.middle_name
                                                                ? individual?.personal_detail?.middle_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Last name</CardLine>
                                                        <Heading>
                                                            {individual?.personal_detail?.last_name
                                                                ? individual?.personal_detail?.last_name
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                    <Box flex={1}>
                                                        <CardLine>Gender</CardLine>
                                                        <Heading>
                                                            {individual?.personal_detail?.gender
                                                                ? capitalise(
                                                                    individual?.personal_detail?.gender
                                                                )
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                </Flex>
                                                <Flex gap="24">
                                                    <Box flex={1}>
                                                        <CardLine>Date of birth</CardLine>
                                                        <Heading>
                                                            {individual?.personal_detail?.date_of_birth
                                                                ? DateTime.fromISO(
                                                                    individual?.personal_detail?.date_of_birth
                                                                ).toFormat("dd/MM/yyyy")
                                                                : "-"}
                                                        </Heading>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Flex>

                                        <Flex gap="24">
                                            <Box flex={1} paddingTop={2}>
                                                <Heading mb={2}>Former Name</Heading>
                                                <Flex gap="24" fontSize={1}
                                                    color="gray.60"
                                                    fontFamily={"'Inter', sans-serif"}>
                                                    {individual?.former_name?.length
                                                        ? individual?.former_name?.map(
                                                            ({ id,
                                                                first_name,
                                                                last_name,
                                                                middle_name,
                                                                change_date }, i) => (
                                                                <Flex gap="24" key={i}>
                                                                    <Box flex={1} mb={2}>
                                                                        <CardLine>{first_name} {middle_name}{" "}
                                                                            {last_name}</CardLine>
                                                                        <Heading>
                                                                            {change_date && (
                                                                                <CardLine color="gray.40">
                                                                                    From{" "}
                                                                                    {change_date
                                                                                        ? DateTime.fromISO(
                                                                                            change_date
                                                                                        ).toFormat("dd/MM/yyyy")
                                                                                        : "-"}
                                                                                </CardLine>
                                                                            )}
                                                                        </Heading>
                                                                    </Box>
                                                                </Flex>
                                                            )
                                                        )
                                                        : <CardLine>No former names provided.</CardLine>}
                                                </Flex>
                                            </Box>
                                        </Flex>

                                        <Flex gap="24" mt={"2"}>
                                            {individual?.place_of_birth && (
                                                <Box flex={1.4}>
                                                    <Heading>Place of Birth</Heading>
                                                    {individual?.place_of_birth ? (
                                                        <Flex gap="24">
                                                            <Box flex={1}>
                                                                <CardLine mt={1}>
                                                                    Country
                                                                </CardLine>
                                                                <Heading>
                                                                    {individual?.place_of_birth?.country
                                                                        ? individual?.place_of_birth?.country
                                                                        : "-"}
                                                                </Heading>
                                                            </Box>
                                                            <Box flex={1}>
                                                                <CardLine mt={1}>City</CardLine>
                                                                <Heading>
                                                                    {individual?.place_of_birth?.city
                                                                        ? individual?.place_of_birth?.city
                                                                        : "-"}
                                                                </Heading>
                                                            </Box>
                                                            <Box flex={1}></Box>
                                                            <Box flex={1}></Box>
                                                        </Flex>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Box>
                                            )}
                                        </Flex>
                                        <Flex gap="24">
                                            <Box flex={1} paddingTop={2}>
                                                <Heading mb={2}>Nationality</Heading>
                                                <Flex gap="24" fontSize={1}
                                                    color="gray.60"
                                                    fontFamily={"'Inter', sans-serif"}>
                                                    {individual?.nationality?.length
                                                        ? individual?.nationality?.map(
                                                            ({ icon, nationality }, i) => (
                                                                <Box flex={1} key={i} mb={2}>
                                                                    {icon && (
                                                                        <Box
                                                                            backgroundColor={"gray.10"}
                                                                            borderRadius={"50%"}
                                                                            p={"1"}
                                                                        >
                                                                            <img
                                                                                src={`data:image/png;base64,${icon}`}
                                                                                alt="iamge"
                                                                            />
                                                                        </Box>
                                                                    )}
                                                                    {nationality && (
                                                                        <>
                                                                            &nbsp;
                                                                            {nationality}
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            )
                                                        )
                                                        : ''}


                                                </Flex>
                                            </Box>
                                        </Flex>

                                        <Flex gap="24" id="address">
                                            <Box
                                                flex={1}
                                            >
                                                {individual?.address && (
                                                    <Box mb={2}>
                                                        <hr></hr>

                                                        <TitleHeading>Address</TitleHeading>
                                                        {individual?.address ? (
                                                            <>
                                                                <Flex gap="24">
                                                                    <Box flex={1}>
                                                                        <CardLine>
                                                                            {individual?.address
                                                                                ?.flat_appartment_subbuilding && (
                                                                                    <span>
                                                                                        {
                                                                                            individual?.address
                                                                                                ?.flat_appartment_subbuilding
                                                                                        }
                                                                                        &nbsp;
                                                                                    </span>
                                                                                )}
                                                                            {individual?.address
                                                                                ?.building_number && (
                                                                                    <span>
                                                                                        {
                                                                                            individual?.address
                                                                                                ?.building_number
                                                                                        }
                                                                                        &nbsp;
                                                                                    </span>
                                                                                )}
                                                                            {individual?.address?.building_name && (
                                                                                <span>
                                                                                    {individual?.address?.building_name}
                                                                                    &nbsp;
                                                                                </span>
                                                                            )}
                                                                            {individual?.address?.road_street}
                                                                        </CardLine>
                                                                        <CardLine>
                                                                            {individual?.address?.town_city && (
                                                                                <span>
                                                                                    {individual?.address?.town_city}
                                                                                    &nbsp;
                                                                                </span>
                                                                            )}
                                                                            {individual?.address?.district}
                                                                        </CardLine>
                                                                        <CardLine>
                                                                            {individual?.address?.post_zip_code}
                                                                        </CardLine>
                                                                    </Box>
                                                                    <Box flex={1}>
                                                                        {individual?.address?.country_flag && (
                                                                            <Box
                                                                                p={"1"}
                                                                            >
                                                                                <img
                                                                                    src={`data:image/png;base64,${individual?.address?.country_flag}`}
                                                                                    alt="image"
                                                                                />
                                                                                <span>
                                                                                    &nbsp;
                                                                                    {individual?.address?.country_name}
                                                                                </span>
                                                                            </Box>
                                                                        )}

                                                                    </Box>
                                                                    <Box flex={1}>
                                                                        {individual?.address?.date_from && (
                                                                            <Box p={"1"}>
                                                                                From &nbsp;
                                                                                {individual?.address?.date_from}
                                                                                {individual?.address?.date_to && (
                                                                                    <>
                                                                                        &nbsp; to &nbsp; {individual?.address?.date_to}
                                                                                    </>
                                                                                )}
                                                                            </Box>
                                                                        )}
                                                                    </Box>
                                                                    <Box flex={1}></Box>
                                                                </Flex>
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Box>
                                                )}

                                                {individual?.previous_address && (
                                                    <Box mb={2}>
                                                        <Heading>Previous Addresses</Heading>
                                                        {individual?.previous_address &&
                                                            individual?.previous_address.length > 0 ? (
                                                            <>
                                                                {individual?.previous_address?.map(
                                                                    (prevAddress, i) => (
                                                                        <Box key={i} >
                                                                            <Heading style={{ fontSize: 14 }}>
                                                                                Previous Address {i + 1}
                                                                            </Heading>
                                                                            <Flex gap="24">
                                                                                <Box mb={2} flex={1}>
                                                                                    <CardLine>
                                                                                        {prevAddress?.flat_appartment_subbuilding && (
                                                                                            <span>
                                                                                                {
                                                                                                    prevAddress?.flat_appartment_subbuilding
                                                                                                }
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        )}
                                                                                        {prevAddress?.building_number && (
                                                                                            <span>
                                                                                                {
                                                                                                    prevAddress?.building_number
                                                                                                }
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        )}
                                                                                        {prevAddress?.building_name && (
                                                                                            <span>
                                                                                                {prevAddress?.building_name}
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        )}
                                                                                        {prevAddress?.road_street}
                                                                                    </CardLine>

                                                                                    <CardLine>
                                                                                        {prevAddress?.town_city && (
                                                                                            <span>
                                                                                                {prevAddress?.town_city}
                                                                                                &nbsp;
                                                                                            </span>
                                                                                        )}
                                                                                        {prevAddress?.district},{" "}
                                                                                        {prevAddress?.post_zip_code}
                                                                                    </CardLine>

                                                                                    <CardLine>
                                                                                        {DateTime.fromISO(
                                                                                            prevAddress?.date_from
                                                                                        ).toFormat("dd/MM/yyyy")}{" "}
                                                                                        -{" "}
                                                                                        {DateTime.fromISO(
                                                                                            prevAddress?.date_to
                                                                                        ).toFormat("dd/MM/yyyy")}
                                                                                    </CardLine>

                                                                                </Box>
                                                                                <Box flex={1}>
                                                                                    <Flex
                                                                                        gap="8"
                                                                                        alignItems={"center"}
                                                                                        mb={1}
                                                                                        fontSize={1}
                                                                                        color="gray.60"
                                                                                        fontFamily={
                                                                                            "'Inter', sans-serif"
                                                                                        }
                                                                                    >
                                                                                        {prevAddress?.country_flag && (
                                                                                            <Box
                                                                                                backgroundColor={"gray.10"}
                                                                                                borderRadius={"50%"}
                                                                                                p={"1"}
                                                                                            >
                                                                                                <img
                                                                                                    src={`data:image/png;base64,${prevAddress?.country_flag}`}
                                                                                                    alt="iamge"
                                                                                                />
                                                                                            </Box>
                                                                                        )}
                                                                                        {prevAddress?.country_flag && (
                                                                                            <span>
                                                                                                &nbsp;
                                                                                                {prevAddress?.country_name}
                                                                                            </span>
                                                                                        )}
                                                                                    </Flex>
                                                                                </Box>
                                                                                <Box flex={1}>
                                                                                    {individual?.previous_address && (
                                                                                        <Box p={"1"}>
                                                                                            From &nbsp;
                                                                                            {individual?.address?.date_from}
                                                                                            {individual?.address?.date_to && (
                                                                                                <>
                                                                                                    &nbsp; to &nbsp; {individual?.address?.date_to}
                                                                                                </>
                                                                                            )}
                                                                                        </Box>
                                                                                    )}
                                                                                </Box>
                                                                                <Box flex={1}>
                                                                                </Box>
                                                                            </Flex>
                                                                        </Box>
                                                                    )
                                                                )}
                                                            </>
                                                        ) : (
                                                            "-"
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Flex>
                                        <Flex gap="24" >
                                            {individual?.geo_location?.lat != null &&
                                                individual?.geo_location?.long != null &&
                                                individual?.geo_location
                                                    ?.location_service_permitted && (
                                                    <>
                                                        <Box flex={1}>
                                                            <Heading>Geolocation</Heading>
                                                            <Flex gap="24">
                                                                <Box flex={1}>
                                                                    <CardLine>Latitude</CardLine>
                                                                    <CardLine>
                                                                        {individual?.geo_location?.lat
                                                                            ? individual?.geo_location?.lat
                                                                            : "-"}
                                                                    </CardLine>
                                                                </Box>
                                                                <Box flex={1}>
                                                                    <CardLine>Longitude</CardLine>
                                                                    <CardLine>
                                                                        {individual?.geo_location?.long
                                                                            ? individual?.geo_location?.long
                                                                            : "-"}
                                                                    </CardLine>
                                                                </Box>
                                                                <Box flex={1}>
                                                                    <CardLine paddingLeft={"1"}>Country</CardLine>
                                                                    <Flex
                                                                        gap="8"
                                                                        alignItems={"center"}
                                                                        mb={1}
                                                                        pl={1}
                                                                        fontSize={1}
                                                                        color="gray.60"
                                                                        fontFamily={
                                                                            "'Inter', sans-serif"
                                                                        }
                                                                    >
                                                                        {individual?.geo_location?.country_flag ? (
                                                                            <Box
                                                                                backgroundColor={"gray.10"}
                                                                                borderRadius={"50%"}
                                                                                p={"1"}
                                                                            >
                                                                                <img
                                                                                    src={`data:image/png;base64,${individual?.geo_location?.country_flag}`}
                                                                                    alt="iamge"
                                                                                />
                                                                            </Box>
                                                                        )  : '-'}
                                                                        {individual?.geo_location?.country_flag && (
                                                                            <span>
                                                                                &nbsp;
                                                                                {individual?.geo_location?.country_name}
                                                                            </span>
                                                                        )}
                                                                    </Flex>
                                                                </Box>
                                                                <Box flex={1}></Box>
                                                            </Flex>

                                                            <br />
                                                            <Box height="170px" flex={1}>
                                                                <Geolocation
                                                                    lat={individual?.geo_location?.lat}
                                                                    long={individual?.geo_location?.long}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </>
                                                )}
                                        </Flex>
                                        <Flex gap="24" id="financials">
                                            {individual?.bank_account_detail && (
                                                <Box flex={1.4}>
                                                    <hr style={{ borderColor: "gray.20" }}></hr>
                                                    <TitleHeading>Financial Details</TitleHeading>
                                                    <Heading>Bank Account</Heading>
                                                    {individual?.bank_account_detail ? (
                                                        <Flex gap="24">
                                                            <Box flex={1}>
                                                                <CardLine mt={1}>
                                                                    Name on account
                                                                </CardLine>
                                                                <CardLine color="gray.40">
                                                                    {individual?.bank_account_detail
                                                                        ?.account_name && (
                                                                            <span>
                                                                                {
                                                                                    individual?.bank_account_detail
                                                                                        ?.account_name
                                                                                }
                                                                                &nbsp;
                                                                            </span>
                                                                        )}
                                                                </CardLine>
                                                            </Box>
                                                            <Box flex={1}>
                                                                <CardLine mt={1}>Sort code</CardLine>
                                                                <CardLine color="gray.40">
                                                                    {individual?.bank_account_detail
                                                                        ?.sort_code && (
                                                                            <span>
                                                                                {
                                                                                    individual?.bank_account_detail
                                                                                        ?.sort_code
                                                                                }
                                                                                &nbsp;
                                                                            </span>
                                                                        )}
                                                                </CardLine>
                                                            </Box>
                                                            <Box flex={1}>
                                                                <CardLine mt={1}>Account number</CardLine>
                                                                <CardLine color="gray.40">
                                                                    {individual?.bank_account_detail
                                                                        ?.account_number && (
                                                                            <span>
                                                                                {
                                                                                    individual?.bank_account_detail
                                                                                        ?.account_number
                                                                                }
                                                                                &nbsp;
                                                                            </span>
                                                                        )}
                                                                </CardLine>
                                                            </Box>

                                                        </Flex>
                                                    ) : (
                                                        "-"
                                                    )}
                                                </Box>
                                            )}
                                        </Flex>
                                        {/* Employment */}
                                        <Box my={2}>
                                            <Employment
                                                employmentDetails={individual.employment_detail}
                                            />
                                        </Box>
                                        <Flex gap="24" id="forms">
                                            <Box flex={1.4}>
                                                <TitleHeading>Forms</TitleHeading>
                                                {individual?.forms &&
                                                    individual?.forms.length > 0 ? (
                                                    <>
                                                        <hr style={{ borderColor: "gray.20" }}></hr>
                                                        {individual?.forms?.map((data, i) => (
                                                            <ExpansionPanel title={data?.name} key={i}>
                                                                <Flex key={i} gap="24">
                                                                    <Box flex={1}>
                                                                        <Heading
                                                                            style={{
                                                                                fontSize: 16,
                                                                                marginTop: "2%",
                                                                            }}
                                                                        >
                                                                            {data?.name}
                                                                        </Heading>
                                                                        <CardLine>
                                                                            <span>{data?.description}</span>
                                                                        </CardLine>
                                                                        {data?.sections?.map(
                                                                            (sectionData, j) => (
                                                                                <div key={j}>
                                                                                    <div>
                                                                                        <Heading
                                                                                            style={{
                                                                                                fontSize: 16,
                                                                                                marginTop: "2%",
                                                                                            }}
                                                                                        >
                                                                                            Section {j + 1} &nbsp;
                                                                                            {sectionData?.title}
                                                                                        </Heading>
                                                                                    </div>
                                                                                    {sectionData?.questions?.map(
                                                                                        (questions, k) => (
                                                                                            <Flex gap="24" key={k} mt={"2%"}>
                                                                                                <Box width={"30%"}>
                                                                                                    <Heading
                                                                                                        style={{
                                                                                                            fontSize: 14,
                                                                                                            marginTop: "2%",
                                                                                                        }}
                                                                                                    >
                                                                                                        {questions?.title}
                                                                                                    </Heading>

                                                                                                </Box>
                                                                                                <Box width={"60%"}>
                                                                                                    <CardLine>
                                                                                                        <span>
                                                                                                            {questions?.answer ? questions?.answer : "-"}
                                                                                                        </span>
                                                                                                    </CardLine>
                                                                                                </Box>
                                                                                            </Flex>
                                                                                        )
                                                                                    )}
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        <hr />
                                                                    </Box>
                                                                </Flex>
                                                            </ExpansionPanel>
                                                        ))}
                                                    </>
                                                ) : (
                                                    <CardLine fontFamily={"'Inter', sans-serif"}>This section is not included in your application process.</CardLine>
                                                )}
                                            </Box>
                                        </Flex>
                                    </Card>
                                </Box>
                            </Box>
                            <Box width={"20%"} backgroundColor={"#F1F4F8"}>
                                <Box position={"fixed"}>
                                    <Button
                                        marginBottom={3}
                                        mt={3}
                                        variant="text"
                                        paddingLeft={2}
                                        backgroundColor="none"
                                        border="none"
                                        color="black"
                                        fontWeight={600}
                                        onClick={() => scrollToElement('invite')}
                                    >
                                        <Image
                                            src={invite}
                                            alt="progress indicator"
                                            height="auto"
                                            width="22px"
                                            mr={1}
                                        />
                                        Invite
                                    </Button>
                                    {individual?.personal_detail && (
                                        <Button
                                            marginBottom={3}
                                            mt={3}
                                            variant="text"
                                            paddingLeft={2}
                                            backgroundColor="none"
                                            color="black"
                                            border="none"
                                            fontWeight={600}
                                            onClick={() => scrollToElement('personal')}
                                        >
                                            <Image
                                                src={person}
                                                alt="progress indicator"
                                                height="auto"
                                                width="22px"
                                                mr={1}
                                            />
                                            Personal
                                        </Button>
                                    )}
                                    {(individual?.address || individual?.previous_address) && (
                                        <Button
                                            marginBottom={3}
                                            mt={3}
                                            variant="text"
                                            paddingLeft={2}
                                            border="none"
                                            backgroundColor="none"
                                            color="black"
                                            fontWeight={600}
                                            onClick={() => scrollToElement('address')}
                                        >
                                            <Image
                                                src={address}
                                                alt="progress indicator"
                                                height="auto"
                                                width="22px"
                                                mr={1}
                                            />
                                            Address
                                        </Button>
                                    )}
                                    {individual?.bank_account_detail && (
                                        <Button
                                            marginBottom={3}
                                            mt={3}
                                            variant="text"
                                            paddingLeft={2}
                                            border="none"
                                            backgroundColor="none"
                                            color="black"
                                            fontWeight={600}
                                            onClick={() => scrollToElement('financials')}
                                        >
                                            <Image
                                                src={financial}
                                                alt="progress indicator"
                                                height="auto"
                                                width="22px"
                                                mr={1}
                                            />
                                            Financials
                                        </Button>
                                    )}

                                    {individual?.forms && (
                                        <Button
                                            marginBottom={3}
                                            mt={3}
                                            variant="text"
                                            paddingLeft={2}
                                            border="none"
                                            backgroundColor="none"
                                            color="black"
                                            fontWeight={600}
                                            onClick={() => scrollToElement('forms')}
                                        >
                                            <Image
                                                src={formsIcon}
                                                alt="progress indicator"
                                                height="auto"
                                                width="22px"
                                                mr={1}
                                            />
                                            Forms
                                        </Button>
                                    )}

                                </Box>

                            </Box>
                        </Flex>
                    </Container>
                </>
            )}
        </Slider>
    );
};
