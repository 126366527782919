import { Formik } from "formik";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddressAutocomplete from "../../components/AddressAutocomplete";
import { Box } from "../../components/Box";
import { Button } from "../../components/Button";
import { Flex } from "../../components/Flex";
import { H2, H3 } from "../../components/Heading";
import { Label } from "../../components/Label";
import { Paragraph } from "../../components/Paragraph";
import Select from "../../components/Select";
import { useAddAddress } from "../../data/useAddAddress";
import { useCountries } from "../../data/useCountries";
import { showError } from "../../utils/error-handling";
import { CheckAddress } from "../../types/CheckAddress";
import useAddressLongForm from "../../utils/forms/useAddressLongForm";
import { addressFormValidationSchema } from "../../utils/validationSchema";
import { NewCompanyChecksFrame } from "./NewCompanyChecksFrame";

const Header = () => (
  <>
    <H2>Registered address</H2>
    <Paragraph>
      Please confirm the company's registered office address. This is optional.
    </Paragraph>
  </>
);

export const AddCompanyAddress = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const mandateId = parseInt(params.id);
  const { data } = useCountries();
  const [selectedCountry, setSelectedCountry] = useState<Option | null>(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [showNoCoverageBox, setShowNoCoverageBox] = useState(null);
  const { mutate: addAddress, isLoading } = useAddAddress();
  const [formOpen, setFormOpen] = useState(false);
  const { AddressLongForm } = useAddressLongForm();

  const isSanctionsSelected = location.state?.isSanctionsSelected;

  const isAdverseMediaSelected = location.state?.isAdverseMediaSelected;

  type Option = {
    label: string;
    value: string | number;
    sac: boolean;
  };

  const countryOptions = data?.data.map(({ isoalpha2, name, sac }) => ({
    label: name,
    value: isoalpha2,
    sac: sac,
  })) as Option[];

  const handleNext = (values: CheckAddress) => {
    values.country_code = selectedCountry.value.toString();
    if (values) {
      addAddress(
        {
          id: parseInt(params.entityId),
          address: values,
        },
        {
          onSuccess: (res) => {
            navigate(
              `/checks/${mandateId}/company/run-checks/${res.data.individual}`,
              {
                state: {
                  isSanctionsSelected: isSanctionsSelected,
                  isAdverseMediaSelected: isAdverseMediaSelected,
                },
              }
            );
          },
          onError: (err: any) => {
            showError(
              err,
              "A problem occurred while creating individual address."
            );
          },
        }
      );
    }
  };

  const initialValues: CheckAddress = {
    building_number: "",
    flat_appartment_subbuilding: "",
    building_name: "",
    road_street: "",
    town_city: "",
    district: "",
    state_province_name: "",
    state_province_code: "",
    post_zip_code: "",
    country_code: "",
  };

  return (
    <NewCompanyChecksFrame step="address">
      <Header />
      <Box position={"relative"}>
        <Select
          value={selectedCountry}
          options={countryOptions}
          onChange={(option: Option) => {
            setSelectedCountry(option);

            if (!option.sac) {
              setShowNoCoverageBox(true);
            } else {
              setShowNoCoverageBox(false);
            }
          }}
          minWidth={200}
          width={600}
          flex={1}
          label="Country"
          name="country"
          isRequired
          hasError={Boolean(!selectedCountry)}
          errorMessage={selectedCountry ? "" : "Country is required"}
        />
{/* 
        {showNoCoverageBox && (
          <Box
            width={250}
            position={"fixed"}
            top={250}
            right={30}
            backgroundColor={"gray.100"}
            p={1}
            borderRadius={12}
          >
            <H3 fontSize={1}>No address coverage</H3>
            <Paragraph fontSize={0}>
              Our address verification service does not currently cover the
              country you have selected.
            </Paragraph>
          </Box>
        )} */}
      </Box>

      {/* !showNoCoverageBox && */}
      
        <Box width={600}>
          <Formik
            initialValues={initialValues}
            validationSchema={addressFormValidationSchema}
            onSubmit={handleNext}
          >
            {(formik) => (
              <>
                <form onSubmit={formik.handleSubmit}>
                {selectedCountry && (
                  <Box mt={2}>
                    <Flex
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Label>Address</Label>
                      <Button
                        variant="text"
                        type="button"
                        onClick={() => setFormOpen(true)}
                      >
                        Enter address manually
                      </Button>
                    </Flex>
                    <AddressAutocomplete
                      setSelectedAddress={(address) => {
                        setSelectedAddress(address);
                        setFormOpen(true);
                        Object.keys(address).forEach((key) => {
                          formik.setFieldValue(key, address[key]);
                        });
                      }}
                      country={selectedCountry.value}
                      setFormOpen={setFormOpen}
                    />
                  </Box>
                )}

                  {formOpen && <AddressLongForm formik={formik} />}

                  <Flex justifyContent="flex-end" my={3}>
                    <Button
                      type="button"
                      variant="text"
                      onClick={() =>
                        navigate(
                          `/checks/${mandateId}/company/run-checks/${params.entityId}`,
                          {
                            state: {
                              isSanctionsSelected:
                              isSanctionsSelected,
                              isAdverseMediaSelected: isAdverseMediaSelected,
                            },
                          }
                        )
                      }
                    >
                      Skip
                    </Button>
                    <Button
                      type="submit"
                      isDisabled={
                        isLoading ||
                        (!selectedAddress && !(formik.isValid && formik.dirty))
                      }
                    >
                      Next
                    </Button>
                  </Flex>
                </form>
              </>
            )}
          </Formik>
        </Box>
    </NewCompanyChecksFrame>
  );
};
