import { useQuery } from "@tanstack/react-query";
import { useAuth } from "../context/AuthContext";
import { axios } from "./axios";
import { MandateCheckList } from "../types/MandateCheckList";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";

const fetch = (authHeader: string, minimal: boolean, checks?: string[]) => () => {
  const checksQuery = checks?.length ? `name__in=${checks.join(",")}` : "";
  const url = `${env.verifyServiceUrl}individual/mandate_check/?${checksQuery}&minimal=${minimal}`;

  return axios.get<MandateCheckList[]>(url, {
    headers: {
      Authorization: authHeader,
    },
  });
};

export const useMandateCheckList = (minimal: boolean = false, checks?: string[]) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();

  return useQuery(
    ["mandate_check_list", minimal, checks],
    fetch(authHeader, minimal, checks),
    {
      retry: false,
      onError: (err: any) => {
        if (err.request.status === 500 || err.request.status === 503) {
          navigate(ROUTE.SERVER_ERROR);
        }
      },
    }
  );
};

