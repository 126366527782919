import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { NewChecksFrame } from "./NewChecksFrame";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { Box } from "../../components/Box";
import { Card } from "../../components/Card";
import { DateTime } from "luxon";
import { Flex } from "../../components/Flex";
import { useIndividual } from "../../data/useIndividual";
import { Image } from "../../components/Image";
import {
  address_verification_check,
  pep_sanctions_check,
  adverse_media_check,
} from "../../assets/checkpoints";
import { useRunChecks } from "../../data/useRunChecks";
import { capitalise } from "../../utils/string";
import { Modal } from "../../components/Modal";
import { ROUTE } from "../../routes";

const Header = () => {
  return (
    <>
      <H2>Run checks</H2>
      <Paragraph>
        The checks will be performed using the following information.
      </Paragraph>
    </>
  );
};

export const RunChecks = () => {
  const params = useParams();
  const navigate = useNavigate();
  const mandateId = parseInt(params.id);
  const location = useLocation();
  const { data, isLoading } = useIndividual(params.individualId);
  const [showSuccess, setShowSuccess] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);
  const showAddressStep = location.state?.showAddressStep ?? true;
  const showMonitoringStep = location.state?.showMonitoringStep ?? true;
  const pep_and_sanction_required = location.state?.pep_and_sanction_required ?? false;
  const adverse_media_required = location.state?.adverse_media_required ?? false;

  const CardLine = ({ children, ...styleProps }: any) => (
    <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
      {children}
    </Paragraph>
  );

  const checksIcons = {
    address_verification_check: address_verification_check,
    adverse_media_check: adverse_media_check,
    "pep_&_sanctions_check": pep_sanctions_check,
  };

  const { mutate: runChecks } = useRunChecks();

  const handleRunChecks = () => {
    setDoubleClick(true);
    runChecks(
      {
        id: parseInt(params.individualId),
      }
      // {
      //   onSuccess: () => {
      //     // setShowSuccess(true);
      //   },
      //   onError: (err: any) => {
      //     setDoubleClick(false);
      //     showError(
      //       err,
      //       "A problem occurred while creating data?.data address."
      //     );
      //   },
      // }
    );
    setShowSuccess(true);
  };

  const navigateToNewChecksFlow = () => {
    navigate(ROUTE.CHECKS_NEW);
    // navigate(`/checks/new`);
  };

  const navigateToChecks = () => {
    setShowSuccess(false);
    navigate(ROUTE.CHECKS);
    // navigate(`/checks`);
  };

  return (
    <NewChecksFrame step="runchecks" mandateId={mandateId} showAddressStep={showAddressStep} showMonitoringStep={showMonitoringStep}>
      <Header />
      {!isLoading && (
        <>
          <Box>
            <Card mb={2}>
              <H3 fontSize={2}>
                {data?.data?.first_name} {data?.data?.middle_name} {data?.data?.last_name}
              </H3>
              {data?.data?.date_of_birth && (
                <Paragraph color={"gray.60"} my={1}>
                  {DateTime.fromISO(data?.data?.date_of_birth).toFormat(
                    "dd/MM/yyyy"
                  )}
                </Paragraph>
              )}
              <Paragraph my={"0px"} color={"gray.60"}>
              {data?.data?.gender
                      ? capitalise(data?.data?.gender)
                      : "-"}
              </Paragraph>
            </Card>

            <Card mb={2}>
              <H3 fontSize={2}>Address</H3>
              {data?.data?.address ? (
                <>
                  <CardLine>
                    {data?.data?.address?.flat_appartment_subbuilding && (
                      <span>
                        {data?.data?.address?.flat_appartment_subbuilding}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.building_number && (
                      <span>
                        {data?.data?.address?.building_number}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.building_name && (
                      <span>
                        {data?.data?.address?.building_name}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.road_street}
                  </CardLine>
                  <CardLine>
                    {data?.data?.address?.town_city && (
                      <span>
                        {data?.data?.address?.town_city}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.district}
                  </CardLine>
                  <CardLine>{data?.data?.address?.post_zip_code}</CardLine>

                  <Flex
                    gap="8"
                    alignItems={"center"}
                    fontSize={1}
                    // color="gray.60"
                    fontFamily={"'Inter', sans-serif"}
                  >
                    {data?.data?.address?.country_flag && (
                      <Box
                        backgroundColor={"gray.10"}
                        borderRadius={"50%"}
                        p={"1"}
                      >
                        <img
                          src={`data:image/png;base64,${data?.data?.address?.country_flag}`}
                          alt="iamge"
                        />
                      </Box>
                    )}
                    {data?.data?.address?.country_flag && (
                      <span>
                        &nbsp;
                        {data?.data?.address?.country_name}
                      </span>
                    )}
                  </Flex>
                </>
              ) : (
                "-"
              )}
            </Card>

            <Card mb={2}>
              <H3 fontSize={2}>Checks</H3>
              {data?.data?.all_checks.map((check, i) => (
                <Flex gap="16" key={i}>
                  <Image
                    src={checksIcons[check.name]}
                    alt="checks"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    {check.title}
                  </Paragraph>
                </Flex>
              ))}
            </Card>

           {/* <Card mb={2}>
              <H3 fontSize={2}>Ongoing Monitoring</H3>
              {pep_and_sanction_required ?  (
                <Flex gap="16">
                  <Image
                    src={checksIcons['pep_&_sanctions_check']}
                    alt="checks"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    PEP & Sanctions
                  </Paragraph>
                </Flex> ) : ''}

                {adverse_media_required ?  (
                <Flex gap="16">
                  <Image
                    src={checksIcons['adverse_media_check']}
                    alt="checks"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    Adverse Media
                  </Paragraph>
                </Flex> ) : ''
                }
            </Card>   */}
          </Box>

          <Flex justifyContent={"flex-end"} mt={2}>
            <Button onClick={handleRunChecks} isDisabled={doubleClick}>
              Run
            </Button>
          </Flex>
        </>
      )}

      <Modal
        isOpen={showSuccess}
        onClose={navigateToChecks}
        title="Checks submitted"
        content="We are processing the checks on this individual. Create new checks for another individual or close to go to the checks history screen. "
        onModalConfirmClick={navigateToNewChecksFlow}
        secondaryButtonText="Close"
        primaryButtonText="+ New checks"
        primaryButtonVariant="primary"
        hasSecondaryButton
        onSecondaryButtonClick={navigateToChecks}
        width="450px"
      />
    </NewChecksFrame>
  );
};
