import { DateTime } from "luxon";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Flex } from "../components/Flex";
import { H2, H3 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { useIndividual } from "../data/useIndividual";
import { capitalise } from "../utils/string";
import { Box } from "../components/Box";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { NewMonitoringRecordFrame } from "./NewMonitoringRecord/NewMonitoringRecordFrame";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;

const CardLine = ({ children, ...styleProps }: any) => (
  <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
    {children}
  </Paragraph>
);
const Header = () => {
  return (
    <>
      <H2>Confirm Individuals Details</H2>
      <Paragraph>
        Please select set of details you would like to proceed.
      </Paragraph>
    </>
  );
};

export const ChecksActivateMonitoringIndividual = () => {
  const params = useParams();
  const navigate = useNavigate();

  const { data: individualData } = useIndividual(params.individualId);


  const individual = individualData?.data;

  return (
    <>
      <NewMonitoringRecordFrame step="individual">
        <Header />
        <Card>
          <Flex gap="12">
            <Box
              flex={4}
              borderColor="gray.20"
              width={"50%"}
            >
              <Heading mb={2}>Individuals Details</Heading>

              <Flex gap="24">
                <Box flex={1}>
                  <CardLine>First name</CardLine>
                  <Heading>
                    {individual?.first_name
                      ? individual?.first_name
                      : "-"}
                  </Heading>
                </Box>
                <Box flex={1}>
                  <CardLine>Middle name</CardLine>
                  <Heading>
                    {individual?.middle_name
                      ? individual?.middle_name
                      : "-"}
                  </Heading>
                </Box>
                <Box flex={1}>
                  <CardLine>Last name</CardLine>
                  <Heading>
                    {individual?.last_name
                      ? individual?.last_name
                      : "-"}
                  </Heading>
                </Box>
              </Flex>
              <Flex gap="24" marginTop={2}>
                <Box flex={1}>
                  <CardLine>Date of birth</CardLine>
                  <Heading>
                    {individual?.date_of_birth
                      ? DateTime.fromISO(
                        individual?.date_of_birth
                      ).toFormat("dd/MM/yyyy")
                      : "-"}
                  </Heading>
                </Box>
                <Box flex={1}>
                  <CardLine>Gender</CardLine>
                  <Heading>
                    {individual?.gender
                      ? capitalise(individual?.gender)
                      : "-"}
                  </Heading>
                </Box>
                <Box flex={1}>
                  <CardLine>Title</CardLine>
                  <Heading>
                    {individual?.title ? individual?.title : "-"}
                  </Heading>
                </Box>
              </Flex>
            </Box>
          </Flex>
        </Card>

        <Flex justifyContent="space-between">
            <Button
              size="large"
              mt="2"
              mb="0"
              variant="secondary"
              onClick={() =>
                navigate(`/checks/${individual.mandate}/individuals/${individual.id}`)
              }
            >
              Cancel
            </Button>
            <Button
              size="large"
              mt="2"
              mb="2"
              type="submit"
              onClick={() =>
                navigate(`/checks/${individual.mandate}/activate-monitoring/${individual.id}`)
              }
            >
              Next
            </Button>
        </Flex>
      </NewMonitoringRecordFrame>
    </>
  );
}