import { Flex } from "../../components/Flex";
import { Slider } from "../../components/Slider";
import { Image } from "../../components/Image";
import success from "../../assets/card-success-icon.svg";
import inReview from "../../assets/card-in-review-icon.svg";
import { useIndividualCheck } from "../../data/useIndividualCheck";
import { Box } from "../../components/Box";
import { H3 } from "../../components/Heading";
import { Loader } from "../../components/Loader";
import { TableBody } from "../../components/Table/TableBody";
import { TableCell } from "../../components/Table/TableCell";
import { TableHead } from "../../components/Table/TableHead";
import { TableRow } from "../../components/Table/TableRow";
import {
  IndividualCheckStatus,
  CheckStatusEnum,
} from "../../context/MandateStatusContext";
import {
  CheckContainer,
  CheckParagraph,
  CheckHeading,
  CheckTable,
  CheckTableHeaderRow,
  CheckTableCell,
  CheckTitle,
  Section,
} from "./CheckStyledComponents";
import { ManualAcceptReject } from "./ManualAcceptReject";
import { IndividualChecksMap } from "./helper";
import { CheckResults } from "./CheckResults";
import { CheckFields } from "./CheckFields";
import { GrayCard } from "../../components/Card";
import { Paragraph } from "../../components/Paragraph";
import { DateTime } from "luxon";
import { ApprovalComment } from "./approvalComment";
import { ExpansionPanel } from "../../components/ExpansionPanel";
import CustomTabs from "../../components/CustomTabs";

export const PepAndSanctionsCheck = (props) => {
  const { isOpen, onRequestClose, id, useSlider = true } = props;

  const { data: individualCheckData, isLoading: isLoadingIndividualCheck } =
    useIndividualCheck(id);

  const instance = individualCheckData?.data?.instance;

  const checkResultsContent = (
    <>
      {instance && Object.keys(instance).length !== 0 && (
        <Section>
          {instance?.pep_check && (
            <>
              <Flex justifyContent="space-between" mb={2}>
                <Box>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Image
                      src={
                        instance?.pep_check?.status ===
                          IndividualCheckStatus.Passed
                          ? success
                          : inReview
                      }
                      alt="status icon"
                      height="20px"
                      width="auto"
                      mr={2}
                      mb={0}
                    />
                    <H3>
                      PEP Check
                      {instance?.pep_check?.status !==
                        IndividualCheckStatus.Passed && (
                          <>
                            &nbsp;-&nbsp;
                            {instance?.pep_check?.status}
                          </>
                        )}
                    </H3>
                  </div>
                </Box>
                <Box></Box>
              </Flex>

              {instance?.pep_check?.status !== IndividualCheckStatus.Passed && (
                <>
                  <Box mb={2}>
                    <CheckHeading>Details:</CheckHeading>
                    <CheckTable>
                      <TableHead></TableHead>
                      <TableBody>
                        <CheckTableHeaderRow>
                          {/* <CheckTableCell>Result</CheckTableCell> */}
                          <CheckTableCell>Code</CheckTableCell>
                          <CheckTableCell>Matching Name</CheckTableCell>
                          <CheckTableCell style={{whiteSpace:"nowrap" }}>Date of Birth</CheckTableCell>
                        <CheckTableCell>Reason</CheckTableCell>
                      </CheckTableHeaderRow>
                      {instance?.pep_check?.details?.map(
                        ({ code, name, dob, reason }, i) => (
                          <TableRow key={i}>
                            {/* <TableCell
                        width="15%"
                        style={{ textTransform: "uppercase" }}
                        fontWeight="500"
                        color="gray.80"
                        borderBottom="none"
                      >
                        <Flex gap="8">                                    
                          {status}
                        </Flex>
                      </TableCell> */}
                            <TableCell
                              color="gray.60"
                              borderBottom="none"
                              verticalAlign="top"
                            >
                              {code || "-"}
                            </TableCell>
                            <TableCell
                              color="gray.60"
                              borderBottom="none"
                              verticalAlign="top"
                            >
                              {name || "-"}
                            </TableCell>
                            <TableCell
                              color="gray.60"
                              borderBottom="none"
                              verticalAlign="top"
                            >
                              {dob && dob.length > 0
                                ? dob.map((date, index) => (
                                  <Paragraph
                                    color="gray.60"
                                    mt={"0px"}
                                    // textAlign={"right"}
                                    mb={1}
                                    key={index}
                                  >
                                    {date.length > 4
                                      ? DateTime.fromISO(date).toFormat(
                                        "dd/MM/yyyy"
                                      )
                                      : date}
                                  </Paragraph>
                                ))
                                : "-"}
                            </TableCell>
                            <TableCell
                              color="gray.60"
                              borderBottom="none"
                              verticalAlign="top"
                            >
                              {reason || "-"}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </CheckTable>
                </Box>
            </>
          )}

          {instance?.pep_check?.status === IndividualCheckStatus.Passed && (
            <>
              <Flex alignItems="center" mb={2}>
                <CheckHeading>Result: &nbsp;</CheckHeading>
                <CheckParagraph>PASSED</CheckParagraph>
              </Flex>
              <GrayCard mx="0">
                <Paragraph fontWeight="600" m="0">
                  Supplied details did not match
                </Paragraph>
              </GrayCard>
            </>
          )}
        </>
      )}

      {instance &&
        instance?.pep_excluded_result &&
        instance?.pep_excluded_result?.details.length > 0 && (
          <ExpansionPanel title="Excluded results">
            <CheckTable>
              <TableHead>
                <CheckTableCell>Code</CheckTableCell>
                <CheckTableCell>Matching Name</CheckTableCell>
                <CheckTableCell>Date of Birth</CheckTableCell>
                <CheckTableCell>Reason</CheckTableCell>
                <CheckTableCell>Excluded reason</CheckTableCell>
              </TableHead>
              <TableBody>
                {instance?.pep_excluded_result?.details?.map(
                  (
                    {
                      code,
                      name,
                      dob,
                      reason,
                      excluded_reason,
                    },
                    i
                  ) => (
                    <TableRow key={i}>
                      {/* <TableCell
                        width="15%"
                        style={{ textTransform: "uppercase" }}
                        fontWeight="500"
                        color="gray.80"
                        borderBottom="none"
                      >
                        <Flex gap="8">{status}</Flex>
                      </TableCell> */}
                      <TableCell
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {code || "-"}
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {name || "-"}
                      </TableCell>
                      <TableCell
                        minWidth={120}
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {dob && dob.length > 0
                          ? dob.map((date, index) => (
                            <Paragraph
                              color="gray.60"
                              mt={"0px"}
                              // textAlign={"right"}
                              mb={1}
                              key={index}
                            >
                              {date.length > 4
                                ? DateTime.fromISO(date).toFormat(
                                  "dd/MM/yyyy"
                                )
                                : date}
                            </Paragraph>
                          ))
                          : "-"}
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {reason || "-"}
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {excluded_reason.join(", ")}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </CheckTable>
          </ExpansionPanel>
        )}
    </Section>
      )}

{
  instance && Object.keys(instance).length !== 0 && (
    <Section>
      {instance?.sanctions_check && (
        <>
          <Flex justifyContent="space-between" mb={2}>
            <Box>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Image
                  src={
                    instance?.sanctions_check?.status ===
                      IndividualCheckStatus.Passed
                      ? success
                      : inReview
                  }
                  alt="status icon"
                  height="20px"
                  width="auto"
                  mr={2}
                  mb={0}
                />
                <H3>
                  Sanctions Check
                  {instance?.sanctions_check?.status !==
                    IndividualCheckStatus.Passed && (
                      <>
                        &nbsp;-&nbsp;
                        {instance?.sanctions_check?.status}
                      </>
                    )}
                </H3>
              </div>
            </Box>
            <Box></Box>
          </Flex>

          {instance?.sanctions_check?.status !==
            IndividualCheckStatus.Passed &&
            instance?.sanctions_check?.details &&
            instance?.sanctions_check?.details.length > 0 && (
              <>
                <Box mb={2}>
                  <CheckHeading>Details:</CheckHeading>
                </Box>
                {instance?.sanctions_check?.details.map((detail, index) => (
                  <GrayCard key={index} mx="0px">
                    <H3
                      mb={2}
                      py={2}
                      borderBottom="1px solid"
                      borderBottomColor="gray.30"
                    >
                      {detail?.status}{" "}
                      {detail?.code == "WLT"
                        ? "- WatchList"
                        : detail?.code == "SAN"
                          ? "- Sanction"
                          : ""}
                    </H3>

                    <Flex justifyContent="space-between">
                      <Box flex={1}>
                        <CheckHeading color="gray.60" fontWeight="500">
                          Matching Name
                        </CheckHeading>
                        <Paragraph
                          mt={1}
                          mb={2}
                          color="gray.80"
                          fontWeight="500"
                        >
                          {detail?.matching_name
                            ? detail?.matching_name
                            : "-"}
                        </Paragraph>
                      </Box>

                      <Box flex={1}>
                        <CheckHeading color="gray.60" fontWeight="500">
                          Date of Birth
                        </CheckHeading>
                        <Paragraph
                          mb={2}
                          mt={1}
                          color="gray.80"
                          fontWeight="500"
                        >
                          {detail?.dob ? detail?.dob : "-"}
                        </Paragraph>
                      </Box>
                    </Flex>

                    <Flex justifyContent="space-between">
                      <Box flex={1}>
                        <CheckHeading color="gray.60" fontWeight="500">
                          Source Name
                        </CheckHeading>
                        <Paragraph
                          mb={2}
                          mt={1}
                          color="gray.80"
                          fontWeight="500"
                        >
                          {detail?.source_name ? detail?.source_name : "-"}
                        </Paragraph>
                      </Box>

                      <Box flex={1}>
                        <CheckHeading color="gray.60" fontWeight="500">
                          Date
                        </CheckHeading>
                        <Paragraph
                          mb={2}
                          mt={1}
                          color="gray.80"
                          fontWeight="500"
                        >
                          {detail?.date
                            ? DateTime.fromISO(detail?.date).toFormat(
                              "dd/MM/yyyy"
                            )
                            : "-"}
                        </Paragraph>
                      </Box>
                    </Flex>
                    <Box>
                      <CheckHeading color="gray.60" fontWeight="500">
                        Reason
                      </CheckHeading>
                      <Paragraph
                        mt={1}
                        mb={2}
                        color="gray.80"
                        fontWeight="500"
                      >
                        {detail?.reason ? detail?.reason : "-"}
                      </Paragraph>
                    </Box>
                  </GrayCard>
                ))}
              </>
            )}

          {instance?.sanctions_check?.status ===
            IndividualCheckStatus.Passed && (
              <>
                <Flex alignItems="center" mb={2}>
                  <CheckHeading>Result: &nbsp;</CheckHeading>
                  <CheckParagraph>PASSED</CheckParagraph>
                </Flex>
                <GrayCard mx="0">
                  <Paragraph fontWeight="600" m="0">
                    Supplied details did not match
                  </Paragraph>
                </GrayCard>
              </>
            )}
        </>
      )}

      {instance?.sanctions_excluded_result &&
        instance?.sanctions_excluded_result?.details.length > 0 && (
          <ExpansionPanel title="Excluded results">
            {instance?.sanctions_excluded_result?.details.map(
              (detail, index) => (
                <GrayCard key={index} mx="0px" backgroundColor={"pink.100"}>
                  <H3
                    mb={2}
                    py={2}
                    borderBottom="1px solid"
                    borderBottomColor="gray.30"
                  >
                    {detail?.status}{" "}
                    {detail?.code == "WLT"
                      ? "- WatchList"
                      : detail?.code == "SAN"
                        ? "- Sanction"
                        : ""}
                  </H3>

                  <Flex justifyContent="space-between">
                    <Box flex={1}>
                      <CheckHeading color="gray.60" fontWeight="500">
                        Matching Name
                      </CheckHeading>
                      <Paragraph
                        mt={1}
                        mb={2}
                        color="gray.80"
                        fontWeight="500"
                      >
                        {detail?.matching_name
                          ? detail?.matching_name
                          : "-"}
                      </Paragraph>
                    </Box>

                    <Box flex={1}>
                      <CheckHeading color="gray.60" fontWeight="500">
                        Date of Birth
                      </CheckHeading>
                      <Paragraph
                        mb={2}
                        mt={1}
                        color="gray.80"
                        fontWeight="500"
                      >
                        {detail?.dob ? detail?.dob : "-"}
                      </Paragraph>
                    </Box>
                  </Flex>

                  <Flex justifyContent="space-between">
                    <Box flex={1}>
                      <CheckHeading color="gray.60" fontWeight="500">
                        Source Name
                      </CheckHeading>
                      <Paragraph
                        mb={2}
                        mt={1}
                        color="gray.80"
                        fontWeight="500"
                      >
                        {detail?.source_name ? detail?.source_name : "-"}
                      </Paragraph>
                    </Box>

                    <Box flex={1}>
                      <CheckHeading color="gray.60" fontWeight="500">
                        Date
                      </CheckHeading>
                      <Paragraph
                        mb={2}
                        mt={1}
                        color="gray.80"
                        fontWeight="500"
                      >
                        {detail?.date
                          ? DateTime.fromISO(detail?.date).toFormat(
                            "dd/MM/yyyy"
                          )
                          : "-"}
                      </Paragraph>
                    </Box>
                  </Flex>
                  <Box>
                    <CheckHeading color="gray.60" fontWeight="500">
                      Reason
                    </CheckHeading>
                    <Paragraph
                      mt={1}
                      mb={2}
                      color="gray.80"
                      fontWeight="500"
                    >
                      {detail?.reason ? detail?.reason : "-"}
                    </Paragraph>
                  </Box>

                  <Box>
                    {detail?.excluded_reason &&
                      detail?.excluded_reason.length > 0 && (
                        <Box
                          borderTop={"1px solid"}
                          borderTopColor={"gray.100"}
                        >
                          <Paragraph fontWeight={"bold"} mb={3}>
                            Excluded reason
                          </Paragraph>

                          <Paragraph mb={1} fontWeight={"500"}>
                            {detail?.excluded_reason.join(", ")}
                          </Paragraph>
                        </Box>
                      )}
                  </Box>
                </GrayCard>
              )
            )}
          </ExpansionPanel>
        )}
    </Section>
  )
}
    </>
  );

const positionsContent = (
  <>
    <Section>
      <H3 mb={2} ml={1}>
        Positions
      </H3>
      {instance?.pep_check?.positions &&
        instance?.pep_check?.positions.length > 0 ? (
        <CheckTable>
          <TableHead></TableHead>
          <TableBody>
            <CheckTableHeaderRow>
              <CheckTableCell>Position</CheckTableCell>
              <CheckTableCell>Start</CheckTableCell>
              <CheckTableCell>End</CheckTableCell>
            </CheckTableHeaderRow>
            {instance?.pep_check?.positions?.map(
              ({ position, from_year, to_year }, i) => (
                <TableRow key={i}>
                  <TableCell
                    color="gray.60"
                    borderBottom="none"
                    verticalAlign="top"
                    width="60%"
                  >
                    {position || "-"}
                  </TableCell>
                  <TableCell
                    color="gray.60"
                    borderBottom="none"
                    verticalAlign="top"
                    width="20%"
                  >
                    {from_year || "-"}
                  </TableCell>

                  <TableCell
                    color="gray.60"
                    borderBottom="none"
                    verticalAlign="top"
                    width="20%"
                  >
                    {to_year || "-"}
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </CheckTable>
      ) : (
        <Paragraph>No positions found</Paragraph>
      )}
    </Section>
  </>
);
const relationsContent = (
  <>
    {instance && Object.keys(instance).length !== 0 && (
      <>
        <Section>
          <H3 mb={2} ml={1}>
            Relations
          </H3>
          {instance?.pep_check?.relations.length > 0 ? (
            <CheckTable>
              <TableHead></TableHead>
              <TableBody>
              <CheckTableHeaderRow>
                <CheckTableCell>Relation Type</CheckTableCell>
                <CheckTableCell>Name</CheckTableCell>
              </CheckTableHeaderRow>
                {instance?.pep_check?.relations.map(
                  ({ entity_name, relation_type }, i) => (
                    <TableRow key={i}>
                      <TableCell borderBottom="none" verticalAlign="top">
                        {relation_type || "-"}
                      </TableCell>
                      <TableCell
                        color="gray.60"
                        borderBottom="none"
                        verticalAlign="top"
                      >
                        {entity_name || "-"}
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </CheckTable>
          ) : (
            <Paragraph>No relations found</Paragraph>
          )}
        </Section>
      </>
    )}
  </>
);

const aliasesContent = (
  <>
    <Section>
      <H3 mb={2}>Aliases</H3>
      {instance?.pep_check?.aliases.length > 0 ? (
        <CheckTable>
          <TableHead></TableHead>
          <TableBody>
          <CheckTableHeaderRow>
            <CheckTableCell>Type</CheckTableCell>
            <CheckTableCell>Value</CheckTableCell>
          </CheckTableHeaderRow>
            {instance?.pep_check?.aliases.map(({ type, name }, i) => (
              <TableRow key={i}>
                <TableCell borderBottom="none" verticalAlign="top">
                  {type || "-"}
                </TableCell>
                <TableCell
                  color="gray.60"
                  borderBottom="none"
                  verticalAlign="top"
                >
                  {name || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </CheckTable>
      ) : (
        <Paragraph>No aliases found</Paragraph>
      )}
    </Section>
  </>
);

const content = (
  <CheckContainer>
    <Flex justifyContent="space-between">
      <CheckTitle>
        {IndividualChecksMap["pep_&_sanctions_check"].title}
      </CheckTitle>

      {individualCheckData?.data?.status?.status ===
        CheckStatusEnum.Review && (
          <ManualAcceptReject
            id={id}
            title={IndividualChecksMap["pep_&_sanctions_check"].title}
            isMonitoring={false}
          ></ManualAcceptReject>
        )}
    </Flex>
    {individualCheckData?.data?.status?.status && (
      <CheckResults
        status={individualCheckData?.data?.status?.status}
      ></CheckResults>
    )}

    {instance && instance?.approval_comment && (
      <ApprovalComment
        data={instance?.approval_comment}
        status={individualCheckData?.data?.status?.status}
      ></ApprovalComment>
    )}

    <CheckFields
      individual_checkfield_result={
        individualCheckData?.data?.individual_checkfield_result
      }
    ></CheckFields>

    <CustomTabs
      tabs={[
        { label: "Check results", content: checkResultsContent },
        { label: "Positions", content: positionsContent },
        {
          label: "Relations",
          content: relationsContent,
        },
        { label: "Aliases", content: aliasesContent },
      ]}
    />
  </CheckContainer>
);

return (
  <>
    {isLoadingIndividualCheck ? (
      <Flex justifyContent="center" alignItems="center" minHeight="60vh">
        <Loader />
      </Flex>
    ) : useSlider ? (
      <Slider
        isOpen={isOpen}
        onRequestClose={() => onRequestClose(false)}
        width="70%"
      >
        {content}
      </Slider>
    ) : (
      content
    )}
  </>
);
};
