import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { Box } from "../../components/Box";
import { Card } from "../../components/Card";
import { DateTime } from "luxon";
import { Flex } from "../../components/Flex";
import { Image } from "../../components/Image";
import {
  pep_sanctions_check,
  adverse_media_check,
} from "../../assets/checkpoints";
import { capitalise } from "../../utils/string";
import { Modal } from "../../components/Modal";
import { ROUTE } from "../../routes";
import { NewMonitoringRecordFrame } from "./NewMonitoringRecordFrame";
import { useCreateMonitoring } from "../../data/useCreateMonitoring";
import { showError } from "../../utils/error-handling";

const Header = () => {
  return (
    <>
      <H2>Monitoring</H2>
      <Paragraph>
        Please confirm the details for this monitoring record.
      </Paragraph>
    </>
  );
};

export const NewMonitoring = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [showSuccess, setShowSuccess] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);

  const formData = location.state?.form;

  const checksIcons = {
    adverse_media_check: adverse_media_check,
    "pep_&_sanctions_check": pep_sanctions_check,
  };

  const { mutate: createMonitoring } = useCreateMonitoring();

  const handleRunChecks = () => {
    setDoubleClick(true);
    const dob = formData.dateOfBirth
      ? DateTime.fromJSDate(formData.dateOfBirth as Date).toFormat("yyyy-MM-dd")
      : null;

    createMonitoring(
      {
        gender: formData.gender,
        title: formData.title,
        first_name: formData.firstName,
        date_of_birth: dob,
        last_name: formData.lastName,
        middle_name: formData.middleName,
        email: formData.email,
        individual: null,
        individual_reference: null,
        pep_and_sanction_required: formData.pep_and_sanction_required,
        adverse_media_required: formData.adverse_media_required,
      },
      {
        onSuccess: ({ data: individual }) => {
          setShowSuccess(true);
        },
        onError: (err: any) => {
          setDoubleClick(false);
          showError(err, "A problem occurred while creating the individual.");
        },
      }
    );
  };

  const navigateToNewChecksFlow = () => {
    navigate(ROUTE.MONITORING_NEW);
  };

  const navigateToMonitoring = () => {
    setShowSuccess(false);
    navigate(ROUTE.MONITORING);
  };

  return (
    <NewMonitoringRecordFrame step="monitoring">
      <Header />
      {formData && (
        <>
          <Box>
            <Card mb={2}>
              <H3 fontSize={2}>
                {formData?.title} {formData?.firstName} {formData?.middleName}{" "}
                {formData?.lastName}
              </H3>
              {formData?.dateOfBirth && (
                <Paragraph color={"gray.60"} my={1}>
                  {DateTime.fromJSDate(formData?.dateOfBirth).toFormat(
                    "dd/MM/yyyy"
                  )}
                </Paragraph>
              )}
              <Paragraph my={"0px"} color={"gray.60"}>
                {capitalise(formData.gender)}
              </Paragraph>
            </Card>

            <Card mb={2}>
              <H3 fontSize={2}>Monitoring events</H3>
              {formData?.pep_and_sanction_required && (
                <Flex gap="16">
                  <Image
                    src={checksIcons["pep_&_sanctions_check"]}
                    alt="pep_&_sanctions_check"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    PEP & Sanctions
                  </Paragraph>
                </Flex>
              )}
              {formData?.adverse_media_required && (
                <Flex gap="16">
                  <Image
                    src={checksIcons["adverse_media_check"]}
                    alt="adverse_media_check"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    Adverse Media
                  </Paragraph>
                </Flex>
              )}
            </Card>
          </Box>

          <Flex justifyContent={"flex-end"} mt={2}>
            <Button onClick={handleRunChecks} isDisabled={doubleClick}>
              Confirm
            </Button>
          </Flex>
        </>
      )}

      <Modal
        isOpen={showSuccess}
        onClose={navigateToMonitoring}
        title="Monitoring record is created successfully!"
        content="Thank you for creating a monitoring record for this individual. The details of the initial checks will be available very shortly. You can create another record now or go back to the dashboard to view the results."
        onModalConfirmClick={navigateToNewChecksFlow}
        secondaryButtonText="Close"
        primaryButtonText="+ New Monitoring Record"
        primaryButtonVariant="primary"
        hasSecondaryButton
        onSecondaryButtonClick={navigateToMonitoring}
        width="450px"
      />
    </NewMonitoringRecordFrame>
  );
};
