import { Field, Form, Formik } from "formik";
import styled from "styled-components";
import { H3 } from "../components/Heading";
import { Box } from "../components/Box";
import { Flex } from "../components/Flex";
import { Label } from "../components/Label";
import { Button } from "../components/Button";
import Select from "../components/Select";
import { Paragraph } from "../components/Paragraph";
import { Toggle } from "../components/Toggle";
import { useState } from "react";

//  const [isCopyDetails, setisCopyDetails] = useState(false);

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[3]};
    word-break: break-all;
  `}
`;

// const handleToggleClick = (value) => {
//     setisCopyDetails(value);
//   }

const rowProps: any = {
    flexWrap: "wrap",
    gap: "16",
    my: "4",
};

export const AddMonitoringChecks = ({
    firstName,
    lastName,
    middleName,
    pepOptions,
    adverse,
    pep,
    dob,
    gender,
    title,
    email,
    individualId,
    reviewOptions,
    customOptios,
    customNumberOptions,
    individualReference,
    handleNext
}: any) => {
    return (
        <div>
            <Box position={"relative"} marginTop={4}>
                <Heading> {firstName} {middleName} {lastName} </Heading>

                <Formik
                    initialValues={{
                        pep_and_sanction_required: pep,
                        adverse_media_required: adverse,
                        periodic_review: '',
                        periodic_review_every: '',
                        periodic_review_every_number: '',
                        firstName,
                        lastName,
                        middleName,
                        email,
                        dob,
                        gender,
                        title,
                        individualId,
                        individualReference
                    }}
                    validationSchema={''}
                    onSubmit={(values) => {

                        handleNext(values);
                    }}
                >
                    {(props) => (
                        <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
                            <Flex
                                {...rowProps}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Label>PEP & sanctions monitoring</Label>
                                {props.values.adverse_media_required}

                                <Field name="pep_and_sanction_required">
                                    {({ field, meta }) => (
                                        <Select
                                            {...field}
                                            onBlur={() => props.setFieldTouched(field.name)} // Trigger validation on blur
                                            onChange={(option: any) =>
                                                props.setFieldValue(
                                                    "pep_and_sanction_required",
                                                    option.value
                                                )
                                            }
                                            placeholder="Select value"
                                            name="pep_and_sanction_required"
                                            value={pepOptions?.find(
                                                ({ value
                                                }) =>
                                                    value === props.values.pep_and_sanction_required
                                            )}
                                            options={pepOptions}
                                            hasError={
                                                meta.touched &&
                                                (props.errors[field.name] || !!meta.error)
                                            }
                                            errorMessage={
                                                meta.touched
                                                    ? props.errors[field.name] || meta.error
                                                    : ""
                                            }
                                            width={"50%"}
                                            isRequired
                                        />
                                    )}
                                </Field>
                            </Flex>

                            <Flex
                                {...rowProps}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Label>Adverse media monitoring</Label>

                                <Field name="adverse_media_required">
                                    {({ field, meta }) => (
                                        <Select
                                            {...field}
                                            onBlur={() => props.setFieldTouched(field.name)}
                                            onChange={(option: any) =>
                                                props.setFieldValue(
                                                    "adverse_media_required",
                                                    option.value
                                                )
                                            }
                                            placeholder="Select value"
                                            name="adverse_media_required"
                                            value={pepOptions?.find(
                                                ({ value }) =>
                                                    value === props.values.adverse_media_required
                                            )}
                                            options={pepOptions}
                                            isRequired
                                            hasError={
                                                meta.touched &&
                                                (props.errors[field.name] || !!meta.error)
                                            }
                                            errorMessage={
                                                meta.touched
                                                    ? props.errors[field.name] || meta.error
                                                    : ""
                                            }
                                            width={"50%"}
                                        />
                                    )}
                                </Field>
                            </Flex>

                            <Flex
                                {...rowProps}
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Label>Periodic review</Label>

                                <Field name="periodic_review">
                                    {({ field, meta }) => (
                                        <Select
                                            {...field}
                                            onBlur={() => props.setFieldTouched(field.name)}
                                            onChange={(option: any) =>
                                                props.setFieldValue(
                                                    "periodic_review",
                                                    option.value
                                                )
                                            }
                                            placeholder="Select value"
                                            name="periodic_review"
                                            value={reviewOptions?.find(
                                                ({ value }) =>
                                                    value === props.values.periodic_review
                                            )}
                                            options={reviewOptions}
                                            isRequired
                                            hasError={
                                                meta.touched &&
                                                (props.errors[field.name] || !!meta.error)
                                            }
                                            errorMessage={
                                                meta.touched
                                                    ? props.errors[field.name] || meta.error
                                                    : ""
                                            }
                                            width={"50%"}
                                        />
                                    )}
                                </Field>
                            </Flex>

                            {props.values.periodic_review == "custom" && (
                                <Flex
                                    alignItems={"center"}
                                    justifyContent={"space-between"}
                                    width={"100%"}
                                >
                                    <Label style={{ width: "50%" }} >Periodic review every</Label>
                                    <Field name="periodic_review_every_number">
                                        {({ field, meta }) => (
                                            <span style={{ display: "flex", gap: "1rem", width: "50%" }}>
                                                <Select
                                                    {...field}
                                                    onBlur={() => props.setFieldTouched(field.name)}
                                                    onChange={(option: any) =>
                                                        props.setFieldValue(
                                                            "periodic_review_every_number",
                                                            option.value
                                                        )
                                                    }
                                                    placeholder="Select value"
                                                    name="periodic_review_every_number"
                                                    value={customNumberOptions?.find(
                                                        ({ value }) =>
                                                            value === props.values.periodic_review_every_number
                                                    )}
                                                    options={customNumberOptions}
                                                    isRequired
                                                    hasError={
                                                        meta.touched &&
                                                        (props.errors[field.name] || !!meta.error)
                                                    }
                                                    errorMessage={
                                                        meta.touched
                                                            ? props.errors[field.name] || meta.error
                                                            : ""
                                                    }
                                                    width={"45%"}
                                                />
                                                <Select
                                                    {...field}
                                                    onBlur={() => props.setFieldTouched(field.name)}
                                                    onChange={(option: any) =>
                                                        props.setFieldValue(
                                                            "periodic_review_every",
                                                            option.value
                                                        )
                                                    }
                                                    placeholder="Select value"
                                                    name="periodic_review_every"
                                                    value={customOptios?.find(
                                                        ({ value }) =>
                                                            value === props.values.periodic_review_every
                                                    )}
                                                    options={customOptios}
                                                    isRequired
                                                    hasError={
                                                        meta.touched &&
                                                        (props.errors[field.name] || !!meta.error)
                                                    }
                                                    errorMessage={
                                                        meta.touched
                                                            ? props.errors[field.name] || meta.error
                                                            : ""
                                                    }
                                                    width={"55%"}
                                                />
                                            </span>

                                        )}
                                    </Field>
                                </Flex>
                            )}
                             <Flex justifyContent="flex-end">
                                <Button
                                    size="large"
                                    mt="2"
                                    mb="2"
                                    type="submit"
                                    isDisabled={(props.dirty && !props.isValid)}
                                >
                                    Next
                                </Button>
                            </Flex>
                        </Form>
                    )}
                </Formik>
            </Box>
        </div>

    );
};
