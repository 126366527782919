import { useMutation } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";

interface Params {
  email: string;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  individual?: null;
  individual_reference?: null;
  pep_and_sanction_required: boolean;
  adverse_media_required: boolean;
  id_document_expiry?: boolean;
  periodic_review?: string;
  custom_periodic?:string;
}

export const useCreateMonitoringBulk = () => {
  const { authHeader } = useAuth();

  return useMutation((params: Params[]) => {
    return axios.post(`${env.verifyServiceUrl}individual/monitoring/bulk/`, {"monitoring": params}, {
      headers: {
        Authorization: authHeader,
      },
    });
  });
};
