import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import Applications from "./pages/Applications";
import ResetPassword from "./pages/ResetPassword";
import { ROUTE } from "./routes";
import "./App.css";
import AuthenticatedRoute from "./pages/AuthenticatedRoute";
import NotFound from "./pages/NotFound";
import ServiceUnavailablePage from "./pages/Error500Page";
import Settings from "./pages/Settings";
import "react-toastify/dist/ReactToastify.css";
import "./customToastify.css";
import SettingsCompanyDetails from "./pages/Settings/SettingsCompanyDetails";
import SettingsUsers from "./pages/Settings/SettingsUsers";
import SettingsApplicationTypeView from "./pages/Settings/SettingsApplicationTypeView";
import SettingsApplicationTypeNew from "./pages/Settings/SettingsApplicationTypeNew";
import SettingsApplicationTypes from "./pages/Settings/SettingsApplicationTypes";
import SettingsBillingDetails from "./pages/Settings/SettingsBillingDetails";
import { Profile } from "./pages/Profile";
import { NewApplication } from "./pages/NewApplication";
import { ApplicationView } from "./pages/ApplicationView";
import AccountPending from "./pages/AccountPending";
import ResetPasswordConfirmation from "./pages/ResetPasswordConfirmation";
import { IndividualView } from "./pages/IndividualView";
import { ApplicationIndividuals } from "./pages/ApplicationIndividuals";
import { AddApplicationIndividual } from "./pages/ApplicationIndividuals/AddApplicationIndividual";
import Logout from "./pages/Logout";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import CreatePassword from "./pages/CreatePassword";
import SettingsDPO from "./pages/Settings/SettingsDPO";
import SettingsPEPSanctions from "./pages/Settings/SettingsPEP&Sanctions";
import { HelpSupport } from "./pages/HelpSupport";
import SettingsBranding from "./pages/Settings/SettingsBranding";
import SettingsSOFRiskScoring from "./pages/Settings/SettingsSOFRiskScoring";
import Checks from "./pages/Checks";
import { AddCheckIndividual } from "./pages/ChecksIndividuals/AddCheckIndividual";
import { IndividualChecksView } from "./pages/IndividualChecksView";
import { RunChecks } from "./pages/ChecksIndividuals/RunChecks";
import { NewCheck } from "./pages/ChecksIndividuals/NewCheck";
import { AddAddress } from "./pages/ChecksIndividuals/AddAddress";
import SettingsAdditionalDocumentsRequests from "./pages/Settings/SettingsAdditionalDocumentsRequests";
import SettingsApplicationTypeNewChecks from "./pages/Settings/SettingsApplicationTypeNewChecks";
import SettingsApplicationTypeNewDocuments from "./pages/Settings/SettingsApplicationTypeNewDocuments";
import Monitoring from "./pages/Monitoring";
import { IndividualMonitoringView } from "./pages/IndividualMonitoringView";
import { NewIndividual } from "./pages/NewMonitoringRecord/NewIndividual";
import { NewMonitoring } from "./pages/NewMonitoringRecord/NewMonitoring";
import SettingsForms from "./pages/Settings/Forms/SettingsForms";
import SettingsNewForm from "./pages/Settings/Forms/SettingsNewForm";
import SettingsNewFormQuestions from "./pages/Settings/Forms/SettingsNewFormQuestions";
import SettingsFormView from "./pages/Settings/Forms/SettingsFormView";
import { SettingsFormFrame } from "./pages/Settings/Forms/SettingsFormFrame";
import { Box } from "./components/Box";
import SettingsApplicationTypeNewForms from "./pages/Settings/SettingsApplicationTypeNewForms";
import Login2FA from "./pages/Login2FA";
import RecoverAccount from "./pages/2FactorAuthentication/RecoverAccount";
import RecoveryCode from "./pages/2FactorAuthentication/RecoveryCode";
import RecoveryEmail from "./pages/2FactorAuthentication/RecoveryEmail";
import RecoveryEmailOTP from "./pages/2FactorAuthentication/RecoveryEmailOTP";
import LoginSetup2FA from "./pages/Login_Setup_2FA";
import { ChecksActivateMonitoring } from "./pages/ChecksActivateMonitorings";
import { ChecksActivateMonitoringIndividual } from "./pages/ChecksActivateMonitoringIndividual";
import { ApplicationsActivateMonitoringIndividual } from "./pages/ApplicationsActivateMonitoringIndividual";
import { ApplicationsActivateMonitoring } from "./pages/ApplicationsActivateMonitoring";
import { AddMonitoring } from "./pages/ChecksIndividuals/AddMonitoring";
import SettingsIncomeAndEmployment from "./pages/Settings/SettingsIncome&Employment";
import { NewCompanyCheck } from "./pages/ChecksIndividuals/NewCompanyCheck";
import { AddCompanyDetails } from "./pages/ChecksIndividuals/AddCompanyDetails";
import { AddCompanyAddress } from "./pages/ChecksIndividuals/AddCompanyAddress";
import { RunCompanyChecks } from "./pages/ChecksIndividuals/RunCompanyChecks";
import { CompanyChecksView } from "./pages/CompanyChecksView";

function App() {
  const isProduction =
    process.env.NODE_ENV === "production" ||
    process.env.NODE_ENV === "development" ||
    process.env.NODE_ENV === "test";
  return (
    <>
      <CacheBuster
        currentVersion={packageInfo.version}
        isEnabled={isProduction} //If false, the library is disabled.
      >
        <Routes>
          <Route path="/" element={<Navigate to={ROUTE.LOGIN} />} />
          <Route path={ROUTE.LOGIN} element={<Login />} />
          <Route path={ROUTE.LOGIN_2FA} element={<Login2FA />} />
          <Route path={ROUTE.SETUP2FA} element={<LoginSetup2FA />} />

          <Route path={ROUTE.RECOVER_ACCOUNT} element={<RecoverAccount />} />
          <Route path={ROUTE.USE_RECOVERY_CODE} element={<RecoveryCode />} />
          <Route path={ROUTE.USE_RECOVERY_EMAIL} element={<RecoveryEmail />} />
          <Route path={ROUTE.EMAIL_OTP} element={<RecoveryEmailOTP />} />

          <Route path={ROUTE.LOGOUT} element={<Logout />} />
          <Route path={ROUTE.SIGN_UP} element={<SignUp />}>
            <Route path={ROUTE.VERIFY} element={<SignUp />} />
            <Route path={ROUTE.SETUP_2FA} element={<SignUp />} />
            <Route path={ROUTE.ADD_COMPANY} element={<SignUp />} />
            <Route path={ROUTE.ADD_COMPANY_ADDRESS} element={<SignUp />} />
            <Route path={ROUTE.ADD_BILLING_INFORMATION} element={<SignUp />} />
            <Route path={ROUTE.ADD_BILLING_ADDRESS} element={<SignUp />} />
            <Route path={ROUTE.ADD_PRIMARY_PURPOSE} element={<SignUp />} />
            <Route path={ROUTE.ADD_DPO} element={<SignUp />} />
          </Route>
          <Route path={ROUTE.CREATE_PASSWORD} element={<CreatePassword />} />
          <Route path={ROUTE.RESET_PASSWORD} element={<ResetPassword />} />
          <Route
            path={ROUTE.RESET_PASSWORD_CONFIRMATION}
            element={<ResetPasswordConfirmation />}
          />
          <Route path={ROUTE.ACCOUNT_PENDING} element={<AccountPending />} />
          <Route
            path={ROUTE.INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <IndividualView />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATIONS}
            element={
              <AuthenticatedRoute>
                <Applications />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_NEW}
            element={
              <AuthenticatedRoute>
                <NewApplication />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.ACTIVATE_APPLICATIONS_INDIVIDUAL_MONITORING}
            element={
              <AuthenticatedRoute>
                <ApplicationsActivateMonitoringIndividual />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.ACTIVATE_APPLICATIONS_MONITORING}
            element={
              <AuthenticatedRoute>
                <ApplicationsActivateMonitoring />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECKS}
            element={
              <AuthenticatedRoute>
                <Checks />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.CHECKS_INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <IndividualChecksView />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.CHECKS_COMPANY}
            element={
              <AuthenticatedRoute>
                <CompanyChecksView />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECKS_NEW}
            element={
              <AuthenticatedRoute>
                <NewCheck />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECKS_NEW_COMPANY}
            element={
              <AuthenticatedRoute>
                <NewCompanyCheck />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECK_INDIVIDUALS_NEW}
            element={
              <AuthenticatedRoute>
                <AddCheckIndividual />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECK_COMPANY_NEW_DETAILS}
            element={
              <AuthenticatedRoute>
                <AddCompanyDetails />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.CHECK_ADDRESS_NEW}
            element={
              <AuthenticatedRoute>
                <AddAddress />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.CHECK_NEW_COMPANY_ADDRESS}
            element={
              <AuthenticatedRoute>
                <AddCompanyAddress />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.ACTIVATE_CHECKS_MONITORING_INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <ChecksActivateMonitoringIndividual />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.ACTIVATE_CHECKS_MONITORING}
            element={
              <AuthenticatedRoute>
                <ChecksActivateMonitoring />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.RUN_CHECKS}
            element={
              <AuthenticatedRoute>
                <RunChecks />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.RUN_COMPANY_CHECKS}
            element={
              <AuthenticatedRoute>
                <RunCompanyChecks />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.CHECK_MONITORING}
            element={
              <AuthenticatedRoute>
                <AddMonitoring />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.APPLICATION}
            element={
              <AuthenticatedRoute>
                <ApplicationView />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_INDIVIDUALS}
            element={
              <AuthenticatedRoute>
                <ApplicationIndividuals />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.APPLICATION_INDIVIDUALS_NEW}
            element={
              <AuthenticatedRoute>
                <AddApplicationIndividual />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.MONITORING}
            element={
              <AuthenticatedRoute>
                <Monitoring />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.MONITORING_INDIVIDUAL}
            element={
              <AuthenticatedRoute>
                <IndividualMonitoringView />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.MONITORING_NEW}
            element={
              <AuthenticatedRoute>
                <NewIndividual />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.MONITORING_NEW_CONFIRM}
            element={
              <AuthenticatedRoute>
                <NewMonitoring />
              </AuthenticatedRoute>
            }
          />

          <Route
            path={ROUTE.SETTINGS}
            element={
              <AuthenticatedRoute>
                <Settings />
              </AuthenticatedRoute>
            }
          >
            <Route index element={<SettingsCompanyDetails />} />
            <Route
              path={ROUTE.SETTINGS_BRANDING}
              element={<SettingsBranding />}
            />
            <Route
              path={ROUTE.SETTINGS_BILLING_DETAILS}
              element={<SettingsBillingDetails />}
            />
            <Route path={ROUTE.SETTINGS_USERS} element={<SettingsUsers />} />
            <Route
              path={ROUTE.SETTINGS_DPO_DETAILS}
              element={<SettingsDPO />}
            />

            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE}
              element={<SettingsApplicationTypeView />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE_NEW}
              element={<SettingsApplicationTypeNew />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE_NEW_CHECKS}
              element={<SettingsApplicationTypeNewChecks />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE_NEW_FORMS}
              element={<SettingsApplicationTypeNewForms />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPE_NEW_DOCUMENTS}
              element={<SettingsApplicationTypeNewDocuments />}
            />
            <Route
              path={ROUTE.SETTINGS_APPLICATION_TYPES}
              element={<SettingsApplicationTypes />}
            />
            <Route
              path={ROUTE.SETTINGS_SOF_RISK_SCORING}
              element={<SettingsSOFRiskScoring />}
            />
            <Route
              path={ROUTE.SETTINGS_ADDITIONAL_DOCUMENTS_REQUESTS}
              element={<SettingsAdditionalDocumentsRequests />}
            />
            <Route path={ROUTE.SETTINGS_FORMS} element={<SettingsForms />} />

            <Route
              path={ROUTE.SETTINGS_FORM_NEW}
              element={<SettingsNewForm />}
            />

            <Route
              path={ROUTE.SETTINGS_FORM_NEW_QUESTIONS}
              element={
                <SettingsFormFrame step="questions">
                  <Box pl={4} pr={2} flex={4} minWidth={400}>
                    <SettingsNewFormQuestions />
                  </Box>
                </SettingsFormFrame>
              }
            />

            <Route
              path={ROUTE.SETTINGS_FORM_VIEW}
              element={<SettingsFormView />}
            />

            <Route
              path={ROUTE.SETTINGS_PEP_SANCTION_SCREENING}
              element={<SettingsPEPSanctions />}
            />
            <Route
              path={ROUTE.SETTINGS_INCOME_AND_EMPLOYMENT}
              element={<SettingsIncomeAndEmployment />}
            />
          </Route>
          <Route
            path={ROUTE.PROFILE}
            element={
              <AuthenticatedRoute>
                <Profile />
              </AuthenticatedRoute>
            }
          />
          <Route
            path={ROUTE.HELP_AND_SUPPORT}
            element={
              <AuthenticatedRoute>
                <HelpSupport />
              </AuthenticatedRoute>
            }
          />
          {/* 👇︝ only match this when no other routes match */}
          <Route path={ROUTE.NOT_FOUND} element={<NotFound />} />
          <Route
            path={ROUTE.SERVER_ERROR}
            element={<ServiceUnavailablePage />}
          />
        </Routes>
      </CacheBuster>
    </>
  );
}

export default App;
