import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Box } from "../../components/Box";
import { showError } from "../../utils/error-handling";
import { Modal } from "../../components/Modal";
import { useIndividuals } from "../../data/useIndividuals";
import { NewApplicationFrame } from "./NewApplicationFrame";
import { Field, Form, Formik } from "formik";
import { Flex } from "../../components/Flex";
import { Label } from "../../components/Label";
import { Button } from "../../components/Button";
import Select from "../../components/Select";
import { useCreateMonitoringBulk } from "../../data/useCreateMonitoringBulk";
import { Toggle } from "../../components/Toggle";

const Header = () => {
  return (
    <>
      <H2>Monitoring</H2>
      <Paragraph>Please select the events you would like to monitor.</Paragraph>
    </>
  );
};
const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[3]};
    word-break: break-all;
  `}
`;
const pepOptions = [
  {
    label: "Yes",
    value: true,
  },
  {
    label: "No",
    value: false,
  },
];
const reviewOptions = [
  {
    label: "Never",
    value: "never",
  },
  {
    label: "Every 6 months",
    value: "6_month",
  },
  {
    label: "Every year",
    value: "1_year",
  },
  {
    label: "Every 3 years",
    value: "3_year",
  },
  {
    label: "Every 5 years",
    value: "5_year",
  },
  {
    label: "Custom",
    value: "custom",
  },
];
const customOptios = [
  {
    label: "Months",
    value: "month",
  },
  {
    label: "Years",
    value: "year",
  },
];
let customNumberOptions = [];
for (let i = 0; i < 11; i++) {
  var number = {
    label: i + 1,
    value: i + 1,
  };
  customNumberOptions.push(number);
}

const rowProps: any = {
  flexWrap: "wrap",
  gap: "16",
  my: "4",
};

export const ApplicationsMonitoring = ({
  mandateId,
  onClickNext,
  onClickBack,
}: {
  mandateId?: number;
  onClickNext: () => void;
  onClickBack: () => void;
}) => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { mutate: createMonitoringCheck } = useCreateMonitoringBulk();
  const { data: individuals } = useIndividuals(mandateId);
  const [isCopyDetails, setIsCopyDetails] = useState(false);
  const [disableToggle, setDisableToggle] = useState(true);

  const showMonitoringStep = location.state?.showMonitoringStep ?? false;
  const [showSuccess, setShowSuccess] = useState(false);
  const MandateTypeBubble = ({ title, content, children }: any) => (
    <Box borderRadius={0} bg="#e3e7ee" p={3} mb={2}>
      <Paragraph mt="0px" fontWeight="bold">
        {title}
      </Paragraph>
      {content ? <Paragraph mb="0px">{content}</Paragraph> : children}
    </Box>
  );
  const StyledUl = styled.ul`
    ${({ theme: { fontSizes, fontFamily } }) => `    
      font-family: ${fontFamily};
      font-size: ${fontSizes[2]};  
      padding-left: 12px;
      margin-bottom: 0px;
    `}
  `;
  const StyledLi = styled.li`
    ${({ theme: { space, colors } }) => `
        margin-left: ${space[2]};
        ::marker{
        color: ${colors.gray[40]};
        }
  `}
  `;

  const handleNextValue = (selectedData: any) => {
    var updatedindividuals = individuals?.data?.results.map((detail, index) => {
      const {
        first_name,
        last_name,
        middle_name,
        date_of_birth,
        reference,
        email,
        gender,
        title,
      } = detail;

      return {
        first_name,
        last_name,
        middle_name,
        date_of_birth,
        reference,
        email,
        gender,
        title,
        pep_and_sanction_required:
          selectedData.individuals[index].pep_and_sanction_required,
        adverse_media_required:
          selectedData.individuals[index].adverse_media_required,
        periodic_review: selectedData.individuals[index].periodic_review,
        custom_periodic: selectedData.individuals[index]
          .periodic_review_every_number
          ? selectedData.individuals[index].periodic_review_every_number +
            "_" +
            selectedData.individuals[index].periodic_review_every
          : "",
      };
    });

    createMonitoringCheck(
     updatedindividuals, {
      onSuccess: (res) => {
        onClickNext();
      },
      onError: (err: any) => {
        showError(err, "A problem occurred while creating the individual.");
      },
    });
  };

  const navigateToScreening = () => {
    setDisableToggle(true);
    setShowSuccess(false);
    navigate(`/checks/${params.mandate}/individuals/${params.individualId}`);
  };
  const handleToggleClick = (isChecked, props) => {
    setIsCopyDetails(isChecked);

    if (isChecked) {
      const firstIndividual = props.values.individuals[0];

      if (!firstIndividual) return;

      const updatedIndividuals = props.values.individuals.map(
        (individual, index) =>
          index === 0 ? individual : { ...individual, ...firstIndividual }
      );

      props.setFieldValue("individuals", updatedIndividuals);
      props.setFieldTouched("individuals", updatedIndividuals);
    }
  };

  return (
    <>
      <NewApplicationFrame
        step="monitoring"
        showMonitoringStep={showMonitoringStep}
      >
        <Header />

        <Formik
          initialValues={{
            individuals: individuals?.data?.results.map((detail) => ({
              pep_and_sanction_required: "",
              adverse_media_required: "",
              periodic_review: "",
              periodic_review_every: "",
              periodic_review_every_number: "",
            })),
          }}
          onSubmit={(selectedData) => handleNextValue(selectedData)}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} style={{ width: "510px" }}>
              {individuals?.data?.results?.map((individual, index) => (
                <div key={index}>
                  <Heading>
                    {" "}
                    {individual.first_name} {individual.middle_name}{" "}
                    {individual.last_name}{" "}
                  </Heading>


                  <Flex alignItems="center" justifyContent="space-between" {...rowProps}>
                    <Label>PEP & sanctions monitoring</Label>
                    <Field
                      name={`individuals.${index}.pep_and_sanction_required`}
                    >
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          onBlur={() => props.setFieldTouched(field.name)}
                          onChange={(option: { value: string }) =>
                          {
                            props.setFieldValue(
                              `individuals.${index}.pep_and_sanction_required`,
                              option.value
                            );
                            setDisableToggle(false);
                          }
                           
                          }
                          placeholder="Select value"
                          value={pepOptions?.find(
                            ({ value }) => value === field.value
                          )}
                          options={pepOptions}
                          hasError={meta.touched && meta.error}
                          errorMessage={meta.touched ? meta.error : ""}
                          width="50%"
                          isRequired
                        />
                      )}
                    </Field>
                  </Flex>

                  <Flex alignItems="center" justifyContent="space-between" {...rowProps}>
                    <Label>Adverse media monitoring</Label>
                    <Field name={`individuals.${index}.adverse_media_required`}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          onBlur={() => props.setFieldTouched(field.name)}
                          onChange={(option: { value: string }) =>
                          {
                            props.setFieldValue(
                              `individuals.${index}.adverse_media_required`,
                              option.value
                            );
                            setDisableToggle(false);
                          }
                          }
                          placeholder="Select value"
                          value={pepOptions?.find(
                            ({ value }) => value === field.value
                          )}
                          options={pepOptions}
                          isRequired
                          hasError={meta.touched && meta.error}
                          errorMessage={meta.touched ? meta.error : ""}
                          width="50%"
                        />
                      )}
                    </Field>
                  </Flex>

                  <Flex alignItems="center" justifyContent="space-between" {...rowProps}>
                    <Label>Periodic review</Label>
                    <Field name={`individuals.${index}.periodic_review`}>
                      {({ field, meta }) => (
                        <Select
                          {...field}
                          onBlur={() => props.setFieldTouched(field.name)}
                          onChange={(option: { value: string }) =>
                          {
                            props.setFieldValue(
                              `individuals.${index}.periodic_review`,
                              option.value
                            );
                            setDisableToggle(false);
                          }
                          }
                          placeholder="Select value"
                          value={reviewOptions?.find(
                            ({ value }) => value === field.value
                          )}
                          options={reviewOptions}
                          isRequired
                          hasError={meta.touched && meta.error}
                          errorMessage={meta.touched ? meta.error : ""}
                          width="50%"
                        />
                      )}
                    </Field>
                  </Flex>

                  {props.values.individuals[index].periodic_review ===
                    "custom" && (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      width="100%"
                    >
                      <Label style={{ width: "50%" }}>
                        Periodic review every
                      </Label>
                      <span
                        style={{ display: "flex", gap: "1rem", width: "50%" }}
                      >
                        <Field
                          name={`individuals.${index}.periodic_review_every_number`}
                        >
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              onBlur={() => props.setFieldTouched(field.name)}
                              onChange={(option: { value: string }) =>
                                props.setFieldValue(
                                  `individuals.${index}.periodic_review_every_number`,
                                  option.value
                                )
                              }
                              placeholder="Select value"
                              value={customNumberOptions?.find(
                                ({ value }) => value === field.value
                              )}
                              options={customNumberOptions}
                              isRequired
                              hasError={meta.touched && meta.error}
                              errorMessage={meta.touched ? meta.error : ""}
                              width="45%"
                            />
                          )}
                        </Field>
                        <Field
                          name={`individuals.${index}.periodic_review_every`}
                        >
                          {({ field, meta }) => (
                            <Select
                              {...field}
                              onBlur={() => props.setFieldTouched(field.name)}
                              onChange={(option: { value: string }) =>
                                props.setFieldValue(
                                  `individuals.${index}.periodic_review_every`,
                                  option.value
                                )
                              }
                              placeholder="Select value"
                              value={customOptios?.find(
                                ({ value }) => value === field.value
                              )}
                              options={customOptios}
                              isRequired
                              hasError={meta.touched && meta.error}
                              errorMessage={meta.touched ? meta.error : ""}
                              width="55%"
                            />
                          )}
                        </Field>
                      </span>
                    </Flex>
                  )}

                  {index === 0 && props.values.individuals.length > 1 && (
                    <Flex
                      flexDirection="row"
                      alignItems="center"
                      justifyContent={"space-between"}
                      marginBottom={2}
                    >
                      <Flex gap="12">
                        <Flex alignItems={"center"} gap="8">
                          <Paragraph m={"0px"} fontWeight={600}>
                            Copy settings for rest of individuals
                          </Paragraph>
                        </Flex>
                      </Flex>

                      <Toggle
                        mt={1}
                        isChecked={isCopyDetails}
                        isDisabled={disableToggle}
                        onChange={(e) =>
                          handleToggleClick(e.target.checked, props)
                        }
                      />
                    </Flex>
                  )}
                </div>
              ))}

              <Flex justifyContent="space-between">
                <Button size="large" mt="2" variant="secondary" onClick={() => onClickBack()}>
                  Back
                </Button>
                <Button size="large" mt="2" type="submit" isDisabled={disableToggle}>
                  Next
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>

        <Modal
          isOpen={showSuccess}
          onClose={navigateToScreening}
          title="Monitoring record is created successfully!"
          content="Thank you for creating a monitoring record for this individual.You can link an application now if you want to capture information from the customer such as an Identity Document."
          onModalConfirmClick={navigateToScreening}
          primaryButtonText="Close"
          primaryButtonVariant="primary"
          width="450px"
        />
      </NewApplicationFrame>

      <Box
        display={{
          _: "none",
          xl: "block",
        }}
        position="absolute"
        right={4}
        top="100px"
        width="325px"
      >
        <Paragraph fontWeight="bold">Monitoring Includes:</Paragraph>
        <MandateTypeBubble
          title="Description"
          content="A monitoring record will be created for this individual. You will recieve a notification for any new PEP & sanctions, adverse media, identity document expiry or period review events. "
        />
        <MandateTypeBubble title="Events">
          <StyledUl>
            <StyledLi>PEP & sanctions screening</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              A new potential PEP or sanctions result against the individual.
            </Paragraph>
            <StyledLi>Adverse media check</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              A new potential adverse media match against the individual.
            </Paragraph>
            <StyledLi>Periodic review</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              The individual reaches their expected review date.
            </Paragraph>
          </StyledUl>
        </MandateTypeBubble>
      </Box>
    </>
  );
};
