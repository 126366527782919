import { useQuery } from "@tanstack/react-query";
import { axios } from "./axios";
import { useAuth } from "../context/AuthContext";
import { env } from "../utils/env";
import { useNavigate } from "react-router-dom";
import { ROUTE } from "../routes";
import { EntityDetails } from "../types/Entity";

const fetch = (authHeader: string, entityId: number) => () =>
  axios.get<EntityDetails>(
    `${env.verifyServiceUrl}individual/entity/${entityId}/`,
    {
      headers: {
        Authorization: authHeader,
      },
    }
  );

export const useEntity = (id) => {
  const { authHeader } = useAuth();
  const navigate = useNavigate();
  return useQuery(["entity", id], fetch(authHeader, id), {
    retry: false,
    onError: (err: any) => {
      if (err.request.status === 500 || err.request.status === 503) {
        navigate(ROUTE.SERVER_ERROR);
      }
    },
  });
};
