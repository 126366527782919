import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { Button } from "../../components/Button";
import { Box } from "../../components/Box";
import { Card } from "../../components/Card";
import { DateTime } from "luxon";
import { Flex } from "../../components/Flex";
import { Image } from "../../components/Image";
import { adverse_media_check, sanctions_check } from "../../assets/checkpoints";
import { useRunChecks } from "../../data/useRunChecks";
import { Modal } from "../../components/Modal";
import { ROUTE } from "../../routes";
import { NewCompanyChecksFrame } from "./NewCompanyChecksFrame";
import { useEntity } from "../../data/useEntity";

const Header = () => {
  return (
    <>
      <H2>Run checks</H2>
      <Paragraph>
        The checks will be performed using the following information.
      </Paragraph>
    </>
  );
};

export const RunCompanyChecks = () => {
  const params = useParams();
  const navigate = useNavigate();
  const mandateId = parseInt(params.id);
  const { data, isLoading } = useEntity(params.entityId);
  const [showSuccess, setShowSuccess] = useState(false);
  const [doubleClick, setDoubleClick] = useState(false);

  const CardLine = ({ children, ...styleProps }: any) => (
    <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
      {children}
    </Paragraph>
  );

  const checksIcons = {
    adverse_media_check: adverse_media_check,
    sanctions_check: sanctions_check,
  };

  const { mutate: runChecks } = useRunChecks();

  const handleRunChecks = () => {
    setDoubleClick(true);
    runChecks(
      {
        id: parseInt(params.entityId),
      }
      // {
      //   onSuccess: () => {
      //     // setShowSuccess(true);
      //   },
      //   onError: (err: any) => {
      //     setDoubleClick(false);
      //     showError(
      //       err,
      //       "A problem occurred while creating data?.data address."
      //     );
      //   },
      // }
    );
    setShowSuccess(true);
  };

  const navigateToNewChecksFlow = () => {
    navigate(ROUTE.CHECKS_NEW_COMPANY);
  };

  const navigateToChecks = () => {
    setShowSuccess(false);
    navigate(ROUTE.CHECKS);
  };

  return (
    <NewCompanyChecksFrame step="runchecks" mandateId={mandateId}>
      <Header />
      {!isLoading && (
        <>
          <Box>
            <Card mb={2}>
              <H3 fontSize={2}>{data?.data?.company_name}</H3>
              <CardLine color={"gray.60"} my={1}>
                {data?.data?.entity_type?.name}
                </CardLine>
                <CardLine>
                {data?.data?.registeration_number} 
              </CardLine>
              {data?.data?.date_of_incorporation && (
                <CardLine color={"gray.60"} my={1}>
                  {DateTime.fromISO(data?.data?.date_of_incorporation).toFormat(
                    "dd/MM/yyyy"
                  )}
                </CardLine>
              )}
                <Flex gap="8" alignItems={"center"} fontSize={1} fontFamily={"'Inter', sans-serif"}>
                  {data?.data?.country?.icon && (
                    <Box
                      backgroundColor={"gray.10"}
                      borderRadius={"50%"}
                      p={"1"}
                    >
                      <img
                        src={`data:image/png;base64,${data?.data?.country?.icon}`}
                        alt="iamge"
                      />
                    </Box>
                  )}
                  {data?.data?.country?.name}
                </Flex>
            </Card>

            <Card mb={2}>
              <H3 fontSize={2}>Registered Address</H3>
              {data?.data?.address ? (
                <>
                  <CardLine>
                    {data?.data?.address?.flat_appartment_subbuilding && (
                      <span>
                        {data?.data?.address?.flat_appartment_subbuilding}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.building_number && (
                      <span>
                        {data?.data?.address?.building_number}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.building_name && (
                      <span>
                        {data?.data?.address?.building_name}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.road_street}
                  </CardLine>
                  <CardLine>
                    {data?.data?.address?.town_city && (
                      <span>
                        {data?.data?.address?.town_city}
                        &nbsp;
                      </span>
                    )}
                    {data?.data?.address?.district}
                  </CardLine>
                  <CardLine>{data?.data?.address?.post_zip_code}</CardLine>

                  <Flex
                    gap="8"
                    alignItems={"center"}
                    fontSize={1}
                    // color="gray.60"
                    fontFamily={"'Inter', sans-serif"}
                  >
                    {data?.data?.address?.country_flag && (
                      <Box
                        backgroundColor={"gray.10"}
                        borderRadius={"50%"}
                        p={"1"}
                      >
                        <img
                          src={`data:image/png;base64,${data?.data?.address?.country_flag}`}
                          alt="iamge"
                        />
                      </Box>
                    )}
                    {data?.data?.address?.country_flag && (
                      <span>
                        &nbsp;
                        {data?.data?.address?.country_name}
                      </span>
                    )}
                  </Flex>
                </>
              ) : (
                "-"
              )}
            </Card>

            <Card mb={2}>
              <H3 fontSize={2}>Checks</H3>
              {data?.data?.all_checks.map((check, i) => (
                <Flex gap="16" key={i}>
                  <Image
                    src={checksIcons[check.name]}
                    alt="checks"
                    height="auto"
                    mr={1}
                  />
                  <Paragraph my={1} fontSize={1} color="gray.60">
                    {check.title}
                  </Paragraph>
                </Flex>
              ))}
            </Card>
          </Box>

          <Flex justifyContent={"flex-end"} mt={2}>
            <Button onClick={handleRunChecks} isDisabled={doubleClick}>
              Run
            </Button>
          </Flex>
        </>
      )}

      <Modal
        isOpen={showSuccess}
        onClose={navigateToChecks}
        title="Checks submitted"
        content="We are processing the checks on this company. Create new checks for another company or close to go to the checks history screen."
        onModalConfirmClick={navigateToNewChecksFlow}
        secondaryButtonText="Close"
        primaryButtonText="+ New checks"
        primaryButtonVariant="primary"
        hasSecondaryButton
        onSecondaryButtonClick={navigateToChecks}
        width="450px"
      />
    </NewCompanyChecksFrame>
  );
};
