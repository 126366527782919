import { useLocation, useNavigate, useParams } from "react-router-dom";
import { H2, H3 } from "../../components/Heading";
import { Paragraph } from "../../components/Paragraph";
import { showError } from "../../utils/error-handling";
import { NewChecksFrame } from "./NewChecksFrame";
import { Individual } from "../../types/Individual";
import { useIndividuals } from "../../data/useIndividuals";
import styled from "styled-components";
import { useCreateMonitoring } from "../../data/useCreateMonitoring";
import { Box } from "../../components/Box";
import { AddMonitoringChecks } from "../AddMonitoringChecks";

const Header = () => (
  <>
    <H2>Monitoring Activities</H2>
    <Paragraph>
      Please select the activities you would like to monitor.
    </Paragraph>
  </>
);

export const AddMonitoring = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();
  const mandateId = parseInt(params.id);
  const { data: individuals } = useIndividuals(
    mandateId
  );
  const showAddressStep = location.state?.showAddressStep ?? true;
  const showMonitoringStep = location.state?.showMonitoringStep ?? false;
  const isAdverseMediaSelected = location.state?.isAdverseMediaSelected ?? false;
  const isPepAndSanctionsSelected = location.state?.isPepAndSanctionsSelected ?? false;
  const { mutate: createMonitoringCheck } = useCreateMonitoring();
  const pepOptions = [
    {
      label: "Yes",
      value: true,
    },
    {
      label: "No",
      value: false,
    },
  ];
  const reviewOptions = [
    {
        label: "Never",
        value: "never",
    },
    {
        label: "Every 6 months",
        value: "6_month",
    },
    {
        label: "Every year",
        value: "1_year",
    },
    {
        label: "Every 3 years",
        value: "3_year",
    },
    {
        label: "Every 5 years",
        value: "5_year",
    },
    {
        label: "Custom",
        value: "custom",
    },
];
const customOptios = [
    {
        label: "Months",
        value: "month",
    },
    {
        label: "Years",
        value: "year",
    },
];
let customNumberOptions = [];
for (let i = 0; i < 11; i++) {
  var number = {
    label: i+1, value: i+1
  }
  customNumberOptions.push(number);
}
  const MandateTypeBubble = ({ title, content, children }: any) => (
    <Box borderRadius={0} bg="#e3e7ee" p={3} mb={2}>
      <Paragraph mt="0px" fontWeight="bold">
        {title}
      </Paragraph>
      {content ? <Paragraph mb="0px">{content}</Paragraph> : children}
    </Box>
  );
  const StyledUl = styled.ul`
  ${({ theme: { fontSizes, fontFamily } }) => `     
      font-family: ${fontFamily};
      font-size: ${fontSizes[2]};   
      padding-left: 12px; 
      margin-bottom: 0px;
    `}
`;
  const StyledLi = styled.li`
  ${({ theme: { space, colors } }) => `
        margin-left: ${space[2]};

        ::marker{
        color: ${colors.gray[40]};
        }
  `}
`;

  const handleNextValue = (values: any) => {
    createMonitoringCheck(
      {
        gender: values.gender,
        title: values.title,
        first_name: values.firstName,
        date_of_birth: values.dob,
        last_name: values.lastName,
        middle_name: values.middleName,
        email: values.email,
        individual: values.id,
        pep_and_sanction_required: values.pep_and_sanction_required,
        adverse_media_required: values.adverse_media_required,
        periodic_review: values.periodic_review,
        custom_periodic: values.periodic_review_every_number? values.periodic_review_every_number +'_'+ values.periodic_review_every: '',
      },
      {
        onSuccess: (res) => {
          navigate(`/checks/${mandateId}/run-checks/${values.individualId}`,
            {
              state: {
                showAddressStep: showAddressStep,
                showMonitoringStep: showMonitoringStep,
                pep_and_sanction_required: res.data.pep_and_sanction_required,
                adverse_media_required: res.data.adverse_media_required,
              },
            }
          )
        },
        onError: (err: any) => {
          showError(err, "A problem occurred while creating the individual.");
        },
      }
    );
  };

  return (
    <NewChecksFrame step="monitoring" mandateId={mandateId} showAddressStep={showAddressStep} showMonitoringStep={showMonitoringStep}>
      <Header />
      {individuals?.data?.results.map(
        (
          {
            first_name,
            middle_name,
            last_name,
            id,
            date_of_birth,
            gender,
            title,
            email,
            reference
          }: Individual,
        ) => (
          <AddMonitoringChecks
            key={id}
            individualId={id}
            firstName={first_name}
            middleName={middle_name}
            lastName={last_name}
            dob={date_of_birth}
            gender={gender}
            title={title}
            email={email}
            pepOptions={pepOptions}
            reviewOptions={reviewOptions}
            customOptios={customOptios}
            customNumberOptions={customNumberOptions}
            individualReference={reference}
            handleNext={handleNextValue}>
          </AddMonitoringChecks>
        )
      )}

      <Box
        display={{
          _: "none",
          xl: "block",
        }}
        position="absolute"
        right={4}
        top="205px"
        width="325px"
      >
        <Paragraph fontWeight="bold">Monitoring</Paragraph>
        <MandateTypeBubble
          title="Description"
          content="A monitoring record will be created for this individual. You will recieve a notification for any new PEP & sanctions, adverse media, identity document expiry or period review events. "
        />
        <MandateTypeBubble title="Events">
          <StyledUl>
            <StyledLi>PEP & sanctions screening</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              A new potential PEP or sanctions result against the individual.
            </Paragraph>
            <StyledLi>Adverse media check</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              A new potential adverse media match against the individual.
            </Paragraph>
            <StyledLi>Periodic review</StyledLi>
            <Paragraph my={0} fontSize={1} color={"gray.60"}>
              The individual reaches their expected review date.
            </Paragraph>
          </StyledUl>
        </MandateTypeBubble>
      </Box>
    </NewChecksFrame>
  )
}
