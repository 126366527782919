import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { H2 } from "../components/Heading";
import { Paragraph } from "../components/Paragraph";
import { Box } from "../components/Box";
import { showError } from "../utils/error-handling";
import { Modal } from "../components/Modal";
import { NewMonitoringRecordFrame } from "./NewMonitoringRecord/NewMonitoringRecordFrame";
import { useCreateMonitoring } from "../data/useCreateMonitoring";
import { AddMonitoringChecks } from "./AddMonitoringChecks";
import { useIndividual } from "../data/useIndividual";

const Header = () => {
    return (
        <>
            <H2>Monitoring</H2>
            <Paragraph>
                Please select the events you would like to monitor.
            </Paragraph>
        </>
    );
};
const pepOptions = [
    {
        label: "Yes",
        value: true,
    },
    {
        label: "No",
        value: false,
    },
];
const reviewOptions = [
    {
        label: "Never",
        value: "never",
    },
    {
        label: "Every 6 months",
        value: "6_month",
    },
    {
        label: "Every year",
        value: "1_year",
    },
    {
        label: "Every 3 years",
        value: "3_year",
    },
    {
        label: "Every 5 years",
        value: "5_year",
    },
    {
        label: "Custom",
        value: "custom",
    },
];
const customOptios = [
    {
        label: "Months",
        value: "month",
    },
    {
        label: "Years",
        value: "year",
    },
];
let customNumberOptions = [];
for (let i = 0; i < 11; i++) {
  var number = {
    label: i+1, value: i+1
  }
  customNumberOptions.push(number);
}

export const ApplicationsActivateMonitoring = () => {
    const params = useParams();
    const navigate = useNavigate();
    const individualId = parseInt(params.id);
    const { mutate: createMonitoringCheck } = useCreateMonitoring();

    const { data: individualData } = useIndividual(
        individualId
    );
    const individual = individualData?.data;
    const [showSuccess, setShowSuccess] = useState(false);
    const MandateTypeBubble = ({ title, content, children }: any) => (
        <Box borderRadius={0} bg="#e3e7ee" p={3} mb={2}>
            <Paragraph mt="0px" fontWeight="bold">
                {title}
            </Paragraph>
            {content ? <Paragraph mb="0px">{content}</Paragraph> : children}
        </Box>
    );
    const StyledUl = styled.ul`
  ${({ theme: { fontSizes, fontFamily } }) => `     
      font-family: ${fontFamily};
      font-size: ${fontSizes[2]};   
      padding-left: 12px; 
      margin-bottom: 0px;
    `}
`;
    const StyledLi = styled.li`
  ${({ theme: { space, colors } }) => `
        margin-left: ${space[2]};

        ::marker{
        color: ${colors.gray[40]};
        }
  `}
`;

    const handleNextValue = (values: any) => {
        createMonitoringCheck(
            {
                gender: values.gender,
                title: values.title,
                first_name: values.firstName,
                date_of_birth: values.dob,
                last_name: values.lastName,
                middle_name: values.middleName,
                email: values.email,
                individual_reference: values.individualReference,
                pep_and_sanction_required: values.pep_and_sanction_required,
                adverse_media_required: values.adverse_media_required,
                periodic_review: values.periodic_review,
                custom_periodic: values.periodic_review_every_number? values.periodic_review_every_number +'_'+ values.periodic_review_every: '',
            },
            {
                onSuccess: (res) => {
                    setShowSuccess(true);
                },
                onError: (err: any) => {
                    showError(err, "A problem occurred while creating the individual.");
                },
            }
        );
    };

    const navigateToScreening = () => {
        setShowSuccess(false);
        navigate(`/applications/${params.mandate}/individuals/${params.id}`);
      };


    return (
        <>
            <NewMonitoringRecordFrame step="monitoring">
                <Header />
               
                        <AddMonitoringChecks
                            key={individual?.id}
                            individualId={individual?.id}
                            firstName={individual?.personal_detail?.first_name}
                            middleName={individual?.personal_detail?.middle_name}
                            lastName={individual?.personal_detail?.last_name}
                            dob={individual?.personal_detail?.date_of_birth}
                            gender={individual?.personal_detail?.gender}
                            title={individual?.personal_detail?.title}
                            email={individual?.email}
                            pepOptions={pepOptions}
                            reviewOptions={reviewOptions}
                            customOptios={customOptios}
                            customNumberOptions={customNumberOptions}
                            individualReference={individual?.reference}
                            handleNext={handleNextValue}>
                        </AddMonitoringChecks>
                <Box
                    display={{
                        _: "none",
                        xl: "block",
                    }}
                    position="absolute"
                    right={4}
                    top="100px"
                    width="325px"
                >
                    <Paragraph fontWeight="bold">Monitoring Includes:</Paragraph>
                    <MandateTypeBubble
                        title="Description"
                        content="A monitoring record will be created for this individual. You will recieve a notification for any new PEP & sanctions, adverse media, identity document expiry or period review events. "
                    />
                    <MandateTypeBubble title="Events">
                        <StyledUl>
                            <StyledLi>PEP & sanctions screening</StyledLi>
                            <Paragraph my={0} fontSize={1} color={"gray.60"}>
                                A new potential PEP or sanctions result against the individual.
                            </Paragraph>
                            <StyledLi>Adverse media check</StyledLi>
                            <Paragraph my={0} fontSize={1} color={"gray.60"}>
                                A new potential adverse media match against the individual.
                            </Paragraph>
                            <StyledLi>Periodic review</StyledLi>
                            <Paragraph my={0} fontSize={1} color={"gray.60"}>
                                The individual reaches their expected review date.
                            </Paragraph>
                        </StyledUl>
                    </MandateTypeBubble>
                </Box>

                <Modal
                    isOpen={showSuccess}
                    onClose={navigateToScreening}
                    title="Monitoring record is created successfully!"
                    content="Thank you for creating a monitoring record for this individual.You can link an application now if you want to capture information from the customer such as an Identity Document."
                    onModalConfirmClick={navigateToScreening}
                    primaryButtonText="Close"
                    primaryButtonVariant="primary"
                    width="450px"
                />
            </NewMonitoringRecordFrame>
        </>
    );
}