import { DateTime } from "luxon";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { Breadcrumb } from "../components/Breadcrumbs";
import { Flex } from "../components/Flex";
import { H3 } from "../components/Heading";
import { Loader } from "../components/Loader";
import { Paragraph } from "../components/Paragraph";
import Topbar from "../components/Topbar";
import { ROUTE } from "../routes";
import { buildName, capitalise } from "../utils/string";
import { Image } from "../components/Image";
import { Box } from "../components/Box";
import { Button } from "../components/Button";
import { Card } from "../components/Card";
import { checksStatusName, IndividualChecksMap } from "./IndividualView/helper";
import axios from "axios";
import theme from "../theme";
import { showError } from "../utils/error-handling";
import { notify, clearAll } from "../utils/notify";
import { useAuth } from "../context/AuthContext";
import downloadIcon from "../assets/download-arrow.svg";
import { Modal } from "../components/Modal";
import { FaSortDown, FaSortUp, FaTrash } from "react-icons/fa";
import { IconButtonDropdown } from "../components/IconButtonDropdown";
import { IconButtonDropdownOption } from "../components/IconButtonDropdown/IconButtonDropdownOption";
import { env } from "../utils/env";
import resendInviteIcon from "../assets/resend-invite-icon.svg";
import { useMandate } from "../data/useMandate";
import { useMonitoringDetail } from "../data/useMonitoringDetail";
import { useMonitoringActivityData } from "../data/useMonitoringActivityData";
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { Icon } from "../components/Icon";
import success from "../assets/card-success-icon.svg";
import inReview from "../assets/card-in-review-icon.svg";
import pending from "../assets/pending-icon.svg";
import { AdverseMediaMonitoring } from "./IndividualView/AdverseMediaMonitoring";
import { PepAndSanctionsMonitoring } from "./IndividualView/PepAndSanctionsMonitoring";
import { queryClient } from "../queryClient";
import manuallyRejected from "../assets/manually_rejected.svg";
import manuallyAccepted from "../assets/manually_accepted.svg";
import { useDeleteMonitoringRecord } from "../data/useDeleteMonitoringRecord";

const Heading = styled(H3)`
  ${({ theme: { fontSizes } }) => `
    font-size: ${fontSizes[2]};
    word-break: break-all;
  `}
`;

const Container = styled.div`
  ${({ theme: { space } }) => `
    padding: ${space[4]};
    height: calc(100vh - 165px);
    overflow-y: auto;
    padding-bottom: ${space[5]}
  `}
`;

const CardLine = ({ children, ...styleProps }: any) => (
  <Paragraph fontSize={1} color="gray.60" my={0} {...styleProps}>
    {children}
  </Paragraph>
);

export const IndividualMonitoringView = (props: any) => {
  const params = useParams();
  const { authHeader } = useAuth();
  const navigate = useNavigate();

  const [isRequestingPdfDownload, setIsRequestingPdfDownload] = useState(false);
  const [checkContent] = useState(null);
  const [monitoringId, setMonitoringId] = useState(null);
  const [isPepAndSanctionsCheck, setIsPepAndSanctionsCheck] = useState(false);
  const [isAdverseMediaCheck, setIsAdverseMediaCheck] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

  const handleViewClick = (name: string, id: number) => {
    setMonitoringId(id);
    if (name === IndividualChecksMap["pep_&_sanctions_check"].title) {
      setIsPepAndSanctionsCheck(!isPepAndSanctionsCheck);
    }
    if (name === IndividualChecksMap.adverse_media_check.title) {
      setIsAdverseMediaCheck(!isAdverseMediaCheck);
    }
  };
  const { data: individualData, isLoading } = useMonitoringDetail(params.id);

  const individual = individualData?.data;
  const individualName = isLoading
    ? "..."
    : buildName([
        individual?.first_name,
        individual?.middle_name,
        individual?.last_name,
      ]);

  const { data: monitoringActivityData } = useMonitoringActivityData(params.id);

  const monitoringActivity = monitoringActivityData?.data;

  let firstActivity, firstActivityAdverse, lastActivity, lastActivityAdverse;
  let pepArray = [];
  let adverseArray = [];

  monitoringActivity?.forEach(function (e) {
    if (e.event === "PEP & Sanctions") {
      pepArray.push(e);
      let length = pepArray.length;
      pepArray.sort(
        (a, b) => Date.parse(b.created_on) - Date.parse(a.created_on)
      );
      lastActivity = pepArray[0].created_on;
      firstActivity = pepArray[length - 1].created_on;
    }
    if (e.event === "Adverse Media") {
      adverseArray.push(e);
      let length = adverseArray.length;
      adverseArray.sort(
        (a, b) => Date.parse(b.created_on) - Date.parse(a.created_on)
      );
      lastActivityAdverse = adverseArray[0].created_on;
      firstActivityAdverse = adverseArray[length - 1].created_on;
    }
  });

  const monitoringIcons = {
    accepted: success,
    review: inReview,
    pending: pending,
    manually_accepted: manuallyAccepted,
    manually_rejected: manuallyRejected,
  };

  const cols = [
    {
      header: () => "Event",
      accessorKey: "event",
      cell: (info) => {
        const { event } = info.row.original; // Access the original row data
        return (
          <span
            style={{ fontWeight: "bold", fontSize: "18px", color: "#09093E" }}
          >
            {`${event}`}
          </span>
        );
      },
    },
    {
      header: () => "Status",
      accessorKey: "status",
      cell: (info) => {
        const status = info.getValue();
        return (
          <Flex justifyContent="start" alignItems="center">
            <span key={status.id} style={{ margin: "0 5px" }}>
              <Image
                src={monitoringIcons[status.status]}
                alt="checks"
                height="auto"
                width="22px"
                mr={1}
              />
              {checksStatusName[status.status]}
            </span>
          </Flex>
        );
      },
    },
    {
      header: () => "Creation Date",
      accessorKey: "created_on",
      cell: (info) => {
        return (
          <Flex color={theme.colors.gray[40]}>
            {DateTime.fromISO(info.getValue()).toFormat("dd/MM/yyyy")}
          </Flex>
        );
      },
    },
    {
      header: () => "",
      id: "view_id",
      accessorKey: "id",
      cell: (info) => {
        const { id } = info.row.original;
        const { event } = info.row.original;
        if (info.row.original.status.status !== "pending") {
          return (
            <Button
              size="regular"
              variant="text"
              fontWeight="bold"
              onClick={() => handleViewClick(event, id)}
            >
              View
            </Button>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const [columns] = useState([...cols]);

  const table = useReactTable({
    data: monitoringActivity,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { data: mandateData } = useMandate(individual?.mandate);
  const mandate = mandateData?.data;

  const handleDownloadPdfClick = () => {
    if (isRequestingPdfDownload) {
      return;
    }

    setIsRequestingPdfDownload(true);
    notify("Generating PDF, this could take up to 30 seconds", {
      position: "bottom-left",
      type: "info",
      pauseOnFocusLoss: false,
      pauseOnHover: false,
      autoClose: false,
    });

    axios
      .get(
        `${env.verifyServiceUrl}individual/download_monitoring_pdf/${params.id}`,
        {
          headers: {
            Authorization: authHeader,
          },
          responseType: "arraybuffer",
        }
      )
      .then((response) => {
        clearAll();
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);

        const timestamp = DateTime.now().toFormat("yyyy-MM-dd_HH-mm-ss");

        link.download = `Customer Report - ${individual?.reference} - ${
          individual?.first_name
        } ${individual?.middle_name || ""} ${
          individual?.last_name
        } - ${timestamp}.pdf`;

        link.click();
      })
      .catch(() => {
        clearAll();
        setTimeout(() => {
          notify("There was a problem while generating the PDF report", {
            position: "bottom-left",
            type: "error",
          });
        }, 100);
      })
      .finally(() => {
        setIsRequestingPdfDownload(false);
      });
  };

  const refetchIndividual = () => {
    queryClient.invalidateQueries(["individual", params.id]);
  };
  const { mutate: deleteMonitoring } = useDeleteMonitoringRecord();
  const handleConfirmDeleteMonitoringRecord = () => {
    setIsConfirmDeleteOpen(false);

    deleteMonitoring(
      { id: params.id },
      {
        onSuccess: () => {
          navigate(`/monitoring`);
          notify("Monitoring Record removed", {
            position: "bottom-left",
            type: "success",
          });
        },
        onError: (err: any) => {
          showError(
            err,
            "A problem occurred while deleting the monitoring record."
          );
        },
      }
    );
  };

  return (
    <div>
      <Topbar
        header={
          <Flex justifyContent="space-between" width="100%">
            <>
              <Breadcrumb>
                <Breadcrumb.Section linkTo={ROUTE.MONITORING}>
                  Monitoring
                </Breadcrumb.Section>

                <Breadcrumb.Section>{individualName}</Breadcrumb.Section>
              </Breadcrumb>
              <Flex justifyContent="space-between">
                {individual && (
                  <Button
                    onClick={handleDownloadPdfClick}
                    isDisabled={isRequestingPdfDownload}
                    variant="secondary"
                    color={"#2D374B"}
                    mr={1}
                  >
                    <Image
                      src={downloadIcon}
                      alt="download pdf"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    <Paragraph fontWeight="500" fontSize={theme.fontSizes[1]}>
                      {isRequestingPdfDownload
                        ? "Downloading..."
                        : "Download PDF"}
                    </Paragraph>
                  </Button>
                )}
                <IconButtonDropdown placement="bottom-end">
                  <IconButtonDropdownOption
                    onClick={() => setIsConfirmDeleteOpen(true)}
                    IconType={FaTrash}
                    isDestructive
                  >
                    Delete Monitoring Record
                  </IconButtonDropdownOption>
                </IconButtonDropdown>
              </Flex>
            </>
          </Flex>
        }
      ></Topbar>

      {isLoading && (
        <Flex justifyContent="center" alignItems="center" minHeight="60vh">
          <Loader />
        </Flex>
      )}

      {!isLoading && (
        <>
          <Container>
            <Flex gap="24">
              <Box width={"20%"}>
                <H3 mb={2}>Details </H3>
                <Card mb={0}>
                  <Button
                    mb={3}
                    onClick={() =>
                      navigate(`/applications/new`, {
                        state: { checks: true, id: individual?.mandate },
                      })
                    }
                    mr={1}
                    variant="gray"
                    paddingLeft={2}
                    backgroundColor="gray.10"
                    border="2px solid background"
                    color="blue.primary"
                    fontWeight={600}
                    isDisabled={mandate?.checks_in_pending !== 0}
                    display={"none"}
                  >
                    <Image
                      src={resendInviteIcon}
                      alt="link application"
                      height="auto"
                      width="22px"
                      mr={1}
                    />
                    Link application
                  </Button>

                  <Heading> Monitoring Reference</Heading>
                  <CardLine mb={3}>{individual?.reference}</CardLine>
                  <Heading>Date Started</Heading>
                  <CardLine mb={3}>
                    {individual?.created_on
                      ? DateTime.fromISO(individual?.created_on).toFormat(
                          "dd/MM/yyyy"
                        )
                      : "-"}
                  </CardLine>
                  <Heading>Last Activity</Heading>
                  <CardLine mb={3}>
                    {individual?.last_activity
                      ? DateTime.fromISO(individual?.last_activity).toFormat(
                          "dd/MM/yyyy"
                        )
                      : "-"}
                  </CardLine>
                  <Heading>Name</Heading>
                  <CardLine mb={3}>
                    {individual?.first_name} {individual?.middle_name}{" "}
                    {individual?.last_name}
                  </CardLine>

                  <Heading>Born</Heading>
                  <CardLine mb={3}>
                    {individual?.date_of_birth
                      ? DateTime.fromISO(individual?.date_of_birth).toFormat(
                          "dd/MM/yyyy"
                        )
                      : "-"}
                  </CardLine>
                  <Heading>Gender</Heading>
                  <CardLine mb={3}>{capitalise(individual?.gender)}</CardLine>
                </Card>
              </Box>
              <Box width={"80%"}>
                <H3 mb={2}>About</H3>
                <Flex>
                  <Card mb={2} width={"100%"}>
                    <Flex gap="24">
                      <Box flex={1} borderColor="gray.20">
                        <Heading mb={2}>PEP & Sanctions</Heading>

                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>Required</CardLine>
                            <Heading>
                              {capitalise(
                                individual?.pep_and_sanction_required?.toString()
                              )}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>First Activity</CardLine>
                            <Heading>
                              {firstActivity !== undefined
                                ? DateTime.fromISO(firstActivity).toFormat(
                                    "dd/MM/yyyy"
                                  )
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Last Activity</CardLine>
                            <Heading>
                              {lastActivity !== undefined
                                ? DateTime.fromISO(lastActivity).toFormat(
                                    "dd/MM/yyyy"
                                  )
                                : "-"}
                            </Heading>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Card>
                </Flex>
                <Flex>
                  <Card mb={2} width={"100%"}>
                    <Flex gap="24">
                      <Box flex={1}>
                        <Heading mb={2}>Adverse Media</Heading>

                        <Flex gap="24">
                          <Box flex={1}>
                            <CardLine>Required</CardLine>
                            <Heading>
                              {capitalise(
                                individual?.adverse_media_required?.toString()
                              )}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>First Activity</CardLine>
                            <Heading>
                              {firstActivityAdverse !== undefined
                                ? DateTime.fromISO(
                                    firstActivityAdverse
                                  ).toFormat("dd/MM/yyyy")
                                : "-"}
                            </Heading>
                          </Box>
                          <Box flex={1}>
                            <CardLine>Last Activity</CardLine>
                            <Heading>
                              {lastActivityAdverse !== undefined
                                ? DateTime.fromISO(
                                    lastActivityAdverse
                                  ).toFormat("dd/MM/yyyy")
                                : "-"}
                            </Heading>
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Card>
                </Flex>

                <H3 mb={2}>Activity</H3>
                <Flex>
                  {monitoringActivity?.length > 0 && (
                    <div className="tableContainer" style={{ width: "100%" }}>
                      <table style={{ margin: "0px" }}>
                        <thead>
                          {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup?.id}>
                              {headerGroup?.headers.map((header) => (
                                <th key={header?.id} colSpan={header?.colSpan}>
                                  {header?.isPlaceholder ? null : (
                                    <>
                                      <div
                                        {...{
                                          className: header?.column.getCanSort()
                                            ? "select-none cursor-pointer flex items-center gap-1"
                                            : "",
                                          onClick:
                                            header?.column.getToggleSortingHandler(),
                                        }}
                                      >
                                        {flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                        {{
                                          asc: (
                                            <Icon
                                              display="inline-block"
                                              ml={1}
                                              size="20px"
                                              color="gray.40"
                                              Type={FaSortDown}
                                            ></Icon>
                                          ),
                                          desc: (
                                            <Icon
                                              display="inline-block"
                                              ml={1}
                                              size="20px"
                                              color="gray.40"
                                              Type={FaSortUp}
                                            ></Icon>
                                          ),
                                        }[
                                          header?.column.getIsSorted() as string
                                        ] ?? null}
                                      </div>
                                    </>
                                  )}
                                </th>
                              ))}
                            </tr>
                          ))}
                        </thead>
                        <tbody>
                          {table?.getRowModel().rows.map((row, index) => (
                            <tr key={row?.id}>
                              {row.getVisibleCells().map((cell) => (
                                <td key={cell?.id}>
                                  {flexRender(
                                    cell?.column.columnDef.cell,
                                    cell?.getContext()
                                  )}
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <Flex
                        justifyContent="space-between"
                        alignItems="center"
                      ></Flex>
                    </div>
                  )}
                </Flex>
              </Box>
              {checkContent && (
                <Box
                  width={"60%"}
                  z-index={"2"}
                  position={"absolute"}
                  pl={"30%"}
                >
                  <>
                    <Card p={"0px !important"}>{checkContent}</Card>
                  </>
                </Box>
              )}
            </Flex>
          </Container>
          <Modal
            isOpen={isConfirmDeleteOpen}
            onClose={() => setIsConfirmDeleteOpen(false)}
            onModalConfirmClick={handleConfirmDeleteMonitoringRecord}
            title="Do you want to delete this monitoring record?"
            content="This is an irreversible action, if you delete it you will not be able to undo the change. "
            hasSecondaryButton
            secondaryButtonText="Cancel"
            width="450px"
            primaryButtonText="Delete"
            primaryButtonVariant="danger"
          />
        </>
      )}

      {isPepAndSanctionsCheck && (
        <PepAndSanctionsMonitoring
          isOpen={isPepAndSanctionsCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsPepAndSanctionsCheck(false);
          }}
          id={monitoringId}
        />
      )}

      {isAdverseMediaCheck && (
        <AdverseMediaMonitoring
          isOpen={isAdverseMediaCheck}
          onRequestClose={() => {
            refetchIndividual();
            setIsAdverseMediaCheck(false);
          }}
          id={monitoringId}
        />
      )}
    </div>
  );
};
