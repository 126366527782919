import { useState } from "react";
import { Box } from "../../Box";
import { Button } from "../../Button";
import { Flex } from "../../Flex";
import { H2, H3 } from "../../Heading";
import { Modal } from "../../Modal";
import { Paragraph } from "../../Paragraph";
import { GrayCard } from "../../Card";
import { useGenerateQRCode } from "../../../data/useGenerateQRCode";
import { Loader } from "../../Loader";
import { Image } from "../../Image";
import { Input } from "../../Input";
import { Form, Formik } from "formik";
import { codeValidationSchema } from "../../../utils/validationSchema";
import { useEnable2FA } from "../../../data/useEnable2FA";
import download from "../../../assets/2FA/download-arrow.svg";
import otp from "../../../assets/2FA/otp.svg";
import password from "../../../assets/2FA/password.svg";
import exclamation from "../../../assets/2FA/exclamation.svg";
import checkmark from "../../../assets/checkmark-green.svg";
import enabled from "../../../assets/2FA/2FAEnabled.svg";
import { IconButton } from "../../IconButton";
import { notify } from "../../../utils/notify";
import { BiCopy } from "react-icons/bi";
import { Icon } from "../../Icon";
import { extractErrorMessage, showError } from "../../../utils/error-handling";

export const Enable2FAModal = (props: any) => {
  const {
    isOpen,
    onRequestClose,
    initialNextClicked = false,
    onSuccessClose,
  } = props;
  const [isNextClicked, setIsNextClicked] = useState(initialNextClicked);
  const [showRecoveryCodes, setShowRecoveryCodes] = useState(false);
  const [recoveryCodes, setRecoveryCodes] = useState([]);
  const [doubleClick, setDoubleClick] = useState(false);

  const { data: QRcode, isLoading } = useGenerateQRCode();

  const { mutate: enable2FA } = useEnable2FA();

  const handleNext = () => {
    setIsNextClicked(true);
  };

  const base64 = window.btoa(
    new Uint8Array(QRcode?.data).reduce(function (data, byte) {
      return data + String.fromCharCode(byte);
    }, "")
  );

  const handleSubmit = (code, setFieldError) => {
    setDoubleClick(true);
    enable2FA(code, {
      onSuccess: (res) => {
        setIsNextClicked(false);
        setShowRecoveryCodes(true);
        setRecoveryCodes(res?.data?.recovery_codes);
        setDoubleClick(false);
      },
      onError: (err: any) => {
        const errorMessage = extractErrorMessage(
          err,
          "The code entered is invalid or already used."
        );

        setFieldError("verificationCode", errorMessage);
        showError(
          err,
          "Problem with verification code, please generate new or scan again."
        );
        setDoubleClick(false);
      },
    });
  };

  const handleStep2Cancel = () => {
    setIsNextClicked(initialNextClicked);
    onRequestClose();
  };
  const handleStep3Close = () => {
    setIsNextClicked(initialNextClicked);
    setShowRecoveryCodes(false);
    onRequestClose();
    onSuccessClose();
  };

  const handleModalClose = () => {
    setIsNextClicked(initialNextClicked);
    setShowRecoveryCodes(false);
    onRequestClose();
  };

  return (
    <Modal width="700px" isOpen={isOpen} onClose={handleModalClose}>
      {!isNextClicked && !showRecoveryCodes ? (
        <>
          <H3>Set up Authenticator</H3>
          <Box my={3}>
            <Paragraph fontWeight={"bold"}>What is 2FA?</Paragraph>
            <Paragraph fontSize={1}>
              Two-Factor Authentication (2FA) is an added layer of security for
              your account. It works by requiring two different forms of
              verification before granting access:
            </Paragraph>
          </Box>

          <Box my={3}>
            <Paragraph fontWeight={"bold"}>How it Works</Paragraph>
            <Flex>
              <Box flex={1}>
                <Flex
                  justifyContent={"center"}
                  backgroundColor={"gray.100"}
                  borderRadius={"50%"}
                  height={60}
                  width={60}
                >
                  <Image
                    src={download}
                    alt="Download Icon"
                    height="auto"
                    width="22px"
                  />
                </Flex>
                <Paragraph fontSize={1}>
                  First, you'll need to download an authenticator app on your
                  mobile device such as Microsoft or Google Authenticator.
                </Paragraph>
              </Box>
              <Box flex={1}>
                <Flex
                  justifyContent={"center"}
                  backgroundColor={"gray.100"}
                  borderRadius={"50%"}
                  height={60}
                  width={60}
                >
                  <Image src={otp} alt="otp" height="auto" width="22px" />
                </Flex>
                <Paragraph fontSize={1}>
                  After installation, the app will generate a temporary OTP
                  every 30 seconds.
                </Paragraph>
              </Box>
              <Box flex={1}>
                <Flex
                  justifyContent={"center"}
                  backgroundColor={"gray.100"}
                  borderRadius={"50%"}
                  height={60}
                  width={60}
                >
                  <Image
                    src={password}
                    alt="password"
                    height="auto"
                    width="22px"
                  />
                </Flex>
                <Paragraph fontSize={1}>
                  During login, input your password, followed by the current OTP
                  from the app.
                </Paragraph>
              </Box>
            </Flex>
          </Box>

          <Flex justifyContent={"end"} gap="18">
            <Button type="button" variant="secondary" onClick={onRequestClose}>
              Cancel
            </Button>
            <Button type="button" variant="primary" onClick={handleNext}>
              Next
            </Button>
          </Flex>
        </>
      ) : isNextClicked ? (
        <>
          <H3>Set up Authenticator</H3>
          <GrayCard mx={"0px"} mb={1}>
            <Paragraph fontWeight={"bold"}>Steps</Paragraph>
            <ul>
              <li>
                <Paragraph my={0} fontSize={0} color={"gray.60"}>
                  Install an authentication app on your mobile device if you
                  don’t already have one
                </Paragraph>
              </li>
              <li>
                <Paragraph my={0} fontSize={0} color={"gray.60"}>
                  Scan QR code with the authenticator and enter the 2-step
                  verification code
                </Paragraph>
              </li>
            </ul>
          </GrayCard>
          <GrayCard mx={"0px"}>
            <Flex gap="6">
              <Image
                src={exclamation}
                alt="password"
                height="auto"
                width="22px"
              />
              <Paragraph my={0} fontSize={0} color={"gray.60"}>
                Once an authentication app is enabled, all other 2FA methods
                will not be accepted.
              </Paragraph>
            </Flex>
          </GrayCard>
          <Flex alignItems={"center"} flexDirection={"column"}>
            <Box>
              <Flex gap="6px" alignItems={"center"} mb={2}>
                <H3 m={0} fontSize={1}>
                  Step 1:{" "}
                </H3>{" "}
                <Paragraph m={0} fontSize={1}>
                  Scan QR code
                </Paragraph>
              </Flex>
              {isLoading ? (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  minHeight="25vh"
                >
                  <Loader />
                </Flex>
              ) : (
                <>
                  {
                    <Image
                      src={`data:image/jpg;base64,${base64}`}
                      alt="QRcode"
                      height={170}
                      width={170}
                    />
                  }
                </>
              )}
            </Box>

            <hr />

            <Box>
              <Flex gap="6px" alignItems={"center"} mb={2}>
                <H3 m={0} fontSize={1}>
                  Step 2:{" "}
                </H3>{" "}
                <Paragraph m={0} fontSize={1}>
                  Enter the 2-step verification code from your authenticator app
                </Paragraph>
              </Flex>
              <Formik
                initialValues={{ verificationCode: "" }}
                validationSchema={codeValidationSchema}
                onSubmit={(values, { setFieldError }) => {
                  handleSubmit(values.verificationCode, setFieldError);
                }}
              >
                {(props) => (
                  <Form onSubmit={props.handleSubmit}>
                    <Flex alignItems={"center"} justifyContent={"space-around"}>
                      <Input
                        width={"90%"}
                        onChange={props.handleChange}
                        value={props.values.verificationCode}
                        placeholder="Enter 6-digit code"
                        label="Verification Code"
                        name="verificationCode"
                        max={6}
                        isRequired
                        hasError={Boolean(props.errors.verificationCode)}
                        errorMessage={props.errors.verificationCode}
                      />
                      {props?.values?.verificationCode?.length === 6 && (
                        <Box>
                          <Image
                            src={checkmark} // Replace with your green checkmark icon path
                            alt="Valid OTP"
                            width="24px"
                            height="24px"
                            marginTop={24}
                          />
                        </Box>
                      )}
                    </Flex>

                    <Flex mt={4} justifyContent={"end"} gap="18">
                      <Button
                        type="button"
                        variant="secondary"
                        onClick={handleStep2Cancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        isDisabled={
                          (props.dirty && !props.isValid) || doubleClick
                        }
                      >
                        Enable
                      </Button>
                    </Flex>
                  </Form>
                )}
              </Formik>
            </Box>
          </Flex>
        </>
      ) : !isNextClicked && showRecoveryCodes ? (
        <>
          <Flex justifyContent={"center"} mt={60}>
            <GrayCard height={200} width={"100%"} mx={0}>
              <Flex justifyContent={"center"}>
                <Image
                  src={enabled}
                  alt="Valid OTP"
                  width="auto"
                  height="auto"
                  marginTop={24}
                  position={"absolute"}
                  top={0}
                />
              </Flex>
            </GrayCard>
          </Flex>
          <H2>Two-Factor Authentication Enabled </H2>
          <Paragraph color={"gray.50"} fontSize={0}>
            Your Two-Factor Authentication has been successfully enabled.
          </Paragraph>

          <Paragraph>
            <strong>Recovery codes:</strong> You can use the following recovery
            codes should you lose access your authenticator app. Please store
            these in a safe location.
          </Paragraph>

          <Flex justifyContent={"center"}>
            <Flex flexDirection={"column"}>
              <Flex justifyContent="flex-end" mb={2} gap="8">
                <Button
                  variant="blueTextWithBorder"
                  onClick={() => {
                    const allCodes = recoveryCodes.join("\n"); // Join all codes with new lines
                    navigator.clipboard.writeText(allCodes);
                    notify("All codes copied to clipboard", {
                      type: "success",
                      position: "bottom-left",
                    });
                  }}
                >
                  Copy all
                  <Icon
                    aria-label="Copy all codes"
                    Type={BiCopy}
                    size="20px"
                    ml={2}
                  />
                </Button>
              </Flex>
              {recoveryCodes.map((code) => (
                <Flex mt={1} gap="6" alignItems={"center"}>
                  <Paragraph my={0} color="gray.50">
                    {code}
                  </Paragraph>
                  <IconButton
                    p={0}
                    onClick={() => {
                      navigator.clipboard.writeText(code);
                      notify("Copied to clipboard", {
                        type: "success",
                        position: "bottom-left",
                      });
                    }}
                    Type={BiCopy}
                    size="20px"
                  />
                </Flex>
              ))}
            </Flex>
          </Flex>

          <Flex mt={4} justifyContent={"end"}>
            <Button type="button" variant="primary" onClick={handleStep3Close}>
              Close
            </Button>
          </Flex>
        </>
      ) : (
        <></>
      )}
    </Modal>
  );
};
