import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import { RiDeleteBinFill } from "react-icons/ri";
import { Box } from "../../components/Box";
import { Flex } from "../../components/Flex";
import { Paragraph } from "../../components/Paragraph";
import { Loader } from "../../components/Loader";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Heading";
import { Button } from "../../components/Button";
import { Image } from "../../components/Image";
import { useMandateCheckList } from "../../data/useMandateCheckList";
import {
  address_verification_check_large,
  adverse_media_check_large,
  sanctions_check_large,
} from "../../assets/checkpoints";
import { NewCompanyChecksFrame } from "./NewCompanyChecksFrame";

const checksIcons = {
  address_verification_check: address_verification_check_large,
  adverse_media_check: adverse_media_check_large,
  sanctions_check: sanctions_check_large,
};

export const NewCompanyCheck = () => {
  // const [doubleClick, setDoubleClick] = useState(false);
  const [selectedChecks, setSelectedChecks] = useState([]);
  const navigate = useNavigate();

  const { data, isLoading } = useMandateCheckList(false, [
    "sanctions_check",
    "adverse_media_check",
  ]);

  const handleRemove = (id) => {
    setSelectedChecks((prevChecks) =>
      prevChecks.filter((check) => check.id !== id)
    );
  };

  const handleAdd = (id) => {
    setSelectedChecks((prevChecks) => {
      const checkToAdd = data?.data?.find((check) => check.id === id);
      return checkToAdd ? [...prevChecks, checkToAdd] : prevChecks;
    });
  };

  const handleNext = () => {
    // setDoubleClick(true);

    const checkpointIds = selectedChecks.map((check) => check.id);

    const isAdverseMediaSelected = selectedChecks.some((check) => {
      return ["adverse_media_check"].includes(check.name);
    });

    const isSanctionsSelected = selectedChecks.some((check) => {
      return ["sanctions_check"].includes(check.name);
    });

    navigate(`/checks/new/company/details`, {
      state: {
        checkpointIds: checkpointIds,
        isSanctionsSelected: isSanctionsSelected,
        isAdverseMediaSelected: isAdverseMediaSelected,
      },
    });
  };

  const CheckItem = ({ check }) => {
    const isAdded = selectedChecks.some(
      (selectedCheck) => selectedCheck.id === check.id
    );

    return isAdded ? (
      <Card mb={1} p={3}>
        <Flex gap="70" justifyContent="space-between" alignItems="center">
          <Flex gap="16">
            <Image
              src={checksIcons[check.name]}
              alt="checks"
              height="auto"
              width="50"
              mr={1}
            />
            <Paragraph fontWeight={500}>{check.actual_name}</Paragraph>
          </Flex>
          <Button
            Icon={RiDeleteBinFill}
            variant="grayText"
            onClick={() => handleRemove(check.id)}
          >
            Remove
          </Button>
        </Flex>
      </Card>
    ) : (
      <Box mb={1} p={3}>
        <Flex gap="70" justifyContent="space-between" alignItems="center">
          <Flex gap="16">
            <Image
              src={checksIcons[check.name]}
              alt="checks"
              height="auto"
              width="50"
              mr={1}
            />
            <Paragraph fontWeight={500}>{check.actual_name}</Paragraph>
          </Flex>
          <Button
            variant="gray"
            Icon={FaPlus}
            iconSize="14px"
            onClick={() => handleAdd(check.id)}
            isDisabled={isAdded}
          >
            Add
          </Button>
        </Flex>
      </Box>
    );
  };

  return (
    <NewCompanyChecksFrame step="checks">
      {isLoading ? (
        <Flex justifyContent="center" alignItems="center" minHeight="25vh">
          <Loader />
        </Flex>
      ) : (
        <>
          <Box mb={3} minWidth={500}>
            <H3>Select Checks</H3>
            <Paragraph fontSize={1} mb={3}>
              Please select the checks you would like to perform.
            </Paragraph>
            {data?.data?.map((check) => (
              <CheckItem key={check.id} check={check} />
            ))}
          </Box>

          <Flex alignItems="center" justifyContent="flex-end">
            <Button
              mr={2}
              onClick={handleNext}
              isDisabled={selectedChecks.length === 0}
            >
              Next
            </Button>
          </Flex>
        </>
      )}
    </NewCompanyChecksFrame>
  );
};
